import { useCallback, useEffect, useMemo, useState } from 'react';
import { TagPickerItem, TagPickerItemProps, inlineDropdownStyle, styles } from './TagPickerItem';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { Callout, Dropdown, IDropdownOption, Stack } from '@fluentui/react';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Label } from '@fluentui/react/lib/Label';
import { initializeComponent, withLocalization } from '../../../services/localization';
import { INTL } from '../../../util/intlUtil';
import { TagPickerLocalizationFormatMessages } from '../../../clientResources';

export type TagPickerGroupOptionsType = Omit<TagPickerItemProps, 'selectedkeys' | 'onRemove' | 'isCanBeRemoved'>;

export interface TagPickerGroupProps {
  isCanBeRemoved?: boolean;
  options: TagPickerGroupOptionsType[];
  onChange: (selectedKeys: Record<string, string[]>) => void;
  selectedKeys?: Record<string, string[]>;
}

export interface AddFilterType {
  options: TagPickerGroupOptionsType[];
  onChange: (addFilter: TagPickerGroupOptionsType & { selectedkeys: string[] }) => void;
}

const AddFilter = ({ options, onChange }: AddFilterType): JSX.Element => {
  const buttonId = useId('callout-button');
  const labelId = useId('callout-label');

  const [isCalloutVisible, { setFalse, setTrue }] = useBoolean(false);

  const [filterOptions, setFilterOptions] = useState<IDropdownOption[]>([]);
  const [filterSelectedKey, setFilterSelectedKey] = useState<IDropdownOption>(); // {key, value}

  const [valueOptions, setValueOptions] = useState<IDropdownOption[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  const [isMultiSelect, isMultiSelectTool] = useBoolean(true);

  useEffect(() => {
    if (options.length !== 0) {
      setFilterOptions(
        options.map((item, index) => ({
          key: item.tagName,
          text: item.tagName,
        }))
      );
    }
  }, [setFilterOptions, options]);

  useEffect(() => {
    if (filterSelectedKey) {
      const selectedItme = options.find((v) => v.tagName === filterSelectedKey.key);
      setValueOptions(selectedItme ? selectedItme.options : []);
      if (selectedItme?.isMultiSelect) {
        isMultiSelectTool.setTrue();
      } else {
        isMultiSelectTool.setFalse();
      }
    }
  }, [filterSelectedKey, options, isMultiSelect, isMultiSelectTool]);

  // Callout close
  const onDismiss = useCallback(() => {
    const currentAddFilter = options.find((v) => v.tagName === filterSelectedKey?.key);
    if (currentAddFilter) {
      onChange({ ...currentAddFilter, selectedkeys: selectedKeys });
    }
    setFalse();

    setFilterSelectedKey(undefined);
    setValueOptions([]);
    setSelectedKeys([]);
  }, [options, setFalse, filterSelectedKey, onChange, selectedKeys, setValueOptions, setSelectedKeys]);
  return (
    <>
      <DefaultButton
        id={buttonId}
        className={styles.defaultContent}
        onClick={setTrue}
        text={INTL.formatMessage(TagPickerLocalizationFormatMessages.AddFilter)}
        iconProps={{
          iconName: "addfilter-svg",
          imageProps: { styles: { root: { display: 'flex', alignItems: 'center' } } }
        }}
      />
      {isCalloutVisible && (
        <Callout className={styles.callout} ariaLabelledBy={labelId} role="dialog" target={`#${buttonId}`} onDismiss={onDismiss} setInitialFocus>
          <Label styles={{ root: { fontSize: 18 } }}>{INTL.formatMessage(TagPickerLocalizationFormatMessages.AddFilter)} </Label>
          <Stack tokens={{ padding: '20px 0', childrenGap: 16 }}>
            <Stack.Item>
              <Dropdown
                placeholder={INTL.formatMessage(TagPickerLocalizationFormatMessages.SelectFilter)}
                label={INTL.formatMessage(TagPickerLocalizationFormatMessages.Filter)}
                required
                options={filterOptions}
                styles={inlineDropdownStyle}
                onChange={(event, item) => {
                  setFilterSelectedKey(item);
                }}
              />
            </Stack.Item>
            <Stack.Item>
              <Dropdown
                placeholder={INTL.formatMessage(TagPickerLocalizationFormatMessages.SelectValue)}
                label={INTL.formatMessage(TagPickerLocalizationFormatMessages.Value)}
                onRenderTitle={(props, defaultRender) => {
                  if (props && props.length > 1) {
                    return <>{props.length} selected</>;
                  }
                  return <>{defaultRender?.(props)}</>;
                }}
                required
                multiSelect={isMultiSelect}
                options={valueOptions}
                styles={inlineDropdownStyle}
                onChange={(event, item, index) => {
                  if (item) {
                    if (isMultiSelect) {
                      setSelectedKeys(item.selected ? [...selectedKeys, item.key as string] : selectedKeys.filter((key) => key !== item.key));
                    } else {
                      setSelectedKeys([item.key as string]);
                    }
                  }
                }}
              />
            </Stack.Item>
          </Stack>

          <Stack horizontal tokens={{ childrenGap: 8 }}>
            <PrimaryButton text={INTL.formatMessage(TagPickerLocalizationFormatMessages.Apply)} onClick={onDismiss} styles={{ root: { height: 26, fontSize: 14 } }} />
            <DefaultButton
              styles={{ root: { height: 26, fontSize: 14 } }}
              text={INTL.formatMessage(TagPickerLocalizationFormatMessages.Cancel)}
              onClick={() => {
                setFalse();
                setFilterSelectedKey(undefined);
              }}
            />
          </Stack>
        </Callout>
      )}
    </>
  );
};

const TagPickerGroupInternal = ({ isCanBeRemoved, options, onChange, selectedKeys }: TagPickerGroupProps): JSX.Element => {
  const [selectedItems, setSelectedItems] = useState<TagPickerGroupOptionsType[]>([]);
  const [selected, setSelected] = useState<Record<string, string[]>>(selectedKeys || {});

  useEffect(() => {
    if (selectedKeys && Object.entries(selectedKeys).length > 0) {
      setSelected(selectedKeys);
      const getSelectedFilters = options.filter((item) => Object.entries(selectedKeys).some((v) => v[0] === item.tagName));
      setSelectedItems(getSelectedFilters);
    }
  }, [options, selectedKeys, setSelected]);

  const getAddFilterOptions = useMemo(() => {
    if (selectedItems.length !== 0) return options.filter((v) => selectedItems.some((i) => i.tagName !== v.tagName));
    return options;
  }, [options, selectedItems]);

  const onChangeFilter = useCallback(
    (addFilter: TagPickerGroupOptionsType & { selectedkeys: string[] }) => {
      if (addFilter && addFilter.selectedkeys.length !== 0) {
        setSelectedItems([...selectedItems, addFilter]);
        setSelected({ ...selected, [addFilter.tagName]: addFilter.selectedkeys });
        onChange({ ...selected, [addFilter.tagName]: addFilter.selectedkeys });
      }
    },
    [selectedItems, setSelectedItems, selected, onChange]
  );
  const onRemove = useCallback(
    (tagName: string) => {
      setSelectedItems(selectedItems.filter((v) => v.tagName !== tagName));
      const selectedArr = Object.entries(selected);
      if (selectedArr.length !== 0) {
        const filterArr = selectedArr.filter((v) => v[0] !== tagName);
        setSelected(Object.fromEntries(filterArr));
        onChange(Object.fromEntries(filterArr));
      }
    },
    [selected, setSelected, selectedItems, setSelectedItems, onChange]
  );

  return (
    <Stack horizontal verticalAlign="center">
      {selectedItems.length !== 0 &&
        selectedItems.map((item, index) => {
          return (
            <TagPickerItem
              isCanBeRemoved={!isCanBeRemoved}
              key={`${item.tagName.replace(/\s*/g, '')}key`}
              tagName={item.tagName}
              options={item.options}
              onSearch={item.onSearch ? item.onSearch : undefined}
              isMultiSelect={item.isMultiSelect}
              selectedkeys={selected[item.tagName]}
              onChange={(name: string, currentSelected: string[]) => {
                setSelected({ ...selected, [name]: currentSelected });
                onChange({ ...selected, [name]: currentSelected });
              }}
              onRemove={onRemove}
            />
          );
        })}
      {selectedItems.length !== options.length && <AddFilter options={getAddFilterOptions} onChange={onChangeFilter} />}
    </Stack>
  );
};

export const TagPickerGroup = withLocalization(initializeComponent(TagPickerGroupInternal));