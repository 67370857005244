import { FontWeights } from '@fluentui/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DefaultPalette } from '@fluentui/theme';

const appTheme = {
  components: {
    DetailsList: {
      styles: {
        root: {
          selectors: {
            '.ms-DetailsHeader-cell.is-actionable': {
              cursor: 'pointer',
            },
            '.ms-Button.is-disabled': {
              background: 'transparent',
            },
            '.ms-DetailsRow-cell': {
              display: 'flex',
              alignItems: 'center',
            },
          },
        },
      },
    },
    CommandBar: {
      styles: {
        root: {
          padding: 0,
          borderBottom: `1px solid ${DefaultPalette.neutralLight}`,
          selectors: {
            '.no-display': {
              display: 'none',
            },
            '.active': {
              background: DefaultPalette.neutralLighter,
            },
          },
        },
      },
    },
    Overlay: {
      styles: {
        root: {
          selectors: {
            // Adding z-index to root will cause issues with modal/dialog overlays
            '&.cover-content': {
              zIndex: 1000,
            },
          },
        },
      },
    },
    DefaultButton: {
      styles: {
        root: {
          selectors: {
            '&:hover': {
              background: 'none',
            },
            '&.as-link': {
              minWidth: 'auto',
              padding: 0,
              border: 'none',
              background: 'none',
            },
            '&.as-link:not(.is-disabled)': {
              color: DefaultPalette.themePrimary,
            },

            '&.as-link .ms-Button-label': {
              fontWeight: FontWeights.regular,
            },
          },
        },
      },
    },
  },
  palette: {
    accent: '#0078d4',
    black: '#000000',
    blackTranslucent40: 'rgba(0,0,0,.4)',
    blue: '#0078d4',
    blueDark: '#002050',
    blueLight: '#00bcf2',
    blueMid: '#00188f',
    green: '#107c10',
    greenDark: '#004b1c',
    greenLight: '#bad80a',
    magenta: '#b4009e',
    magentaDark: '#5c005c',
    magentaLight: '#e3008c',
    neutralDark: '#201f1e',
    neutralLight: '#edebe9',
    neutralLighter: '#f3f2f1',
    neutralLighterAlt: '#faf9f8',
    neutralPrimary: '#323130',
    neutralPrimaryAlt: '#3b3a39',
    neutralQuaternary: '#d0d0d0',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralSecondary: '#605e5c',
    neutralSecondaryAlt: '#8a8886',
    neutralTertiary: '#a19f9d',
    neutralTertiaryAlt: '#c8c6c4',
    orange: '#d83b01',
    orangeLight: '#ea4300',
    orangeLighter: '#ff8c00',
    purple: '#5c2d91',
    purpleDark: '#32145a',
    purpleLight: '#b4a0ff',
    red: '#e81123',
    redDark: '#a4262c',
    teal: '#008272',
    tealDark: '#004b50',
    tealLight: '#00b294',
    themeDark: '#005a9e',
    themeDarkAlt: '#106ebe',
    themeDarker: '#004578',
    themeLight: '#c7e0f4',
    themeLighter: '#deecf9',
    themeLighterAlt: '#eff6fc',
    themePrimary: '#0078d4',
    themeSecondary: '#2b88d8',
    themeTertiary: '#71afe5',
    white: '#ffffff',
    whiteTranslucent40: 'rgba(255,255,255,.4)',
    yellow: '#ffb900',
    yellowDark: '#d29200',
    yellowLight: '#fff100',
  },
};

export default appTheme;
