/**
 * List of available actions for the error popup dialog confirmation button when clicked.
 * These actions will be used in the AppLayout to determine what actions are dispatched
 * beyond the default dismissal of the error popup
 */
export enum PopUpErrorActions {
  RefreshProjects = 'refreshProjects',
  RefreshModels = 'refreshModels',
  RefreshDocuments = 'refreshDocuments',
  Dismiss = 'dismiss',
  RefreshPage = 'refreshPage',
}

/**
 * List of entity types to check against to process pop up error payloads accordingly
 */
export enum PopUpEntityTypes {
  Projects = 'projects',
  Models = 'models',
  Documents = 'documents',
  Workspaces = 'workspaces',
  ModelTestResults = 'modelTestResults',
  Subscription = 'subscription',
}

export enum RequestTypes {
  Rename = 'rename',
  Create = 'create',
  Get = 'get',
  Post = 'post',
  Put = 'put',
  Delete = 'delete',
  Download = 'download',
  UpdateSharing = 'update sharing',
}
