import { AzureEnvironment, azureEnvironment } from "./environment"

const getDocumentFwlink = (linkId: string): string => {
    switch (azureEnvironment) {
        case AzureEnvironment.AzureEnvironmentUSNat:
            return "https://aka.eaglex.ic.gov/fwlink/?linkid=" + linkId;
        case AzureEnvironment.AzureEnvironmentUSSec:
            return "https://aka.microsoft.scloud/fwlink/?linkid=" + linkId;
        default:
            return "https://go.microsoft.com/fwlink/?linkid=" + linkId;
    }
};

export const urlLinks = {
    learnMoreAboutAzurePortal: (): string => {
        switch (azureEnvironment) {
            // case AzureEnvironment.AzureEnvironmentDogfood:
            //     return getDocumentFwlink("2162780");
            case AzureEnvironment.AzureEnvironmentMooncake:
                return getDocumentFwlink("2162691");
            case AzureEnvironment.AzureEnvironmentFairfax:
                return getDocumentFwlink("2162909");
            case AzureEnvironment.AzureEnvironmentUSSec:
                return "https://portal.azure.microsoft.scloud/";
            case AzureEnvironment.AzureEnvironmentUSNat:
                return "https://portal.azure.eaglex.ic.gov/";
            default:
                return getDocumentFwlink("2162781");
        }
    },
    learnMoreAboutCognitiveServicesSubscription: (): string => {
        switch (azureEnvironment) {
            //   case AzureEnvironment.AzureEnvironmentDogfood:
            //     return getDocumentFwlink("2163214");
            case AzureEnvironment.AzureEnvironmentMooncake:
                return getDocumentFwlink("2163215");
            case AzureEnvironment.AzureEnvironmentFairfax:
                return getDocumentFwlink("2162938");
            case AzureEnvironment.AzureEnvironmentUSSec:
                return "https://portal.azure.microsoft.scloud/#browse/Microsoft.CognitiveServices%2Faccounts";
            case AzureEnvironment.AzureEnvironmentUSNat:
                return "https://portal.azure.eaglex.ic.gov/#browse/Microsoft.CognitiveServices%2Faccounts";
            default:
                return getDocumentFwlink("2163216");
        }
    },

    headerAzure: (): string => {
        switch (azureEnvironment) {
            case AzureEnvironment.AzureEnvironmentMooncake:
                return "https://www.azure.cn";
            case AzureEnvironment.AzureEnvironmentUSSec:
                return "https://docs.microsoft.scloud/azure";
            case AzureEnvironment.AzureEnvironmentUSNat:
                return "https://docs.eaglex.ic.gov/azure";
            default:
                return "https://www.azure.com";
        }
    },

    advancedOptionsLink: (): string => {
        switch (azureEnvironment) {
            default:
                return getDocumentFwlink("2189349");
        }
    },

    learnMoreAboutAzureDirectories: (): string => {
        switch (azureEnvironment) {
            default:
                return getDocumentFwlink("2162896");
        }
    },
    createAzureAccount: (): string => {
        switch (azureEnvironment) {
            default:
                return getDocumentFwlink("2162782");
        }
    },
    learnMoreCreatingAzureAccount: (): string => {
        switch (azureEnvironment) {
            default:
                return getDocumentFwlink("2191822");
        }
    },
    privacyCookies: (): string => {
        switch (azureEnvironment) {
            case AzureEnvironment.AzureEnvironmentUSSec:
                return "https://docs.microsoft.scloud/sovereign-cloud/privacy";
            case AzureEnvironment.AzureEnvironmentUSNat:
                return "https://docs.eaglex.ic.gov/sovereign-cloud/privacy";
            default:
                return getDocumentFwlink("521839");
        }
    },
    imprintDe: (): string => {
        switch (azureEnvironment) {
            default:
                return getDocumentFwlink("2192553");
        }
    },
    imprintIt: (): string => {
        switch (azureEnvironment) {
            default:
                return getDocumentFwlink("2213826");
        }
    },
    imprintFr: (): string => {
        switch (azureEnvironment) {
            default:
                return getDocumentFwlink("2214997");
        }
    },
    userProfile: (): string => {
        switch (azureEnvironment) {
            case AzureEnvironment.AzureEnvironmentFairfax:
                return "https://myaccount.azure.us/?ref=MeControl";
            case AzureEnvironment.AzureEnvironmentMooncake:
                return "https://myaccount.windowsazure.cn/?ref=MeControl";
            case AzureEnvironment.AzureEnvironmentUSSec:
                return "https://myaccount.microsoft.scloud/?ref=MeControl";
            case AzureEnvironment.AzureEnvironmentUSNat:
                return "https://myaccount.eaglex.ic.gov/?ref=MeControl";
            default:
                return "https://myaccount.microsoft.com/?ref=MeControl";
        }
    },
    SpeechStudio: (): string => {
        switch (azureEnvironment) {
            case AzureEnvironment.AzureEnvironmentFairfax:
                return "https://speech.azure.us/portal";
            default:
                return "https://speech.microsoft.com/portal";
        }
    },
    LanguageStudio: (): string => {
        switch (azureEnvironment) {
            case AzureEnvironment.AzureEnvironmentFairfax:
                return "https://language.cognitive.azure.us/";
            default:
                return "https://language.cognitive.azure.com/";
        }
    },
    CustomTranslator: (): string => {
        switch (azureEnvironment) {
            default:
                return "https://portal.customtranslator.azure.ai/"
        }
    },
    VisionStudio: (): string => {
        switch (azureEnvironment) {
            default:
                return "https://portal.vision.cognitive.azure.com/"
        }
    },
    DocumentIntelligence: (): string => {
        switch (azureEnvironment) {
            default:
                return "https://formrecognizer.appliedai.azure.com/";
        }
    },
    ContentSafety: (): string => {
        switch (azureEnvironment) {
            default:
                return "https://contentsafety.cognitive.azure.com/"
        }
    },
    AzureAIStudio: (): string => {
        switch (azureEnvironment) {
            default:
                return "https://ai.azure.com/"
        }
    },
    AzureMachineLearning: (): string => {
        switch (azureEnvironment) {
            case AzureEnvironment.AzureEnvironmentFairfax:
                return "https://studio.ml.azure.us/";
            case AzureEnvironment.AzureEnvironmentMooncake:
                return "https://studio.ml.azure.cn/";
            case AzureEnvironment.AzureEnvironmentUSSec:
                return "https://ml.azure.microsoft.scloud/";
            case AzureEnvironment.AzureEnvironmentUSNat:
                return "https://ml.azure.eaglex.ic.gov/";
            default:
                return "https://ml.azure.com/";
        }
    },
}