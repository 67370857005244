import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import {
  useTheme,
} from "@fluentui/react";
import { useId } from "@fluentui/react-hooks";

export interface DragZoneProps {
  disabled?: boolean;
  onFileChange?: (files: Array<File>) => void;
  dragZoneTag?: string;
}

export const useDragZone = (props: DragZoneProps) => {
  const uploadFileId = useId();
  const { disabled=false, onFileChange, dragZoneTag = `#${uploadFileId}` } = props
  const [isDroping, setIsDroping] = useState(false);
  const [files, setFiles] = useState<Array<File> | undefined>();
  const theme = useTheme();

  const handleDrop = (e: DragEvent) => {
    if (!disabled && e.dataTransfer && e.dataTransfer.files) {
      setIsDroping(false);
      const newFiles = Array.from(e.dataTransfer.files);
      onFileChange && onFileChange(newFiles);
      setFiles(newFiles);
    }
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragover = (e: DragEvent) => {
    if (disabled) return;
    setIsDroping(true);
    e.preventDefault();
    e.stopPropagation();
  }

  const handleDragleave = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) return;
    const dropZone = document.querySelector(dragZoneTag) as HTMLElement;
    if(!dropZone.contains(e.relatedTarget as HTMLElement))setIsDroping(false);
  }

  const [defaultBorder,setDefaultBorder] = useState<string|undefined>()

  useLayoutEffect(()=>{
    const dropZone = document.querySelector(dragZoneTag) as HTMLElement;
    setDefaultBorder(dropZone.style.border)
  },[])

  useEffect(() => {
    const dropZone = document.querySelector(dragZoneTag) as HTMLElement;
    dropZone.addEventListener('dragover', handleDragover);
    dropZone.addEventListener('drop', handleDrop);
    dropZone.addEventListener('dragleave', handleDragleave);
    (dropZone as HTMLElement).style.border = isDroping ? `2px dashed ${theme.palette.themePrimary}` : defaultBorder
 
    return () => {
      dropZone.removeEventListener('dragover', handleDragover);
      dropZone.removeEventListener('drop', handleDrop);
      dropZone.removeEventListener('dragleave', handleDragleave);
    };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dragZoneTag, disabled, isDroping]);
  // add forbidden indicator
  useEffect(() => {
    if (dragZoneTag === 'html') return
    const dropZone = document.querySelector('html');
    const changeIndicator = (e: any) => {
      if (isDroping && !disabled) {
        e.dataTransfer.dropEffect = "copy";
      } else {
        e.dataTransfer.dropEffect = "none";
      }
      e.preventDefault();
      e.stopPropagation();
    }
    dropZone.addEventListener('dragover', changeIndicator);
    return () => {
      dropZone.removeEventListener('dragover', changeIndicator);
    };
  }, [disabled, dragZoneTag, isDroping]);

  const getDragZoneProps = () => {
    return {
      id: uploadFileId
    }
  }
  return { files, getDragZoneProps, isDroping }
}

