import { AppBarPanels } from '../../utils/constants/appBarPanels';

export const SET_ACTIVE_PANEL = 'SET_ACTIVE_PANEL';
export const SET_IS_PANEL_OPEN = 'SET_IS_PANEL_OPEN';
export const SET_ACTIVE_OPEN_PANEL = 'SET_ACTIVE_OPEN_PANEL';

export interface ISetActivePanel {
  type: typeof SET_ACTIVE_PANEL;
  payload: AppBarPanels;
}

export interface ISetIsPanelOpen {
  type: typeof SET_IS_PANEL_OPEN;
  payload: boolean;
}

export interface ISetActiveOpenPanel {
  type: typeof SET_ACTIVE_OPEN_PANEL;
  payload: AppBarPanels;
}

export type PanelsDispatchTypes = ISetActivePanel | ISetIsPanelOpen | ISetActiveOpenPanel;
