import { FontIcon, Stack } from "@fluentui/react";
import styled from "styled-components";

export type SplashScreenViewProps = {
  title: string;
  description?: JSX.Element;
  isShaded?: boolean;
};

export function SplashScreenView(props: SplashScreenViewProps) {
  const { title, description, isShaded } = props;
  const SplashScreenViewWrapper = styled(Stack)`
    background: ${isShaded ? "#FAF9F8" : ""};
    text-align: center;
    padding: 10px;
    min-height: 500px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .contentWrapper{
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
    }
  `;
  return (
    <SplashScreenViewWrapper className="splashScreenViewParentContainer" >
      <div className="contentWrapper">
        <FontIcon aria-label="box bubbles" iconName="boxbubbles-svg" />
        {title && <h3 className="splashScreenViewChildren">{title}</h3>}
        {description && <Stack className="splashScreenViewChildren">{description}</Stack>}
      </div>
    </SplashScreenViewWrapper>
  );
}
