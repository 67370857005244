import { IBaseModelNotificationItem } from '../notifications/notifications.types';

import { DeploymentNotificationStatus } from '../../utils/constants/notifications';
import PublishOperation from '../../utils/constants/publishOperation';

export const ADD_DEPLOYMENT_NOTIFICATION = 'ADD_DEPLOYMENT_NOTIFICATION';
export const UPDATE_DEPLOYMENT_NOTIFICATION = 'UPDATE_DEPLOYMENT_NOTIFICATION';
export const CLEAR_DEPLOYMENT_NOTIFICATION = 'CLEAR_DEPLOYMENT_NOTIFICATION';
export const CLEAR_ALL_DEPLOYMENT_NOTIFICATIONS = 'CLEAR_ALL_DEPLOYMENT_NOTIFICATONS';

export interface IDeploymentNotificationItemBase {
  operation: PublishOperation;
  regions: IDeploymentRegionItem[];
  swapModelName: string;
}

export interface IDeploymentNotificationItem extends IBaseModelNotificationItem, IDeploymentNotificationItemBase {}

export interface IDeploymentRegionItem {
  region: number;
  status: DeploymentNotificationStatus;
  operation: PublishOperation;
}

export interface IAddDeploymentNotificationPayload {
  id: string;
  data: {
    notification: IDeploymentNotificationItemBase;
    name: string;
  };
}

export interface IUpdateDeploymentNotificationPayload {
  id: string;
  data: {
    region: number;
    status: DeploymentNotificationStatus;
  };
}

export interface IAddDeploymentNotification {
  type: typeof ADD_DEPLOYMENT_NOTIFICATION;
  payload: IAddDeploymentNotificationPayload;
}

export interface IUpdateDeploymentNotification {
  type: typeof UPDATE_DEPLOYMENT_NOTIFICATION;
  payload: IUpdateDeploymentNotificationPayload;
}

export interface IClearDeploymentNotification {
  type: typeof CLEAR_DEPLOYMENT_NOTIFICATION;
  payload: string;
}

export interface IClearAllDeploymentNotifications {
  type: typeof CLEAR_ALL_DEPLOYMENT_NOTIFICATIONS;
}

export type DeploymentNotificationsDispatchTypes =
  | IAddDeploymentNotification
  | IUpdateDeploymentNotification
  | IClearDeploymentNotification
  | IClearAllDeploymentNotifications;
