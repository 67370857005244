export const SET_USERS = 'SET_USERS';
export const CLEAR_USERS = 'CLEAR_USERS';

export interface IUserItem {
  id: string;
  userName: string;
  emailAddress: string;
}

export interface ISetUsers {
  type: typeof SET_USERS;
  payload: {
    [key: string]: IUserItem;
  };
}

export interface IClearUsers {
  type: typeof CLEAR_USERS;
}

export type UsersDispatchTypes = ISetUsers | IClearUsers;
