import styled from "styled-components";
import { INTL } from "../../../util/intlUtil";
import { PreviewTagMessages } from "../../../clientResources";
import { defaultTheme } from "../../../themes";

/**
 * border color should be "#B8B8B8"
 * background should be "#E8E8E8"
 * font-color should be "#1F1F1F"
 * 
 */
export const PreviewTag = styled((props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>) =>
    <span {...props} >{INTL.formatMessage(PreviewTagMessages.Preview)}</span>
)`
  & {
    display: block;
    box-sizing: border-box;
    padding: 1px 8px;
    width: 50px;
    height: 20px;
    background: ${defaultTheme.header.palette.themeDarkAlt};
    border: 1px solid ${defaultTheme.header.palette.themeLighter};
    border-radius: 16px;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: ${defaultTheme.body.palette.neutralDark};
  }
`;