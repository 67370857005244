export const SET_ERROR = 'SET_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';

export interface IErrorItem {
  message: string | undefined;
  statusCode: number | undefined;
  category: string | undefined;
}

export interface ISetError {
  type: typeof SET_ERROR;
  payload: IErrorItem;
}

export interface IClearError {
  type: typeof CLEAR_ERROR;
}

export type ErrorDispatchTypes = ISetError | IClearError;
