import { IUpdateJobIdUploadNotificationPayload } from '../uploadNotifications/uploadNotifications.types';

import { NotificationType } from '../../utils/constants/notifications';

export const CONNECT_TO_HUB = 'CONNECT_TO_HUB';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REPLACE_NOTIFICATION_ID = 'REPLACE_NOTIFICATION_ID';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const CLEAR_ALL_NOTIFICATIONS = 'CLEAR_ALL_NOTIFICATIONS';
export const SILENCE_ALL_NOTIFICATIONS = 'SILENCE_ALL_NOTIFICATIONS';

/**
 * Each notification is identified by the combination of their unique id
 * and their notification type.
 */
export interface INotificationItemId {
  id: string;
  type: NotificationType;
  silent?: boolean;
}

/**
 * Id is the id of the notification. Name has to be stored here as the models slice
 * of the store is wiped during page navigation to a different workspace/project
 * and notifications have to work across all pages.
 */
export interface IBaseModelNotificationItem {
  id: string;
  name: string;
}

export interface IClearNotificationPayload {
  id: string;
  type: NotificationType;
}

export interface IAddNotification {
  type: typeof ADD_NOTIFICATION;
  payload: INotificationItemId;
}

export interface IReplaceNotificationId {
  type: typeof REPLACE_NOTIFICATION_ID;
  payload: IUpdateJobIdUploadNotificationPayload;
}

export interface IClearNotification {
  type: typeof CLEAR_NOTIFICATION;
  payload: IClearNotificationPayload;
}

export interface IClearAllNotifications {
  type: typeof CLEAR_ALL_NOTIFICATIONS;
}

export interface IConnectToHub {
  type: typeof CONNECT_TO_HUB;
}

export interface ISilenceAllNotifications {
  type: typeof SILENCE_ALL_NOTIFICATIONS;
}

export type NotificationsDispatchTypes =
  | IClearAllNotifications
  | IAddNotification
  | IReplaceNotificationId
  | IClearNotification
  | IConnectToHub
  | ISilenceAllNotifications;
