import { IDocumentItem } from '../documents/documents.types';

export const SET_MODEL_DOCUMENTS = 'SET_MODEL_DOCUMENTS';
export const CLEAR_MODEL_DOCUMENTS = 'CLEAR_MODEL_DOCUMENTS';

export interface ISetModelDocuments {
  type: typeof SET_MODEL_DOCUMENTS;
  payload: {
    [key: number]: IDocumentItem;
  };
}

export interface IClearModelDocuments {
  type: typeof CLEAR_MODEL_DOCUMENTS;
}

export type ModelDocumentsDispatchTypes = ISetModelDocuments | IClearModelDocuments;
