import { schema } from 'normalizr';

const categorySchema = new schema.Entity('categories');
const languageSchema = new schema.Entity('languages');
const billingRegionSchema = new schema.Entity('billingRegions', {}, { idAttribute: 'billingRegionCode' });
const modelRegionSchema = new schema.Entity('modelRegions');
const roleSchema = new schema.Entity('roles');
const userSchema = new schema.Entity('users');
const sharingSchema = new schema.Entity('sharing', { role: roleSchema, user: userSchema });
const subscriptionSchema = new schema.Entity('subscription', { region: billingRegionSchema });
const supportedLanguageSchema = new schema.Entity('supportedLanguages');
const supportedLanguagePairSchema = new schema.Entity('supportedLanguagePairs');

const fileSchema = new schema.Entity('files', {
  language: languageSchema,
});

const workspaceSchema = new schema.Entity('workspaces', {
  createdBy: userSchema,
  role: roleSchema,
  region: billingRegionSchema,
  sharing: [sharingSchema],
  subscription: subscriptionSchema,
});

const projectSchema = new schema.Entity('projects', {
  createdBy: userSchema,
  category: categorySchema,
  modifiedBy: userSchema,
  languagePair: {
    sourceLanguage: languageSchema,
    targetLanguage: languageSchema,
  },
});

const documentsSchema = new schema.Entity('documents', {
  createdBy: userSchema,
  modifiedBy: userSchema,
  languages: [languageSchema],
  files: [fileSchema],
});

const modelSchema = new schema.Entity('models', {
  createdBy: userSchema,
  documents: [documentsSchema],
});

const workspacesListSchema = new schema.Array(workspaceSchema);
const projectsListSchema = new schema.Array(projectSchema);
const documentsListSchema = new schema.Array(documentsSchema);
const modelsListSchema = new schema.Array(modelSchema);
const modelRegionsListSchema = new schema.Array(modelRegionSchema);
const supportedLanguagesSchema = new schema.Array(supportedLanguageSchema);
const supportedLanguagePairListSchema = new schema.Array(supportedLanguagePairSchema);
const billingRegionsListSchema = new schema.Array(billingRegionSchema);
const categoryListSchema = new schema.Array(categorySchema);

export {
  workspaceSchema,
  workspacesListSchema,
  projectsListSchema,
  projectSchema,
  documentsListSchema,
  documentsSchema,
  modelsListSchema,
  modelSchema,
  modelRegionsListSchema,
  subscriptionSchema,
  supportedLanguagesSchema,
  supportedLanguagePairListSchema,
  categoryListSchema,
  billingRegionsListSchema,
};
