import { PopUpErrorActions } from '../../utils/constants/popUpErrors';

export const SET_POP_UP_ERROR = 'SET_POP_UP_ERROR';
export const DISMISS_POP_UP_ERROR = 'DISMISS_POP_UP_ERROR';

export interface IPopUpErrorItem {
  title: string;
  message: string;
  confirmationButtonText: string;
  confirmationButtonAction: PopUpErrorActions;
  showCancelButton: boolean;
}

export interface ISetPopUpError {
  type: typeof SET_POP_UP_ERROR;
  payload: IPopUpErrorItem;
}

export interface IDismissPopUpError {
  type: typeof DISMISS_POP_UP_ERROR;
}

export type PopUpErrorsDispatchTypes = ISetPopUpError | IDismissPopUpError;
