import { DirectionalHint, Dropdown, ICalloutProps, IDropdown, IDropdownOption, IDropdownProps, IDropdownStyles, IRefObject, ITheme, mergeStyles, TooltipHost, TooltipOverflowMode, useTheme } from '@fluentui/react';
import _ from 'lodash';
import * as React from 'react';
import { FilterBarDropdownSearchBox } from './FilterBarDropdownSearchBox';
import { initializeComponent, withLocalization } from '../../../services/localization';
import { INTL } from '../../../util/intlUtil';
import { FilterBarDropdownLocalizationFormatMessages } from '../../../clientResources';

export interface IFilterFieldComponent {
  /**
   * Set focus to this field
   */
  focus(): void;
}

export interface IFilterBarDropdownProps extends Omit<IDropdownProps, 'componentRef'> {
  /**
   * Sets the component reference to this filter field component
   *
   * @param ref Reference to this filter field component
   */
  componentRef?: React.MutableRefObject<IFilterFieldComponent | undefined>;

  /**
   * If true, don't allow values to be selected that are not in the suggested values list.
   */
  restrictToSuggestedValues?: boolean;

  /**
   * If true, select-all updates the filter to include a clause with all currently-suggested items.
   * The default behavior (if false) is that no explicit filter is included when "all" is selected (so "all" and "none" are equivalent).
   */
  selectAllProvidesExplicitItems?: boolean;

  /**
   * If true, only support a single value to be selected in the combo.
   * By default, multiple values can be selected (OR'd)
   */
  singleSelect?: boolean;

  /**
   * If true, use an inline-filter style of dropdown. This turns off any combo-box style editing,
   * shows the field name as a label and hides the dropdown border, etc.
   */
  useInlineStyle?: boolean;

  selectedKeys?: string[];

  /**
   * Callback invoked when the set of filters for this field have been changed
   *
   * @param filters New filters applicable to this field
   */
  onSelectedKeysChanged(filters: string[]): void;

  onSearch?(text: string): void;
};

export function createFieldRef<T>(
  fieldRef: React.MutableRefObject<IFilterFieldComponent | undefined> | undefined,
  focus: (ref: T) => void
): IRefObject<T> | undefined {
  return fieldRef
    ? (dropdownRef) => {
      fieldRef.current = dropdownRef
        ? {
          focus: () => {
            focus(dropdownRef);
          },
        }
        : undefined;
    }
    : undefined;
}

export function createDropdownFieldRef(fieldRef?: React.MutableRefObject<IFilterFieldComponent | undefined>): IRefObject<IDropdown> | undefined {
  return createFieldRef<IDropdown>(fieldRef, (dropdown) => {
    dropdown.focus();
  });
}

export function getFilterCalloutProps(inline?: boolean): ICalloutProps {
  return {
    // Within the filter bar, the button width changes as items in the callout are checked/unchecked.
    // Since these buttons are right-aligned, this causes the left edge of the buttons to move.
    // So, to avoid the open callout shifting while items are checked/unchecked, anchor it to the right edge of the button.
    calloutMaxHeight: inline ? undefined : 400,
    directionalHint: DirectionalHint.bottomRightEdge,
    directionalHintFixed: inline,
  };
}

export function getFilterDropdownStyles(themeContext?: ITheme, inline?: boolean, selected?: boolean): Partial<IDropdownStyles> {
  return {
    caretDown: inline
      ? {
        // color: themeContext.themeStyles.palette.themePrimary,
      }
      : undefined,
    dropdown: inline
      ? {
        //   selectors: {
        //     '&:hover': { backgroundColor: themeContext.themeStyles.palette.neutralLighter },
        //     '&:hover .ms-Dropdown-title': { borderColor: 'transparent' },
        //     '&:hover .ms-Dropdown-caretDown': { color: themeContext.themeStyles.palette.themePrimary },
        //   },
      }
      : undefined,
    root: { overflow: 'hidden' },
    title: {
      // backgroundColor: inline && selected ? themeContext.themeStyles.palette.neutralLight : inline ? 'transparent' : undefined,
      borderColor: inline ? 'transparent' : undefined,
      fontWeight: inline && selected ? 600 : undefined,
    },
  };
}

const selectAllItemKey = '::select-all::';
const noOptionsKey = '::no-options::';
let uniqueKeyPrefixCounter = 1;

function FilterBarDropdownInternal(props: IFilterBarDropdownProps): JSX.Element {
  const { componentRef, selectedKeys = [], selectedKey, onSelectedKeysChanged, singleSelect, useInlineStyle, options, onSearch, ...others } = props;
  const themeContext = useTheme();
  const [filterText, setFilterText] = React.useState('');
  const [selectAll, setSelectAll] = React.useState(false);
  const [tmpSelectedKeys,setTmpSelectedKeys] = React.useState(selectedKeys)
  const filterTextLower = filterText.toLocaleLowerCase();
  const buttonTextValue = React.useMemo(() => {
    if (tmpSelectedKeys.length > 0) {
      return _.filter(options, (v) => {
        return _.findIndex(tmpSelectedKeys, (key) => key === v.key) >= 0;
      })
        .map((v) => v.text)
        .join(',');
    }
    return others.placeholder;
  }, [tmpSelectedKeys, options]);

  // The Office Fabric Dropdown has issues if you reuse keys on your set of options when those options
  // have previously been rendered at a different index. To work around this, we will use unique keys
  // for all options, and use the "data" property to store the actual/underlying key.
  const keyPrefix = `${uniqueKeyPrefixCounter++}::`;

  // Show search if there are 5 or more items or if not in restricted mode (so that the user can add any value)
  const showSearchBox = options.length > 4;

  const filteredOptions = filterText ? options.filter((v) => v.text.toLocaleLowerCase().indexOf(filterTextLower) >= 0) : options;

  function getDropdownOptionKey(key: string): string {
    return `${keyPrefix}${key}`;
  }
  function createDropdownOption(item: IDropdownOption): IDropdownOption {
    return {
      data: item.key,
      key: getDropdownOptionKey(item.key as string),
      text: item.text,
      ariaLabel: item.text,
      title: item.text,
    };
  }
  function onItemSelectionChanged(key: string, selected: boolean): void {
    let newValues: string[];
    if (key === selectAllItemKey) {
      // Check or uncheck Select-All. Clear the selection if unselected or when selected with all options showing.
      if (singleSelect) {
        newValues = [];
      } else {
        setSelectAll(selected);
        newValues = selected ? filteredOptions.filter((v) => v.key !== selectAllItemKey).map((v) => v.key as string) : [];
      }
    } else {
      if (singleSelect) {
        newValues = [key];
      } else if (selectAll && !tmpSelectedKeys?.length) {
        // Unchecking an item when in select-all mode
        newValues = filteredOptions.map((v) => v.key as string).filter((k) => k !== key);
      } else {
        newValues = selected ? tmpSelectedKeys.concat(key) : tmpSelectedKeys.filter((v) => v !== key);
      }
      if (selectAll) {
        setSelectAll(false);
      }
    }
    if (newValues.length !== 0 || tmpSelectedKeys.length !== 0) {
      setTmpSelectedKeys(newValues.length ? newValues : []);
      onSelectedKeysChanged(newValues.length ? newValues : []);
    }
  }

  const dropdownOptions: IDropdownOption[] = [];
  if (filteredOptions.length > 1 && (!singleSelect || tmpSelectedKeys.length > 1)) {
    // Add a SelectAll/Clear item at the top if there are multiple values AND we are in multi-select mode or single-select with an active filter
    dropdownOptions.push(
      createDropdownOption({
        key: selectAllItemKey,
        text: singleSelect ? 'Clear' : 'Select all',
      })
    );
  }
  dropdownOptions.push(...filteredOptions.map(createDropdownOption));
  if (filteredOptions.length === 0) {
    // No dropdown items, show a placeholder item to indicate this.
    dropdownOptions.push(createDropdownOption({ key: noOptionsKey, text: '' }));
  }

  // Construct the set of selected items
  const trueSelectedKeys: string[] = [];
  if (selectAll && !trueSelectedKeys.length) {
    trueSelectedKeys.push(...filteredOptions.map((o) => getDropdownOptionKey(o.key as string)));
  } else {
    for (const value of tmpSelectedKeys) {
      const stringValue = `${value}`;
      if (filteredOptions.find((v) => v.key === stringValue)) {
        trueSelectedKeys.push(getDropdownOptionKey(stringValue));
      }
    }
  }
  if (filteredOptions.length > 1 && trueSelectedKeys.length === filteredOptions.length) {
    trueSelectedKeys.push(getDropdownOptionKey(selectAllItemKey));
  }

  return (
    <Dropdown
      calloutProps={getFilterCalloutProps(useInlineStyle)}
      className={mergeStyles({ flexGrow: 1 })}
      componentRef={createDropdownFieldRef(componentRef)}
      dropdownWidth={useInlineStyle ? 275 : 0}
      multiSelect={!singleSelect}
      onChange={(_ev, item) => {
        if (item) {
          onItemSelectionChanged(item.data, !!item.selected);
        }
      }}
      onRenderItem={(option, defaultRender) => {
        if (option?.data === noOptionsKey) {
          return (
            <div className={mergeStyles({ padding: '10px', textAlign: 'center' })} key={noOptionsKey}>
              No Options
            </div>
          );
        }
        return defaultRender!(option);
      }}
      onRenderList={(listProps, defaultRender) => {
        return (
          <div>
            {showSearchBox && (
              <FilterBarDropdownSearchBox
                filterText={filterText}
                placeholder={INTL.formatMessage(FilterBarDropdownLocalizationFormatMessages.Search)}
                onSearchEnter={() => {
                  if (onSearch) {
                    onSearch(filterText);
                  } else if (filterText && !selectAll) {
                    onItemSelectionChanged(filterText, true);
                  }
                }}
                onSearchChange={(text) => {
                  if (onSearch) {
                    onSearch(text);
                  }
                  setFilterText(text);
                }}
              />
            )}
            {defaultRender!(listProps)}
          </div>
        );
      }}
      onRenderPlaceholder={() => (
        <TooltipHost content={buttonTextValue} overflowMode={TooltipOverflowMode.Parent}>
          {buttonTextValue}
        </TooltipHost>
      )}
      onRenderTitle={() => (
        <TooltipHost content={buttonTextValue} overflowMode={TooltipOverflowMode.Parent}>
          {buttonTextValue}
        </TooltipHost>
      )}
      options={dropdownOptions}
      selectedKey={singleSelect ? trueSelectedKeys[0] : undefined}
      selectedKeys={singleSelect ? undefined : trueSelectedKeys}
      styles={getFilterDropdownStyles(themeContext, useInlineStyle, (tmpSelectedKeys?.length || 0) > 0)}
    />
  );
}

export const FilterBarDropdown = withLocalization(initializeComponent(FilterBarDropdownInternal));