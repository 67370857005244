import { IStackTokens, Link, Stack, Text, useTheme } from '@fluentui/react';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { uploadTMOrZipFile } from '../../../../../redux/documents/documentsActions';
import { DocumentSetTypes } from '../../../../../redux/uploadDocumentsWizard/uploadDocumentsWizard.types';
import { FileInputButton } from '../../../SharedFormElements';
import useSharedUploadFormStyles from '../SharedUploadForm.styles';
import { ISingleDocumentFormProps, ISingleDocumentFormValues } from './SingleDocumentForm.types';

import { useAppDispatch, useAppSelector } from '../../../../../utils/hooks';

const SingleDocumentForm: React.FC<ISingleDocumentFormProps> = ({
  handleCancelAndClose,
  formId,
  subtitle,
  fileInputButton,
}) => {
  const classes = useSharedUploadFormStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const appDispatch = useAppDispatch();
  const {
    uploadDocumentsWizard,
    languages,
    projects,
    workspaces: { currentWorkspace },
  } = useAppSelector((state) => state);

  let sourceLanguage;
  // If current project is undefined, the information will be pulled from uploadDocumentsWizard slice
  if (uploadDocumentsWizard?.sourceLanguage) {
    sourceLanguage = uploadDocumentsWizard.sourceLanguage;
  } else {
    const currentProject = projects.data[projects.currentProject.id];
    sourceLanguage = languages[currentProject?.languagePair.sourceLanguage];
  }

  const initialState: ISingleDocumentFormValues = {
    documentSetType:
      uploadDocumentsWizard.documentSetType === DocumentSetTypes.Dictionary
        ? uploadDocumentsWizard.dictionaryType || ''
        : uploadDocumentsWizard.documentSetType || '',
    // For TM and zip upload, it doesn't matter if source or target language code is used
    languageCode: sourceLanguage?.languageCode || '',
    fileName: '',
    fileUpload: {} as Blob,
  };

  const [formValues, setFormValues] = useState(
    (): ISingleDocumentFormValues => {
      return initialState;
    }
  );

  const [error, setError] = useState<string | undefined>(undefined);

  const largeStackTokens: IStackTokens = {
    childrenGap: theme.spacing.l1,
  };

  const mediumStackTokens: IStackTokens = {
    childrenGap: theme.spacing.m,
  };

  const handleChange = useCallback(
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const inputFile = (event.target as HTMLInputElement).files;

      if (inputFile) {
        setFormValues({
          ...formValues,
          fileUpload: inputFile[0],
          fileName: inputFile[0].name,
        });
      }
    },
    [formValues]
  );

  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();

    if (formValues.fileName.length === 0) {
      setError(t('components.forms.generalErrors.required'));
    } else {
      appDispatch(uploadTMOrZipFile(formValues, currentWorkspace.id));
      handleCancelAndClose();
    }
  };

  const unselectFile = (): void => {
    setFormValues({
      ...formValues,
      fileName: initialState.fileName,
      fileUpload: initialState.fileUpload,
    });
  };

  return (
    <Stack className={classes.root} tokens={largeStackTokens}>
      <Stack.Item>
        <Stack tokens={mediumStackTokens}>
          <Stack.Item>
            <Text>{subtitle.sizeAndFormatRestriction}</Text>
          </Stack.Item>
          <Stack.Item>
            <Text>
              {`${subtitle.detailInformation} `}
              {subtitle.linkUrl && (
                <Link href={subtitle.linkUrl} target="_blank" rel="noopener noreferrer">
                  {subtitle.linkText}
                </Link>
              )}
            </Text>
          </Stack.Item>
        </Stack>
      </Stack.Item>

      <Stack.Item>
        <form id={formId} className={classes.form} onSubmit={handleSubmit}>
          <FileInputButton
            text={fileInputButton.text}
            label={fileInputButton.label}
            inputId={fileInputButton.id}
            fileName={formValues.fileName || ''}
            onChange={handleChange}
            accept={fileInputButton.acceptedFormats}
            unselectFile={unselectFile}
            error={error}
          />
        </form>
      </Stack.Item>
    </Stack>
  );
};

export default SingleDocumentForm;
