// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { schema as BaseSchema, NormalizedSchema, normalize } from 'normalizr';

const normalizeResponse = <T, ReturnType = string>(
  responseData: T[],
  // eslint-disable-next-line no-shadow
  schema: BaseSchema.Array
): NormalizedSchema<T, ReturnType[]> => {
  return normalize(responseData, schema);
};

export default normalizeResponse;
