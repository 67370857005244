import { Stack, Text, FontIcon, TextField, useTheme, ComboBox, MessageBar, MessageBarType, Icon, StackItem, IComboBoxOption, Link } from "@fluentui/react";
import styled from "styled-components";
import { INTL } from "../../../util/intlUtil";
import { CreateResourceWizardMessages } from "../../../clientResources";
import { AzureSku, AzureSubscription, ResourceGroup, Subscription } from "../../../models";
import { useEffect, useState } from "react";

const StyledContainer = styled(Stack)`
width: 100%;
>* {
    margin-bottom: 20px;
    width: 100%;
}

a {
    text-decoration: none;
    position: relative;
    cursor: pointer;
    border-color: transparent;

    i.popup-icon {
        position: absolute;
        top: 10px;
        right: 36px;
        font-size: 10px;
    }

    .ms-MessageBar-icon {
        margin: 16px 0 16px 16px;
    }

    .ms-MessageBar-text {
        margin: 16px 16px 16px 8px;
    }

    a {
        text-decoration: underline;
        color: ${props => props.theme ? props.theme.palette.blueMid : "#00188f"};
    }
}

.crw-form-item {
    input {
      border: none !important;
    }
    .crw-form-item-label {
      width: 331px;
      height: 20px;
      margin-bottom: 5px;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: ${props => props.theme ? props.theme.palette.neutralPrimary : "#323130"};
    }

    div.crw-form-item-input, div.ms-ComboBox {
      width: 331px;
      max-width: 100%;
      height: 32px;
      input {
        line-height: 32px;
        padding: 0 8px;
        border: none;
        box-sizing: border-box;
        background: ${props => props.theme ? props.theme.palette.white : "#ffffff"};
        border: 1px solid ${props => props.theme ? props.theme.palette.neutralPrimary : "#323130"};
        border-radius: 2px;
      }
    }

    .crw-form-item-input {
        max-width: 100%;
        width: 331px;
        height: 32px;
        line-height: 32px;
    }
  }

  .crw-form-item-tip-container {
    display: flex;
    flex-direction: row;
  }

  .crw-form-item-tips-container {
    margin-top: 25px;
    align-self: start;
    margin-left: 41px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 12px;
    width: 243px;
    background: ${props => props.theme ? props.theme.palette.white : "#ffffff"};
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    i {
      width: 15.86px;
      height: 16px;
      /* Coulnn't found this color in theme */
      color: #424242; 
    }
    span {
      width: 182px;
      margin-left: 7px;
      font-size: 10px;
      line-height: 12px;
      color: ${props => props.theme ? props.theme.palette.neutralPrimary : "#323130"};
    }
  }

  .crw-form-item, .ms-ComboBox {
    max-width: 100%;
  }

  @media (max-width: 660px) {
  .crw-form-item-tip-container {
    flex-wrap: wrap;
    .crw-form-item-tips-container {
        margin-left: 10px;
    }
  }
}

@media (max-width: 648px) {
  .crw-form-item-tip-container {
    flex-wrap: wrap;
    .crw-form-item-tips-container {
        margin-left: 0;
    }
  }
}
`;

const ErrorMsg = styled((props: { msg: string; color: string; className: string; }) => (
    <Stack className={props.className} >
        <Icon iconName="ErrorBadge" />
        <span>{props.msg}</span>
    </Stack>
))`
    color: ${props => props.color};
    height: 16px;
    margin-top: 4px;
    line-height: 16px;
    flex-direction: row;
    i {
        margin-top: 1px;
    }
    span {
        margin-left: 4px;
        height: 16px;
    }
`;

export interface ICreateResourceMsg {
    url?: string;
    message: string | JSX.Element;
    type?: "error" | "info" | "warning";
    kind: "subscription" | "resourceGroup" | "resourceName" | "location" | "pricingTier" | "complete";
};

export interface ICreateResourceStepProps {

    subscriptions: AzureSubscription[];
    resourceGroups: ResourceGroup[];
    selectedResourceGroupId: string;

    resourceName: string;
    locations: {
        id: string;
        displayName: string;
    }[];
    selectedSubscriptionId: string;
    selectedLocationId: string;
    pricingTiers: {
        code: AzureSku['code'];
        name: AzureSku['name'];
        displayName: string;
    }[];
    selectedPricingTierId: string;

    onChangeSubscription: (subscriptionId: string) => void;
    onChangeResourceGroup: (resourceGroupId: string) => void;
    onChangeLocation: (locationId: string) => void;
    onChangePricingTier: (pricingTierId: string) => void;
    onChangeResourceName: (resourceName: string) => void;
    onBlurResourceName: () => void;

    pricingTierDetail?: string;
    pricingTierDetailLink?: string;
    messages?: ICreateResourceMsg[];
};

export const CreateResourceStep = (props: ICreateResourceStepProps) => {

    const theme = useTheme()

    const [subscriptions, setSubscriptions] = useState([] as IComboBoxOption[]);
    const [resourceGroups, setResourceGroups] = useState([] as IComboBoxOption[]);
    const [locations, setLocations] = useState([] as IComboBoxOption[]);
    const [pricingTiers, setPricingTiers] = useState([] as IComboBoxOption[]);

    const [subscriptionMsg, setSubscriptionMsg] = useState([] as ICreateResourceMsg[]);
    const [resourceGroupMsg, setResourceGroupMsg] = useState([] as ICreateResourceMsg[]);
    const [resourceNameMsg, setResourceNameMsg] = useState([] as ICreateResourceMsg[]);
    const [locationMsg, setLocationMsg] = useState([] as ICreateResourceMsg[]);
    const [pricingTierMsg, setPricingTierMsg] = useState([] as ICreateResourceMsg[]);
    const [completeMsg, setCompleteMsg] = useState([] as ICreateResourceMsg[]);

    useEffect(() => {
        setSubscriptions(props.subscriptions.map(item => ({
            isSelected: props.selectedSubscriptionId === item.subscriptionId,
            key: item.subscriptionId,
            value: item.subscriptionId,
            text: item.displayName,
        })))
    }, [props.subscriptions, props.selectedSubscriptionId]);

    useEffect(() => {
        setResourceGroups(props.resourceGroups.map(item => ({
            isSelected: props.selectedResourceGroupId === item.id,
            key: item.id,
            value: item.id,
            text: item.name,
        })))
    }, [props.resourceGroups, props.selectedResourceGroupId]);

    useEffect(() => {
        setLocations(props.locations.map(item => ({
            isSelected: props.selectedLocationId === item.id,
            key: item.id,
            value: item.id,
            text: item.displayName,
        })));
    }, [props.locations, props.selectedLocationId]);

    useEffect(() => {
        setPricingTiers(props.pricingTiers.map(item => ({
            isSelected: props.selectedPricingTierId === item.displayName,
            key: item.displayName,
            value: item.displayName,
            text: item.displayName,
        })))
    }, [props.pricingTiers, props.selectedPricingTierId]);

    useEffect(() => {
        const messages: { [key: string]: ICreateResourceMsg[] } = {
            complete: [],
            subscription: [],
            resourceGroup: [],
            resourceName: [],
            pricingTier: [],
            location: [],
        };
        if (props.messages && props.messages.length > 0) props.messages.forEach(item => {
            switch (item.kind) {
                case "complete":
                    messages.complete.push(item);
                    break;
                case "subscription":
                    messages.subscription.push(item);
                    break;
                case "resourceGroup":
                    messages.resourceGroup.push(item);
                    break;
                case "resourceName":
                    messages.resourceName.push(item);
                    break;
                case "pricingTier":
                    messages.pricingTier.push(item);
                    break;
                case "location":
                    messages.location.push(item);
                    break;
            }
        });
        setSubscriptionMsg(messages.subscription);
        setResourceGroupMsg(messages.resourceGroup);
        setResourceNameMsg(messages.resourceName);
        setLocationMsg(messages.location);
        setPricingTierMsg(messages.pricingTier);
        setCompleteMsg(messages.complete);
    }, [props.messages]);

    return (
        <StyledContainer theme={theme}>

            <Stack className="crw-form-item-tip-container">
                <StackItem className="crw-form-item">
                    <ComboBox
                        className="crw-form-item crw-form-item-subscription"
                        selectedKey={subscriptions.length > 0 ? props.selectedSubscriptionId : null }
                        placeholder={INTL.formatMessage(CreateResourceWizardMessages.SelectSubscription)}
                        label={INTL.formatMessage(CreateResourceWizardMessages.Subscription)}
                        allowFreeInput
                        autoComplete="on"
                        onChange={(_, option) => props.onChangeSubscription(option.key as string)}
                        options={subscriptions}
                        iconButtonProps={{ ariaDescription: "dropdown button", role: "button" }}
                    />
                    {subscriptionMsg.map((item, index) => (
                        <ErrorMsg key={`subscriptionMsg#${index}`} className="error-msg" msg={item.message as string} color={theme.palette.redDark} />
                    ))}
                </StackItem>
            </Stack>

            <Stack className="crw-form-item-tip-container">
                <StackItem className="crw-form-item">
                    <ComboBox
                        className="crw-form-item crw-form-item-resourceGroup"
                        selectedKey={resourceGroups.length > 0 ? props.selectedResourceGroupId : null }
                        placeholder={INTL.formatMessage(CreateResourceWizardMessages.SelectGroup)}
                        label={INTL.formatMessage(CreateResourceWizardMessages.AzureResourceGroup)}
                        allowFreeInput
                        autoComplete="on"
                        onChange={(_, option) => props.onChangeResourceGroup(option.key as string)}
                        options={resourceGroups}
                        iconButtonProps={{ ariaDescription: "dropdown button", role: "button" }}
                    />
                    {resourceGroupMsg.map((item, index) => (
                        <ErrorMsg key={`resourceGroupMsg#${index}`} className="error-msg" msg={item.message as string} color={theme.palette.redDark} />
                    ))}
                </StackItem>
            </Stack>

            <Stack className="crw-form-item-tip-container">
                <StackItem className="crw-form-item">
                    <Text className="crw-form-item-label">
                        {INTL.formatMessage(CreateResourceWizardMessages.ResourceNameInputTitle)}
                    </Text>
                    <TextField
                        value={props.resourceName}
                        className="crw-form-item-input"
                        placeholder={INTL.formatMessage(CreateResourceWizardMessages.NameYourResource)}
                        onChange={(_, e) => props.onChangeResourceName(e)}
                        onBlur={props.onBlurResourceName}
                    />
                    {resourceNameMsg.map((item, index) => (
                        <ErrorMsg key={`resourceNameMsg#${index}`} className="error-msg" msg={item.message as string} color={theme.palette.redDark} />
                    ))}
                </StackItem>
                <StackItem className="crw-form-item-tips-container">
                    <FontIcon iconName="InfoSolid" />
                    <span>{INTL.formatMessage(CreateResourceWizardMessages.ResourceNameInputTip)}</span>
                </StackItem>
            </Stack>

            <Stack className="crw-form-item-tip-container">
                <StackItem>
                    <ComboBox
                        className="crw-form-item crw-form-item-location"
                        selectedKey={locations.length > 0 ? props.selectedLocationId : null }
                        placeholder={INTL.formatMessage(CreateResourceWizardMessages.SelectLocation)}
                        onChange={(_, option) => props.onChangeLocation(option.key as string)}
                        iconButtonProps={{ ariaDescription: "dropdown button", role: "button" }}
                        options={locations}
                        allowFreeInput
                        autoComplete="on"
                        label={INTL.formatMessage(CreateResourceWizardMessages.Location)}
                    />
                    {locationMsg.map((item, index) => (
                        <ErrorMsg key={`locationMsg#${index}`} className="error-msg" msg={item.message as string} color={theme.palette.redDark} />
                    ))}
                </StackItem>
                <StackItem className="crw-form-item-tips-container">
                    <FontIcon iconName="InfoSolid" />
                    <span>{INTL.formatMessage(CreateResourceWizardMessages.LocationSelectorTip)}</span>
                </StackItem>
            </Stack>

            <Stack className="crw-form-item-tip-container">
                <StackItem>
                    <ComboBox
                        className="crw-form-item crw-form-item-pricingTier"
                        selectedKey={pricingTiers.length > 0 ? props.selectedPricingTierId : null }
                        placeholder={INTL.formatMessage(CreateResourceWizardMessages.SelectTier)}
                        onChange={(_, option) => props.onChangePricingTier(option.key as string)}
                        options={pricingTiers}
                        allowFreeInput
                        autoComplete="on"
                        iconButtonProps={{ ariaDescription: "dropdown button", role: "button" }}
                        label={INTL.formatMessage(CreateResourceWizardMessages.PricingTier)}
                    />
                    {pricingTierMsg.map((item, index) => (
                        <ErrorMsg key={`pricingTierMsg#${index}`} className="error-msg" msg={item.message as string} color={theme.palette.redDark} />
                    ))}
                    {props.pricingTierDetail && <Link underline={true} href={props.pricingTierDetailLink}>{props.pricingTierDetail}</Link>}
                </StackItem>
                <StackItem className="crw-form-item-tips-container">
                    <FontIcon iconName="InfoSolid" />
                    <span>{INTL.formatMessage(CreateResourceWizardMessages.PricingTierSelectorTip)}</span>
                </StackItem>
            </Stack>
            {completeMsg.map((item, index) => (
                <a key={`completeMsg#${index}`} href={item.url} className="crw-form-item-tip-container">
                    <Icon iconName="NavigateExternalInline" className="popup-icon" />
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        truncated={true}
                    >
                        {item.message}
                    </MessageBar>
                </a>
            ))}

        </StyledContainer>
    )
};

