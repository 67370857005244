export const SET_MODEL_NAME = 'SET_MODEL_NAME';
export const CLEAR_MODEL_NAME = 'CLEAR_MODEL_NAME';

export const SET_TRAINING_TYPE = 'SET_TRAINING_TYPE';

export const UPDATE_ADD_DOCUMENTS = 'UPDATE_ADD_DOCUMENTS';
export const UPDATE_REMOVE_DOCUMENTS = 'UPDATE_REMOVE_DOCUMENTS';
export const CLEAR_DOCUMENTS = 'CLEAR_DOCUMENTS';

export const CLEAR_TRAIN_MODEL_DATA = 'CLEAR_TRAIN_MODEL_DATA';

export interface ISetModelName {
  type: typeof SET_MODEL_NAME;
  payload: string;
}

export interface IClearModelName {
  type: typeof CLEAR_MODEL_NAME;
}

export interface ISetTrainingType {
  type: typeof SET_TRAINING_TYPE;
  payload: string;
}

export interface IUpdateAddDocuments {
  type: typeof UPDATE_ADD_DOCUMENTS;
  payload: number[];
}

export interface IUpdateRemoveDocuments {
  type: typeof UPDATE_REMOVE_DOCUMENTS;
  payload: number[];
}

export interface IClearDocuments {
  type: typeof CLEAR_DOCUMENTS;
}

export interface IClearTrainModelData {
  type: typeof CLEAR_TRAIN_MODEL_DATA;
}
export type TrainModelDispatchTypes =
  | ISetModelName
  | IClearModelName
  | ISetTrainingType
  | IUpdateAddDocuments
  | IUpdateRemoveDocuments
  | IClearDocuments
  | IClearTrainModelData;
