enum ErrorCategories {
  Model = 'model',
  Models = 'models',
  TestModelResults = 'test model results',
  Document = 'document',
  Documents = 'documents',
  Workspaces = 'workspaces',
  Project = 'project',
  Projects = 'projects',
  Subscription = 'subscription',
  ModelRegions = 'model regions',
  BillingRegion = 'billing region',
  Categories = 'categories',
  SupportedLanguages = 'supported languages',
  Fallback = 'fallback',
  UploadHistory = 'upload history',
}

export default ErrorCategories;
