import { KeyCodes, mergeStyles, SearchBox } from '@fluentui/react';
import * as React from 'react';
import { initializeComponent, withLocalization } from '../../../services/localization';

export interface IFilterBarDropdownSearchBoxProps {
  filterText: string;
  helpTooltip?: string;
  placeholder: string;
  onSearchEnter(): void;
  onSearchChange(text: string): void;
}

function FilterBarDropdownSearchBoxInternal(props: IFilterBarDropdownSearchBoxProps): JSX.Element {
  const { filterText, helpTooltip, onSearchEnter, placeholder, onSearchChange } = props;

  return (
    <div className={mergeStyles({ padding: '10px 8px', display: 'flex' })}>
      <SearchBox
        autoComplete="off"
        className={mergeStyles({ flexGrow: 1 })}
        data-automation-id="filter-bar-dropdown-search-box"
        disableAnimation
        onChange={(_event, text) => {
          onSearchChange(text || '');
        }}
        onKeyDown={(e) => {
          if (filterText && (e.which === KeyCodes.home || e.which === KeyCodes.end)) {
            // If home or end are pressed in the text box, allow the default events
            // by stopping propagation to the parent focus zone (which will prevent default).
            // This allows home and end to work as expected when the text box has focus, moving the cursor to the
            // start or end position. Don't do this if there is no text entered so that the End key goes to the last menu item.
            e.stopPropagation();
          }
        }}
        onEscape={() => {
          onSearchChange('');
        }}
        onSearch={onSearchEnter}
        placeholder={placeholder}
        styles={{ field: { letterSpacing: 'normal !important' }, icon: { opacity: 1 }, iconContainer: { width: 32 } }}
        value={filterText}
      />
      {helpTooltip && (
        <></>
        // <div className={mergeStyles({ alignSelf: 'center', marginLeft: '8px' })}>
        //   <IconWithTooltip iconProps={{ iconName: Icons.Unknown }} tooltip={helpTooltip} />
        // </div>
      )}
    </div>
  );
}

export const FilterBarDropdownSearchBox = withLocalization(initializeComponent(FilterBarDropdownSearchBoxInternal));