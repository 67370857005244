import React from "react";
import { Spinner, SpinnerSize } from "@fluentui/react";
import { initializeComponent, withLocalization } from "../../../services/localization";

import styled from "styled-components";

export interface ILoadingProps {
    message?: string;
    subMessage?: string;
    size?: SpinnerSize;
}

const LoadingStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;

    .container {
        flex: 1;
    }

    .ms-Spinner--large {
        width: 114px;
        height: 114px;
        margin-top: 30px;
    }

    .message {
        height: auto;
        margin: 30px 0px 12px 0px;
        font-size: 21px;
    }

    .subMessage {
        font-size: 14px;
    }
`
export function LoadingWrapper(props: ILoadingProps) {
    return (
        <LoadingStyled>
            <div className="container">
                <Spinner size={props.size || SpinnerSize.large} />
                <div>
                    <div className="message">{props.message}</div>
                    <div className="subMessage">{props.subMessage}</div>
                </div>
            </div>
        </LoadingStyled>
    );
}

export const Loading = withLocalization(initializeComponent(LoadingWrapper));