export enum AzureEnvironment {
    AzureEnvironmentPublic = "public",
    AzureEnvironmentMooncake = "mooncake",
    AzureEnvironmentFairfax = "fairfax",
    AzureEnvironmentUSNat = "usnat",
    AzureEnvironmentUSSec = "ussec",
}

function initializeEnvironment(): AzureEnvironment {
    let environment: AzureEnvironment;

    const hostname = window.location.hostname.toLowerCase();

    if (hostname.endsWith(".azure.cn")) {
        environment = AzureEnvironment.AzureEnvironmentMooncake;
    } else if (hostname.endsWith(".azure.us")) {
        environment = AzureEnvironment.AzureEnvironmentFairfax;
    } else if (hostname.endsWith(".eaglex.ic.gov")) {
        environment = AzureEnvironment.AzureEnvironmentUSNat;
    } else if (hostname.endsWith(".microsoft.scloud")) {
        environment = AzureEnvironment.AzureEnvironmentUSSec;
    } else {
        environment = AzureEnvironment.AzureEnvironmentPublic;
    }

    return environment;
}

export const azureEnvironment = initializeEnvironment();
