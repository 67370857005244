export enum AppBarPanels {
  Notification = 'notification',
  Help = 'help',
  Feedback = 'feedback',
}

export interface IAppBarPanel {
  type: AppBarPanels;
  name: string;
}
