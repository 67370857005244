import {
  useTheme,
  IDocumentCardStyles,
  DocumentCard,
  Link,
  Icon,
  Text,
  Stack,
  ILinkProps,
  ActionButton,
  IIconStyles,
  IContextualMenuItem,
  FontIcon,
  IStyle,
  mergeStyleSets
} from "@fluentui/react";
import { FontSizes } from '@fluentui/theme';
import React, { useState } from "react";
import { CSSProperties, ReactNode } from "react";
import { initializeComponent, useLocalization, withLocalization } from "../../../services/localization";
import { CardLocalizationFormatMessages } from "../../../clientResources";
import { INTL } from "../../../util/intlUtil";
import styled from "styled-components";
import { isEmpty } from 'lodash';
import { PreviewTag } from "../Tag/PreviewTag";
import { defaultTheme } from "../../../themes";
import { useId } from "@fluentui/react-hooks";

export interface ICardWrapperProp extends ILinkProps {
  linkProps?: ILinkProps;
  clickHandle?: () => void;
  linkPropsDropdownOptions?: IContextualMenuItem[];
};

const CardWrapper = styled((props:ICardWrapperProp) =>{
  if (!isEmpty(props.linkProps?.href) && !props.linkPropsDropdownOptions &&!props.clickHandle) {
    return (
      <Link {...props.linkProps} className={props.className}>
        {props.children}
      </Link>
    );
  } else {
    return <div className={props.className}>{props.children}</div>;
  }
})`
  display:inline-block;
  border-radius:8px;
  &&[type="button"]{
    margin:10px;
  }
  &&:focus{
    outline:#000 solid 2px;
    text-decoration: none;
  }
  &&:hover{
    text-decoration: none;
  }
`

export enum CardTypes {
  CardWithIllustration = 260,
  CardWithNoIllustration = 260,
  CardWithIcon = 208,
  CardWithCustomDesign = 199
}

export interface ICardProp {
  cardType?: keyof typeof CardTypes;

  /**
   * Only applicable if the CardType is CardWithIcon
   */
  headerIconText?: string;

  isCompactMode?: boolean;
  title: string;
  // props titleHeadingTag is used for the requirement to change the title element tag, default is span
  titleHeadingTag?: React.ElementType<React.HTMLAttributes<HTMLElement>>;
  subTitle?: JSX.Element;
  preview?: string,
  description: string;
  iconName?: string;
  iconUrl?: URL;

  isPreview?: boolean;

  /**
   * Sample usage for rendering SVG icons:
   * const CreateArtwork = require('./portal/icons/CreateArtwork.svg').default;
   * <CreateArtwork />
   */
  iconElement?: JSX.Element;
  /**
   * description for Illustration
   */
  illustrationDescription?: string;
  linkTitle: string;
  linkProps?: ILinkProps;
  onClick?: () => void;
  linkPropsDropdownOptions?: IContextualMenuItem[];
  linkIconName?: string;
  /**
   * default value: false
   */
  nonInteractive?: boolean
};

export interface IStyledDocumentCardProp {
  onClick?: () => void;
  children: React.ReactNode;
  width: number;
  role?: string;
  selected?: boolean;
  nonInteractive?: boolean;
}

const iconStyles: IIconStyles = { root: { fontSize: "14px", textDecoration: "underline" } };

const getSamplesLinkOptions = (linkPropsDropdownOptions: IContextualMenuItem[]): IContextualMenuItem[] =>
  linkPropsDropdownOptions.map(
    (link) =>
    ({
      ...link,
      onRenderContent: () => (
        <Link as="span" underline>
          {`${link.text} `}
          <Icon iconName="MiniExpand" styles={iconStyles} />
        </Link>
      ),
    } as IContextualMenuItem)
  );

const CardInternal = (props: ICardProp) => {
  const { preview, onClick, linkProps, linkPropsDropdownOptions,nonInteractive=false,titleHeadingTag="span",...others } = props;
  const theme = useTheme();
  const [isLinkPropsDropdownExpanded, setIsLinkPropsDropdownExpanded] = useState(false);
  const id = useId('card_');
  const styles = {
    headerWrapper: {
      root: {
        margin: "12px 12px 0px 12px",
        div: {
          display: "flex",
          flexDirection: "row",
          span: {
            display: "flex-item",
            grow: "auto",
          },
          ".preview-tag": {
            margin: "2px 0 0 7px",
          },
        }
      }
    },
    header: {
      fontSize: "14px",
      fontWeight: 600,
      borderBottom: props.cardType === "CardWithCustomDesign" ? "2px solid #F7630C" : 0,
      paddingBottom: props.cardType === "CardWithCustomDesign" ? "5px" : 0,
      margin: 0
    },
    subTitleStyle: {
      fontSize: "12px",
      padding: "0 12px",
      color: "#707070"
    },
    image: {
      textAlign: "center",
      minHeight: "153px",
      maxHeight: "153px",
      background: "#f2f2f2",
      borderRadius: "8px 8px 0 0",
      overflow: "hidden",
      width: "100%",
      display: "flex",
      alignItems: "center",
      position: "relative",
    } as CSSProperties,
    descriptionWrapper: {
      padding: "12px",
      paddingBottom: props.isCompactMode ? "0px" : "12px",
    },
    description: {
      fontSize: "12px",
      color: "#242424",
      lineHeight: "16px"
    },
    linkWrapper: {
      padding: "12px",
      paddingTop: props.isCompactMode ? "0px" : "12px",
    },
    link: {
      fontSize: "12px",
      lineHeight: "16px",
    },
    linkStyle: {
      fontSize: "14px",
      color: props.linkProps?.disabled ? "#a19f9d" : "#106ebe",
    },
    illustrationDescriptionStyle:{
      zIndex: '10',
      left: '8px',
      top: '8px',
      lineHeight: '16px',
      padding: '2px 8px 3px 8px',
      background: '#C1DBEE',
      borderRadius: '16px',
      position: 'absolute',
      color:`${defaultTheme.body.palette.neutralDark}`
    } as CSSProperties
  };

  return (
    <CardWrapper
      clickHandle={onClick}
      linkPropsDropdownOptions = {linkPropsDropdownOptions}
      linkProps = {linkProps}
    >
      <StyledDocumentCard onClick={props.onClick} width={CardTypes[props.cardType]} nonInteractive={nonInteractive}>
        {props.cardType === "CardWithIllustration" && (
          <Stack>
            {
              props?.illustrationDescription ?
                <Stack
                  style={styles.illustrationDescriptionStyle}>
                    <Text variant="xSmall">{props?.illustrationDescription}</Text>
                </Stack> : null
            }
            {
              props.iconUrl ?
                (<Stack style={styles.image}>
                  <Stack grow style={{ width: "100%", backgroundSize: "cover", background: `url(${props.iconUrl})` }} ></Stack>
                </Stack>)
                : (props.iconElement ?
                  (<Stack style={styles.image}>
                    <Stack style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}>{props.iconElement}</Stack>
                  </Stack>) : <></>)
            }
          </Stack>
        )}
        {props.cardType === "CardWithIcon" && (
          <Stack
            horizontal
            style={{
              padding: "12px 12px 0",
              alignItems: "center",
            }}
          >
            <Stack
              style={{
                width: "28px",
                height: "28px",
                background: "#773ADC",
                borderRadius: "4px",
                position: "relative",
              }}
            >
              <Icon
                iconName={props.iconName || "AlignCenter"}
                style={{
                  color: "#FFFFFF",
                  width: "16px",
                  height: "16px",
                  position: "absolute",
                  left: "calc(50% - 14px/2)",
                  top: "calc(50% - 22px/2)",
                }}
              />
            </Stack>
            <Text style={{ fontSize: FontSizes.size12, paddingLeft: "8px" }}>{props.headerIconText}</Text>
          </Stack>
        )}
        <Stack styles={styles.headerWrapper}>
          <Stack>
            <Text as={titleHeadingTag} style={styles.header} id={`${id}_title`}>{props.title}</Text>
            {props.isPreview && <PreviewTag className="preview-tag" />}
          </Stack>
        </Stack>
        {props.subTitle && <Text style={styles.subTitleStyle}>{props.subTitle}</Text>}
        {preview && <Text style={styles.subTitleStyle}>{preview}</Text>}
        <Stack grow style={styles.descriptionWrapper}>
          <Text style={styles.description}>{props.description}</Text>
        </Stack>
        {["CardWithIllustration", "CardWithNoIllustration"].includes(props.cardType) && (
          <>
            {linkPropsDropdownOptions?.length > 0 ? (
              <Stack style={{ padding: "0 2px" }}>
                <ActionButton
                  onClick={() => { }}
                  data-is-focusable
                  menuIconProps={{
                    iconName: isLinkPropsDropdownExpanded ? "ChevronUp" : "ChevronDown",
                    title: isLinkPropsDropdownExpanded ? INTL.formatMessage(CardLocalizationFormatMessages.CollapseCardLink) : INTL.formatMessage(CardLocalizationFormatMessages.ExpandCardLink)
                  }}
                  menuProps={{ items: getSamplesLinkOptions(linkPropsDropdownOptions) }}
                  onMenuClick={() => { setIsLinkPropsDropdownExpanded(!isLinkPropsDropdownExpanded) }}
                >
                  {props.linkTitle}
                </ActionButton>
              </Stack>
            ) : (
              <Stack style={styles.linkWrapper}>
                {props.onClick ? <Link {...props.linkProps} aria-describedby={`${`${id}_title`}`}>
                  {props.linkTitle}
                  {props.linkIconName && <FontIcon style={{ marginLeft: "3px" }} iconName={props.linkIconName} />}
                </Link> : <Text style={styles.linkStyle}>
                  {props.linkTitle}
                  {props.linkIconName && <FontIcon style={{ marginLeft: "3px" }} iconName={props.linkIconName} />}
                </Text>}
              </Stack>
            )}
          </>
        )}
      </StyledDocumentCard>
    </CardWrapper>
  );
};

export function StyledDocumentCard(props: IStyledDocumentCardProp): JSX.Element {
  const theme = useTheme();
  const { nonInteractive } = props;
  const documentCardDefaultStyles = {
    root: {
      "display": "flex",
      "maxWidth": `${props.width}px!important`,
      "minWidth": `${props.width}px!important`,
      "flexDirection": "column",
      "border": "none",
      "boxShadow": "none",
      "background": theme.palette.white,
      "borderRadius": "8px",
      "height": "100%",
      '@media (max-width: 480px)': {
        "minWidth": props.width < 206 ? `${props.width}px!important` : 'auto !important',
      },
      "::after": {
        content: `" "!important`,
        width: "100%",
        height: "100%",
        position: "absolute",
        outline: "2px solid transparent",
        boxShadow: nonInteractive ? 'none' : `0px 2px 4px ${theme.palette.neutralQuaternary}, 0px 0px 2px ${theme.palette.neutralLight}`,
        border: props.selected ? `solid 2px ${theme.palette.themePrimary} !important` : nonInteractive ? `solid 0.5px ${theme.palette.neutralLight} !important` : "none !important",
        borderRadius: "8px"
      },
    },
  } as IDocumentCardStyles;

  const documentCardHoverStyles = {
    root: {
      ":hover": {
        "border": "none",
        "boxShadow": "none",
        "background": "#fafafa",
        "::after": {
          boxShadow: `0px 4px 8px ${theme.palette.neutralQuaternary}, 0px 0px 2px ${theme.palette.neutralLight}`,
        },
      },
    }
  }

  const documentCardStyles = nonInteractive ? documentCardDefaultStyles : mergeStyleSets(documentCardDefaultStyles,documentCardHoverStyles)
  return (
    <DocumentCard onClick={props.onClick} styles={documentCardStyles} role={props.role ?? "none"}>
      {props.children}
    </DocumentCard>
  );
}

export const Card = withLocalization(initializeComponent(CardInternal));