export const CLEAR_ALL_ENTITY_SELECTIONS = 'CLEAR_ALL_ENTITY_SELECTIONS';
export const SET_SELECTED_MODEL = 'SET_SELECTED_MODEL';
export const CLEAR_SELECTED_MODEL = 'CLEAR_SELECTED_MODEL';
export const SET_SELECTED_PROJECT = 'SET_SELECTED_PROJECT';
export const CLEAR_SELECTED_PROJECT = 'CLEAR_SELECTED_PROJECT';
export const SET_SELECTED_DOCUMENT = 'SET_SELECTED_DOCUMENT';
export const CLEAR_SELECTED_DOCUMENT = 'CLEAR_SELECTED_DOCUMENT';
export const SET_SELECTED_WORKSPACE = 'SET_SELECTED_WORKSPACE';
export const CLEAR_SELECTED_WORKSPACE = 'CLEAR_SELECTED_WORKSPACE';

/**
 * An entity selection can be any item within the application a user can select for mutation.
 * Entity selections should be the ID of the selected item that can then point back to its' data
 * object in the Store.
 */
export interface IEntitySelectionItem {
  [key: string]: string | string[];
}

export interface IClearAllEntitySelections {
  type: typeof CLEAR_ALL_ENTITY_SELECTIONS;
}

export interface ISetSelectedProject {
  type: typeof SET_SELECTED_PROJECT;
  payload: string;
}
export interface IClearSelectedProject {
  type: typeof CLEAR_SELECTED_PROJECT;
}

export interface ISetSelectedModel {
  type: typeof SET_SELECTED_MODEL;
  payload: string;
}
export interface IClearSelectedModel {
  type: typeof CLEAR_SELECTED_MODEL;
}

export interface ISetSelectedDocuments {
  type: typeof SET_SELECTED_DOCUMENT;
  payload: string[];
}

export interface IClearSelectedDocuments {
  type: typeof CLEAR_SELECTED_DOCUMENT;
}

export interface ISetSelectedWorkspace {
  type: typeof SET_SELECTED_WORKSPACE;
  payload: string;
}

export interface IClearSelectedWorkspace {
  type: typeof CLEAR_SELECTED_WORKSPACE;
}

export type EntitySelectionDispatchTypes =
  | IClearAllEntitySelections
  | ISetSelectedProject
  | IClearSelectedProject
  | ISetSelectedModel
  | IClearSelectedModel
  | ISetSelectedDocuments
  | IClearSelectedDocuments
  | ISetSelectedWorkspace
  | IClearSelectedWorkspace;
