import { Stack, StackItem, ActionButton, IIconProps, IButtonStyles, useTheme, IStackProps } from "@fluentui/react";
import React, { FunctionComponent, ReactElement } from "react";
import { useBoolean } from "@fluentui/react-hooks";
import { initializeComponent, withLocalization } from "../../../services/localization";
import styled from "styled-components";
import { IWizardFlowMenuItem, generateLeftMenu } from "./WizardFlow";

type ReactElementChildren = ReactElement<WizardFullScreenProps, FunctionComponent>;

const findByType = (
    children: ReactElementChildren[],
    component: FunctionComponent
): ReactElementChildren => {
    const result: ReactElementChildren[] = [];
    /* This is the array of result since Article can have multiple times the same sub-component */
    const type = [component.displayName || component.name];
    /* We can store the actual name of the component through the displayName or name property of our sub-component */
    React.Children.forEach<ReactElementChildren>(
        children,
        (child: ReactElementChildren) => {
            const childType = child && child.type && (child.type.displayName || child.type.name);
            if (!result.length) {
                if (type.includes(childType)) {
                    result.push(child);
                }
                const childResult = findByType(child?.props?.children, component);
                childResult && result.push(childResult);
            }
        }
    );

    /* Then we go through each React children, if one of matches the name of the sub-component we’re looking for we put it in the result array */
    return result[0];
};

const Header: FunctionComponent = () => null;
const Subheader: FunctionComponent = () => null;
const Content: FunctionComponent = () => null;
const Footer: FunctionComponent = () => null;

export interface WizardFullScreenProps extends IStackProps {
    heading: string;
    menuItems: IWizardFlowMenuItem[];
    currentKey?: string;
    children?: ReactElementChildren[];
    menuWidth?: number | string;
    /**
     * @description  Use style to determine the width of this dialog
     */
    innerWidth?: number | string;
    /**
     * @deprecated Due to adapting to the screen environment, this attribute is obsolete, please use innerWidth
     */
    minWidth?: number | string;
    /**
     * @deprecated Due to adapting to the screen environment, this attribute is obsolete, please use innerWidth
     */
    maximumWidth?: number | string;
}

export interface IWizardFull extends FunctionComponent<WizardFullScreenProps> {
    Header: FunctionComponent;
    Subheader: FunctionComponent;
    Content: FunctionComponent;
    Footer: FunctionComponent;
}

const StyledStack = styled(Stack)`
    flex:1;
    @media (max-width: 768px) {
      .hidden_medium {
          display: none !important;
      }
  
      .expand_medium {
          width: 100%;
      }
      .wizard-right-footer{
        flex-wrap:wrap;
        height:auto
      }
    }
  
    @media (min-width: 768px) {
      .hidden_medium_large {
          display: none !important;
      }
    }
  `;

export const WizardFullScreen: IWizardFull = function (props: WizardFullScreenProps) {
    const HeaderComponent = findByType(props.children, Header);
    const SubheaderComponent = findByType(props.children, Subheader);
    const ContentComponent = findByType(props.children, Content);
    const FooterComponent = findByType(props.children, Footer);

    const [isMenuVisible, { toggle: toggleIsMenuVisible }] = useBoolean(false);
    const theme = useTheme();
    const addChevronDownIcon: IIconProps = { iconName: isMenuVisible ? "ChevronDown" : "ChevronRight" };
    const addChevronDownIconStyle: IButtonStyles = {
        root: {
            height: 30,
        },
        icon: {
            color: theme.palette.neutralPrimary,
        },
    };

    return (
        <Stack
        styles={{root:{height:'100%'}}}
            {...props}
        >
            <Stack
                className="wizard-right-header hidden_medium_large"
                role="heading"
                aria-level={2}
                styles={{
                    root: {
                        padding: "24px 24px",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        borderBottom: `1px solid ${theme.palette.neutralLight}`,
                        fontSize: "24px",
                        fontWeight: "600",
                        lineHeight: "32px",
                    },
                }}
            >{props.heading}</Stack>
            <StyledStack horizontal styles={{ root: { minHeight: "600px" } }}>
                <Stack
                    className="wizard-left hidden_medium"
                    styles={{
                        root: {
                            width: props.menuWidth || "20%",
                            borderRight: `1px solid ${theme.palette.neutralLight}`,
                        },
                    }}
                >
                    {generateLeftMenu(theme, props)}
                </Stack>
                <Stack
                    className="wizard-right expand_medium"
                    styles={{
                        root: {
                            width: props.menuWidth ? `calc(100% - ${props.menuWidth})` : '80%',
                            height: "auto",
                        },
                    }}
                >
                    <Stack
                        style={{
                            flex: 1,
                            overflowY: "auto",
                            wordBreak: "break-word",
                        }}
                    >
                        {Stack && (
                            <Stack
                                className="wizard-right-header hidden_medium"
                                role="heading"
                                aria-level={2}
                                styles={{
                                    root: {
                                        fontSize: "20px",
                                        fontWeight: "600",
                                        lineHeight: "28px",
                                        padding: "16px 0px 12px 24px"
                                    },
                                }}
                            >
                                {HeaderComponent.props.children}
                            </Stack>
                        )}
                        {Stack && (
                            <Stack
                                className="wizard-right-header hidden_medium_large"
                                role="heading"
                                aria-level={2}
                                styles={{
                                    root: {
                                        padding: "16px",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        borderBottom: `1px solid ${theme.palette.neutralLight}`,
                                    },
                                }}
                            >
                                <StackItem
                                    styles={{
                                        root: {
                                            fontSize: "20px",
                                            fontWeight: "600",
                                            paddingLeft: "8px"
                                        },
                                    }}
                                >
                                    {HeaderComponent.props.children}
                                </StackItem>
                                <ActionButton
                                    styles={addChevronDownIconStyle}
                                    iconProps={addChevronDownIcon}
                                    onClick={toggleIsMenuVisible}
                                    ariaLabel="Show steps"
                                />
                            </Stack>
                        )}
                        {isMenuVisible && (
                            <Stack
                                className="hidden_medium_large"
                                styles={{
                                    root: {
                                        position: "absolute",
                                        top: 63,
                                        background: "white",
                                        width: "100%",
                                        zIndex: 1,
                                        boxShadow: "0 3px 5px 0 rgba(0, 0, 0, 0.3)",
                                    },
                                }}
                            >
                                {generateLeftMenu(theme, props)}
                            </Stack>
                        )}
                        {SubheaderComponent && (
                            <Stack
                                className="wizard-right-header"
                                styles={{
                                    root: {
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        padding: "10px 32px 0px 24px"
                                    },
                                }}
                            >
                                {SubheaderComponent.props.children}
                            </Stack>
                        )}
                        {ContentComponent && (
                            <Stack
                                className="wizard-right-content"
                                styles={{
                                    root: {
                                        position: 'relative',
                                        flex: "1",
                                        overflowX: "hidden",
                                        overflowY: "auto",
                                        maxHeight: "597px",
                                        padding: "10px 32px 0px 24px"
                                    },
                                }}
                            >
                                <Stack styles={{ root: { position: 'absolute', insert: '0', width: '100%' } }}>
                                    {ContentComponent.props.children}
                                </Stack>
                            </Stack>
                        )}
                    </Stack>
                    {FooterComponent && (
                        <Stack
                            className="wizard-right-footer"
                            horizontal
                            styles={{
                                root: {
                                    borderTop: `1px solid ${theme.palette.neutralLight}`,
                                    overflow: "hidden",
                                    flexWrap: 'wrap',
                                    height: "64px",
                                    justifyContent: "space-between",
                                    padding: "16px 24px"
                                },
                            }}
                        >
                            {FooterComponent.props.children}
                        </Stack>
                    )}
                </Stack>
            </StyledStack>
        </Stack>
    );
};

WizardFullScreen.Header = Header;
WizardFullScreen.Subheader = Subheader;
WizardFullScreen.Content = Content;
WizardFullScreen.Footer = Footer;

export const WizardFullScreenComp = withLocalization(initializeComponent(WizardFullScreen));