import React, { useState, useEffect } from 'react';
import { Nav, INavStyles, INavLinkGroup, Stack, IStackItemStyles, INavLink } from "@fluentui/react";
import { initializeComponent, withLocalization } from "../../../services/localization";
import styled from 'styled-components';
import { INTL } from '../../../util/intlUtil';
import { LeftNavLocalizationFormatMessages } from '../../../clientResources';
import { PreviewTag } from '../Tag/PreviewTag';

const navStylesExpanded: Partial<INavStyles> = {
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    border: '1px solid #eee',
    borderBottom: 0,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  // these link styles override the default truncation behavior
  link: {
    whiteSpace: 'normal',
    lineHeight: 'inherit',
    paddingLeft: '16px',
    paddingRight: '16px',
    minWidth: '228px'
  },
  linkText: {
    color: '#323130'
  },
  chevronIcon: {
    display: 'none'
  },
  chevronButton: {
    display: 'none'
  }
};

const navStylesCollapsed: Partial<INavStyles> = {
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    border: '1px solid #eee',
    borderBottom: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
    '.left-nav-preview-tag': {
      display: 'none',
    },
  },
  // these link styles override the default truncation behavior
  link: {
    whiteSpace: 'normal',
    lineHeight: 'inherit',
    paddingLeft: '11px',
    paddingRight: '11px'
  },
  linkText: {
    color: '#323130'
  },
  chevronIcon: {
    display: 'none'
  },
  chevronButton: {
    display: 'none'
  }
};

const stackItemStyles: IStackItemStyles = {
  root: {
    alignItems: 'start',
    display: 'flex',
    height: '100%'
  }
};

const LeftNavStyles = styled.div`
  height: 100%;
  margin-left: 0;
  &>div{
    height: inherit;
  }
  @media all and (max-width: 992px) {
    .hideOnMediumOrSmallerDevice {
      display: none;
    }
  }
  @media all and (min-width: 992px) {
    .showOnMediumOrLargerDevice {
      display: none;
    }
  }
  .left-nav-preview-tag {
    margin-top: 2px;
    margin-left: 7px;
  }
  .ms-Nav-compositeLink#collapseChevron {
    span {
        justify-content: flex-end;
    }
    button {
      background-color: transparent;
    }
    &:focus {
        outline: none;
    }
    i {
        color: #0078D4;
        font-size: 8px;
        display: flex;
        height: 32px;
        width: 32px;
        align-items: center;
        justify-content: center;
        &:hover {
            background-color: #EDEBE9;
        }
    }
  }

  #tryitout, #management {
      pointer-events: none;
  }

  .ms-Nav-link {
      &:after {
          border-left: 0;
      }
  }

  .ms-Nav-compositeLink#expandChevron {
      button {
          padding-right: 0;
          padding-left: 0;
          span {
              justify-content: center;
              align-items: center;
          }
          &:focus {
              outline: none;
          }
      }
      background-color: transparent;
      &:focus {
          outline: 0;
      }
      i {
          color: #0078D4;
          font-size: 8px;
          height: 32px;
          width: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
              background-color: #EDEBE9;
          }
      }
  }

  .ms-Nav-link {
      border-radius: 0;
      i {
          margin-left: 0;
      }
  }

  .ms-Nav-link[title="Expand/Collapsed"] {
      padding-right: 0;
      position: relative;
      right: -5px;
      outline: none;
  }

  .ms-Nav-compositeLink {
      button:disabled {
          color:rgb(50, 49, 48);
      }
  }

  .ms-Nav-groupContent {
      margin-bottom: 0px;
  }

  .navigation-header {
      height: 44px;
      display: flex;
      align-items: center;
      p {
          font-size: 14px;
          font-weight: 600;
          padding-left: 16px;
          margin-bottom: 0px;
      }
  }

  .childrenFullHeight>div { 
    height: 100%;
  }
`

const _onRenderGroupHeader = (group: INavLinkGroup): JSX.Element => {
  return (
    <div className='navigation-header'>
      <p>{group.name}</p>
    </div>
  )
}


interface INavLinkProps extends INavLink {
  isPreview?: boolean;
}
export interface INavGroupProps extends INavLinkGroup {
  links: INavLinkProps[];
}

const Navi = (props: {
  subscriptionId: string;
  expandedNaviItems: INavGroupProps[];
  collapsedNaviItems: INavGroupProps[];
  expandedStyle: Partial<INavStyles>;
  collapsedStyle: Partial<INavStyles>;
  navigation: (navigate: { pathname: string, search: string }) => void;
  getCurrentUrl: () => string;
  getPathname?: (url: string) => string;
  search?: string;
}) => {
  const [naviItems, setNaviItems] = useState(props.expandedNaviItems);
  const [naviStyles, setNaviStyles] = useState(props.expandedStyle);
  const [currentUrl, setCurrentUrl] = useState("");

  useEffect(() => {
    setCurrentUrl(props.getCurrentUrl());
  }, []);

  useEffect(() => {
    setNaviItems(props.expandedNaviItems);
  }, [props.expandedNaviItems]);

  useEffect(() => {
    setNaviStyles(props.expandedStyle);
  }, [props.expandedStyle]);

  return (
    <Stack.Item
      grow={0}
      styles={stackItemStyles}
      className="childrenFullHeight"
    >
      <Nav
        styles={naviStyles}
        groups={naviItems}
        selectedKey={currentUrl}
        onRenderLink={(link) => {
          if (link.isExpanded) {
            return <b>{link.name}</b>;
          } else {
            return <><span>{link.name}</span>{link.isPreview && <PreviewTag className="left-nav-preview-tag" />}</>;
          }
        }}
        onLinkClick={(ev, item) => {
          ev.stopPropagation();
          ev.preventDefault();

          if (item.key !== "collapse") {
            setCurrentUrl(item.url);

            props.navigation({
              pathname: props.getPathname(item.url),
              search: props.search
            });
          }

          if (item.links && item.links.length) {
            ev.stopPropagation();
            ev.preventDefault();
            return false;
          }
          if (item.key == "collapse") {
            if (naviItems == props.expandedNaviItems) {
              setNaviItems(props.collapsedNaviItems);
              setNaviStyles(props.collapsedStyle);
            } else {
              setNaviItems(props.expandedNaviItems);
              setNaviStyles(props.expandedStyle);
            }
          }
        }}
        onLinkExpandClick={(evt) => {
          evt.stopPropagation();
        }}
        onRenderGroupHeader={_onRenderGroupHeader}
        ariaLabel="Main"
      />
    </Stack.Item>
  );
};

const Navigation = (props: NavigationProps) => {
  const { subscriptionId, navigation, getCurrentUrl, expandedNaviItems, collapsedNaviItems, getPathname, search } = props;

  return (
    <LeftNavStyles>
      <div className="hideOnMediumOrSmallerDevice">
        <Navi
          subscriptionId={subscriptionId}
          expandedNaviItems={expandedNaviItems}
          collapsedNaviItems={collapsedNaviItems}
          expandedStyle={navStylesExpanded}
          collapsedStyle={navStylesCollapsed}
          navigation={navigation}
          getCurrentUrl={getCurrentUrl}
          getPathname={getPathname}
          search={search}
        />
      </div>
      <div className="showOnMediumOrLargerDevice">
        <Navi
          subscriptionId={subscriptionId}
          expandedNaviItems={collapsedNaviItems}
          collapsedNaviItems={expandedNaviItems}
          expandedStyle={navStylesCollapsed}
          collapsedStyle={navStylesExpanded}
          navigation={navigation}
          getCurrentUrl={getCurrentUrl}
          getPathname={getPathname}
          search={search}
        />
      </div>
    </LeftNavStyles>
  );
}

export interface NavigationProps {
  subscriptionId?: string,
  expandedNaviItems: INavGroupProps[];
  collapsedNaviItems: INavGroupProps[];
  navigation: (navigate: { pathname: string, search: string }) => void;
  getCurrentUrl: () => string;
  getPathname?: (url: string) => string;
  search?: string;
}

export const LeftNav = withLocalization(initializeComponent(Navigation))