import React from 'react';
import { Stack, Text, Icon, IStackTokens, IStyle } from "@fluentui/react";
import { PlatformsMessages } from "../../../clientResources";
import { INTL } from "../../../util/intlUtil";

export interface ISupportedPlatforms {
    supportsIoT?: boolean;
    supportsDocker?: boolean;
    supportsCloud?: boolean;
}
const customSpacingStackTokens: IStackTokens = {
    childrenGap: 5
};

const Platforms: React.FC<ISupportedPlatforms> = (props) => {
    const { supportsIoT = true, supportsDocker = true, supportsCloud = true } = props;
    const rootStyles: IStyle = {"[hidden]": {display: "none !important"}};
    
    return (
        <Stack styles={{ root: rootStyles }}>
            <Text style={{ fontWeight: 600, fontSize: "14px" }}>{INTL.formatMessage(PlatformsMessages.Platforms)}</Text>
            <Stack horizontal tokens={{ childrenGap: 20 }} wrap>
                <Stack horizontal tokens={customSpacingStackTokens} verticalAlign={"center"} hidden={!supportsIoT}>
                    <Icon iconName="Iot-svg" style={{display: 'flex', alignSelf: "center"}} />
                    <Text>{INTL.formatMessage(PlatformsMessages.IOT)}</Text>
                </Stack>
                <Stack horizontal tokens={customSpacingStackTokens} verticalAlign={"center"} hidden={!supportsDocker}>
                    <Icon iconName='docker-svg' style={{display: 'flex', alignSelf: "center", marginTop: "5px"}}/>
                    <Text>{INTL.formatMessage(PlatformsMessages.Docker)}</Text>
                </Stack>
                <Stack horizontal tokens={customSpacingStackTokens} verticalAlign={"center"} hidden={!supportsCloud}>
                    <Icon iconName="Cloud" style={{fontSize: "14px"}}/>
                    <Text>{INTL.formatMessage(PlatformsMessages.Cloud)}</Text>
                </Stack>
            </Stack>
        </Stack>
    );
}

export default Platforms;