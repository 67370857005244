import { FontWeights, makeStyles } from '@fluentui/react';
import { DefaultPalette } from '@fluentui/react/lib/Theme';

const useHeroStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '550px',
    backgroundSize: 'cover',
    objectFit: 'cover',
    /* Once the window is resized, blur the hero image so that the text is still accessible. */
    '& img': {
      '@media (max-width: 1300px)': {
        filter: 'blur(8px)',
        opacity: '0.8',
      },
    },
  },
  content: {
    position: 'absolute',
    maxWidth: '500px',
    paddingBottom: '15px',
    left: 70,
    paddingTop: 70,
  },
  title: {
    fontSize: '60px',
    fontWeight: FontWeights.semilight,
    paddingBottom: '15px',
  },
  subTitle: {
    fontSize: '24px',
    color: DefaultPalette.black,
    fontWeight: FontWeights.semilight,
  },
  button: {
    height: '52px',
    fontSize: '18px',
    marginRight: '15px',
    '&.signIn': {
      background: theme.palette.black,
    },
  },
}));

export default useHeroStyles;
