import axios, { AxiosResponse } from 'axios';
import i18next from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import { batch } from 'react-redux';
import { Action, Dispatch } from 'redux';
import { v4 as uuidv4 } from 'uuid';

import { IRenameModelFormValues } from '../../components/Forms/RenameModelForm/RenameModelForm.types';
import {
  IModelRegionStatusFormItem,
  ISwapModel,
  IUpdatePublishFormValues,
} from '../../components/Forms/UpdatePublishForm/UpdatePublishForm.types';
import { ADD_COPY_NOTIFICATION, CopyNotificationsDispatchTypes } from '../copyNotifications/copyNotifications.types';
import {
  ADD_DEPLOYMENT_NOTIFICATION,
  DeploymentNotificationsDispatchTypes,
  IAddDeploymentNotificationPayload,
  IDeploymentRegionItem,
} from '../deploymentNotifications/deploymentNotifications.types';
import { IDocumentItem, IProcessedDocumentItem } from '../documents/documents.types';
import { CLEAR_SELECTED_MODEL, EntitySelectionDispatchTypes } from '../entitySelections/entitySelections.types';
import { ErrorDispatchTypes, SET_ERROR } from '../error/error.types';
import { FileDispatchTypes, SET_FILES } from '../files/files.types';
import { LanguagesDispatchTypes, SET_LANGUAGES } from '../languages/languages.types';
import {
  CLEAR_MODEL_DOCUMENTS,
  ModelDocumentsDispatchTypes,
  SET_MODEL_DOCUMENTS,
} from '../modelDocuments/modelDocuments.types';
import normalizeResponse from '../normalizeResponse';
import {
  ADD_NOTIFICATION,
  NotificationsDispatchTypes,
  REPLACE_NOTIFICATION_ID,
} from '../notifications/notifications.types';
import { PanelsDispatchTypes, SET_ACTIVE_PANEL } from '../panels/panels.types';
import { PopUpErrorsDispatchTypes, SET_POP_UP_ERROR } from '../popUpErrors/popUpErrors.types';
import processPopUpErrorPayload from '../popUpErrors/processPopUpErrorPayload';
import { modelSchema, modelsListSchema } from '../schema';
import { TelemetryDispatchTypes } from '../telemetry/telemetry.types';
import { CLEAR_DOCUMENTS, CLEAR_MODEL_NAME, TrainModelDispatchTypes } from '../trainModel/trainModel.types';
import {
  ADD_TRAINING_NOTIFICATION,
  TrainingNotificationsDispatchTypes,
  UPDATE_MODELID_TRAINING_NOTIFICATION,
} from '../trainingNotifications/trainingNotifications.types';
import { SET_USERS, UsersDispatchTypes } from '../users/users.types';
import {
  CLEAR_MODELS,
  CREATE_MODEL,
  CREATE_MODEL_FAILED,
  CREATE_MODEL_SUCCESSFUL,
  DELETE_MODEL,
  DELETE_MODEL_FAILED,
  DELETE_MODEL_SUCCESSFUL,
  DUPLICATE_MODEL,
  DUPLICATE_MODEL_FAILED,
  DUPLICATE_MODEL_SUCCESSFUL,
  GET_MODEL,
  GET_MODELS,
  GET_MODELS_FAILED,
  GET_MODELS_SUCCESSFUL,
  GET_MODEL_FAILED,
  GET_MODEL_SUCCESSFUL,
  ICreateModelData,
  IModelEntities,
  IModelsEntities,
  IUpdateModelPublishStatusPayload,
  IUpdateModelStatusPayload,
  MODEL_COPY,
  MODEL_COPY_FAILED,
  MODEL_COPY_SUCCESSFUL,
  ModelsDispatchTypes,
  RENAME_MODEL,
  RENAME_MODEL_FAILED,
  RENAME_MODEL_SUCCESSFUL,
  TRAIN_MODEL,
  TRAIN_MODEL_FAILED,
  TRAIN_MODEL_SUCCESSFUL,
  UPDATE_DRAFT_MODEL,
  UPDATE_DRAFT_MODEL_FAILED,
  UPDATE_DRAFT_MODEL_SUCCESSFUL,
  UPDATE_MODEL_PUBLISH_STATUS,
  UPDATE_MODEL_PUBLISH_STATUS_FAILED,
  UPDATE_MODEL_PUBLISH_STATUS_SUCCESSFUL,
  UPDATE_MODEL_TRAINING_STATUS,
} from './models.types';

import addToSignalRGroup from '../../utils/addToSignalRGroup';
import { AppBarPanels } from '../../utils/constants/appBarPanels';
import ErrorCategories from '../../utils/constants/errorCategories';
import { DeploymentNotificationStatus, NotificationType } from '../../utils/constants/notifications';
import { PopUpEntityTypes, RequestTypes } from '../../utils/constants/popUpErrors';
import PublishOperation from '../../utils/constants/publishOperation';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const processModelResponse = (response: AxiosResponse) => {
  const clonedModelResponse = cloneDeep(response.data);

  /* 
    In the GET /model/{id} API response there is documents[{documentInfo: {}, processedDocumentInfo: {}]. 
    One document may be used across multiple models. For that document, all the data in documentInfo will remain constant across models. 
    The data in processedDocumentInfo (alignedSentences, usedSentences) will vary from model to model. 
    Because of this distinction and because the data is fetched via a call to GET /model/{id}, 
    we store the data about modelDocuments in a flattened modelDocuments facet instead of in the documents facet. 
    Once the documents are flattened and normalized Store.model[modelId].documents will store the model document IDs used within a model.
  */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const combinedModelDocuments: any = [];

  response.data.documents.map(
    (document: { documentInfo: IDocumentItem; processedDocumentInfo: IProcessedDocumentItem | null }): null => {
      // Processed document has an unrelated field "id", referring to the id of the processed document. We
      // want to strip this ID before completing more processing
      if (document.processedDocumentInfo) {
        const { id, ...processedDocumentWithoutId } = document.processedDocumentInfo;
        const combineModelDocument = { ...document.documentInfo, ...processedDocumentWithoutId };

        combinedModelDocuments.push(combineModelDocument);
      } else {
        combinedModelDocuments.push(document.documentInfo);
      }
      return null;
    }
  );

  clonedModelResponse.documents = combinedModelDocuments;
  return clonedModelResponse;
};

export const getModels = (projectId: string) => async (
  dispatch: Dispatch<ModelsDispatchTypes | UsersDispatchTypes | ErrorDispatchTypes>
): Promise<void | Action> => {
  try {
    dispatch({ type: GET_MODELS });

    const response = await axios.get(`${process.env.REACT_APP_API_URL}projects/${projectId}/models`);

    const { entities, result } = await normalizeResponse<IModelsEntities>(response.data.models, modelsListSchema);
    return batch(() => {
      dispatch({
        type: SET_USERS,
        payload: entities.users,
      });
      dispatch({
        type: GET_MODELS_SUCCESSFUL,
        payload: {
          ids: result,
          data: entities.models,
        },
      });
    });
  } catch (error: any) {
    return batch(() => {
      dispatch({
        type: GET_MODELS_FAILED,
      });
      dispatch({
        type: SET_ERROR,
        payload: {
          message: error.message,
          statusCode: error.response?.status,
          category: ErrorCategories.Models,
        },
      });
    });
  }
};

export const getModel = (modelId: string, silent = false) => async (
  dispatch: Dispatch<
    ModelsDispatchTypes | FileDispatchTypes | LanguagesDispatchTypes | ModelDocumentsDispatchTypes | ErrorDispatchTypes
  >
): Promise<void | Action | null> => {
  try {
    if (!silent) {
      batch(() => {
        dispatch({ type: GET_MODEL });
        dispatch({ type: CLEAR_MODEL_DOCUMENTS });
      });
    } else {
      dispatch({ type: CLEAR_MODEL_DOCUMENTS });
    }

    const response = await axios.get(`${process.env.REACT_APP_API_URL}models/${modelId}`);

    // Using deep clone as response.data is a nested object and spread operator will only shallow clone
    const clonedModelResponse = processModelResponse(response);

    const { entities } = await normalizeResponse<IModelEntities>(clonedModelResponse, modelSchema);
    const numberResult = parseInt(Object.keys(entities.models)[0], 10);

    return batch(() => {
      dispatch({
        type: SET_FILES,
        payload: entities.files,
      });
      dispatch({
        type: SET_LANGUAGES,
        payload: entities.languages,
      });
      dispatch({
        type: SET_MODEL_DOCUMENTS,
        payload: entities.documents,
      });
      dispatch({
        type: GET_MODEL_SUCCESSFUL,
        payload: {
          ids: [numberResult],
          data: entities.models,
        },
      });
    });
  } catch (error: any) {
    if (!silent) {
      return batch(() => {
        dispatch({
          type: GET_MODEL_FAILED,
        });
        dispatch({
          type: SET_ERROR,
          payload: {
            message: error.message,
            statusCode: error.response?.status,
            category: ErrorCategories.Model,
          },
        });
      });
    }
    return null;
  }
};

export const renameModel = (modelFormValues: IRenameModelFormValues, selectedModel: string) => async (
  dispatch: Dispatch<
    | ModelsDispatchTypes
    | EntitySelectionDispatchTypes
    | PopUpErrorsDispatchTypes
    | FileDispatchTypes
    | LanguagesDispatchTypes
    | ModelDocumentsDispatchTypes
    | TelemetryDispatchTypes
  >
): Promise<void | Action> => {
  try {
    dispatch({ type: RENAME_MODEL });

    const { modelName } = modelFormValues;

    const response = await axios.put(`${process.env.REACT_APP_API_URL}models/${selectedModel}`, {
      name: modelName,
    });
    return batch(() => {
      dispatch({
        type: CLEAR_SELECTED_MODEL,
      });
      dispatch({
        type: RENAME_MODEL_SUCCESSFUL,
        payload: {
          id: selectedModel,
          data: response.data,
        },
      });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'model-rename/success' });
    });
  } catch (err: any) {
    const responseStatusCode: number = err.response.status || 0;
    const { display, message }: { display: boolean; message: string } = err.response.data;

    const popUpErrorPayload = processPopUpErrorPayload({
      title: `${i18next.t('components.popUpErrors.models.rename.title')}`,
      errorMessage: display ? message : '',
      statusCode: responseStatusCode,
      entityType: PopUpEntityTypes.Models,
      requestType: RequestTypes.Put,
    });

    return batch(() => {
      dispatch({ type: RENAME_MODEL_FAILED });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'model-rename/failure' });
      dispatch({
        type: SET_POP_UP_ERROR,
        payload: {
          ...popUpErrorPayload,
        },
      });
    });
  }
};

export const updateModelStatus = (payload: IUpdateModelStatusPayload) => async (
  dispatch: Dispatch<ModelsDispatchTypes>
): Promise<void | Action> => {
  return dispatch({ type: UPDATE_MODEL_TRAINING_STATUS, payload });
};

const getPublishErrorTitle = (type: PublishOperation): string => {
  switch (type) {
    case PublishOperation.Unpublish:
      return `${i18next.t('components.popUpErrors.models.unpublish')}`;
    case PublishOperation.Swap:
      return `${i18next.t('components.popUpErrors.models.swap')}`;
    case PublishOperation.Update:
      return `${i18next.t('components.popUpErrors.models.update')}`;
    default:
      return `${i18next.t('components.popUpErrors.models.publish')}`;
  }
};

const getDeploymentNotificationPayload = (
  modelId: string,
  modelRegionStatuses: IModelRegionStatusFormItem[],
  modelName: string,
  publishOperation: PublishOperation,
  swapModelName?: string
): IAddDeploymentNotificationPayload => {
  const regions: IDeploymentRegionItem[] = [];
  modelRegionStatuses.map((region) => {
    if (region.isChanged) {
      const operation = region.isDeployed ? PublishOperation.Publish : PublishOperation.Unpublish;

      // We deploy to new regions first before undeploying old regions, which means that any regions that are
      // being undeployed to in an update operation need to show status "New" instead of "In Progress".
      const isRegionInQueue = publishOperation === PublishOperation.Update && !region.isDeployed;
      const status = isRegionInQueue ? DeploymentNotificationStatus.New : DeploymentNotificationStatus.InProgress;

      const newRegion = {
        region: region.region,
        status,
        operation,
      };
      // Queued regions should be added to the back of the array, otherwise add new regions to front of array
      if (isRegionInQueue) {
        regions.push(newRegion);
      } else {
        regions.unshift(newRegion);
      }
    }
    return null;
  });
  return {
    id: modelId,
    data: {
      name: modelName,
      notification: {
        operation: publishOperation,
        regions,
        swapModelName: swapModelName || '',
      },
    },
  };
};

export const updateModelPublishStatus = (
  updateModelPublishStatusForm: IUpdatePublishFormValues,
  publishOperation: PublishOperation,
  swapModel?: ISwapModel
) => async (
  dispatch: Dispatch<
    | ModelsDispatchTypes
    | PopUpErrorsDispatchTypes
    | DeploymentNotificationsDispatchTypes
    | NotificationsDispatchTypes
    | PanelsDispatchTypes
    | TelemetryDispatchTypes
  >
): Promise<void | Action> => {
  try {
    dispatch({ type: UPDATE_MODEL_PUBLISH_STATUS });

    const modelId = updateModelPublishStatusForm.modelId.toString();
    const { modelRegionStatuses, modelName, projectId } = updateModelPublishStatusForm;

    // Send deployment request to API
    await axios.post(
      `${process.env.REACT_APP_API_URL}models/${modelId}/deployment`,
      updateModelPublishStatusForm.modelRegionStatuses
    );

    // Subscribe to notifications for relevant models
    addToSignalRGroup(`${projectId}_${modelId}`);
    if (swapModel?.modelId) {
      addToSignalRGroup(`${projectId}_${swapModel.modelId}`);
    }

    const payload: IUpdateModelPublishStatusPayload = {
      id: modelId,
      data: {
        modelRegionStatuses,
        modelStatus: updateModelPublishStatusForm.nextModelStatus,
      },
    };

    const notificationPayload = getDeploymentNotificationPayload(
      modelId,
      modelRegionStatuses,
      modelName,
      publishOperation,
      swapModel?.modelName
    );

    return batch(() => {
      dispatch({ type: UPDATE_MODEL_PUBLISH_STATUS_SUCCESSFUL, payload });
      dispatch({
        type: ADD_DEPLOYMENT_NOTIFICATION,
        payload: notificationPayload,
      });
      dispatch({
        type: ADD_NOTIFICATION,
        payload: {
          id: modelId,
          type: NotificationType.Deployment,
        },
      });
      dispatch({
        type: SET_ACTIVE_PANEL,
        payload: AppBarPanels.Notification,
      });
    });
  } catch (err: any) {
    const responseStatusCode: number = err.response?.status || 0;
    const { display, message }: { display: boolean; message: string } = err.response.data;

    const popUpErrorPayload = processPopUpErrorPayload({
      title: getPublishErrorTitle(publishOperation),
      errorMessage: display ? message : '',
      statusCode: responseStatusCode,
      entityType: PopUpEntityTypes.Models,
      requestType: RequestTypes.Put,
    });

    return batch(() => {
      dispatch({ type: UPDATE_MODEL_PUBLISH_STATUS_FAILED });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'model-update-publish-status/failure' });
      dispatch({
        type: SET_POP_UP_ERROR,
        payload: {
          ...popUpErrorPayload,
        },
      });
    });
  }
};

export const deleteModel = (selectedModel: string) => async (
  dispatch: Dispatch<ModelsDispatchTypes | PopUpErrorsDispatchTypes | TelemetryDispatchTypes>
): Promise<void | Action> => {
  try {
    dispatch({ type: DELETE_MODEL });

    await axios.delete(`${process.env.REACT_APP_API_URL}models/${selectedModel}`);

    return batch(() => {
      dispatch({ type: DELETE_MODEL_SUCCESSFUL, payload: selectedModel });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'model-delete/success' });
    });
  } catch (err: any) {
    const responseStatusCode: number = err.response?.status || 0;
    const { display, message }: { display: boolean; message: string } = err.response.data;

    const popUpErrorPayload = processPopUpErrorPayload({
      title: `${i18next.t('components.popUpErrors.models.delete.title')}`,
      errorMessage: display ? message : '',
      statusCode: responseStatusCode,
      entityType: PopUpEntityTypes.Models,
      requestType: RequestTypes.Delete,
    });

    return batch(() => {
      dispatch({ type: DELETE_MODEL_FAILED });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'model-delete/failure' });
      dispatch({
        type: SET_POP_UP_ERROR,
        payload: {
          ...popUpErrorPayload,
        },
      });
    });
  }
};

export const clearModels = () => async (dispatch: Dispatch<ModelsDispatchTypes>): Promise<void | Action> => {
  return dispatch({ type: CLEAR_MODELS });
};

export const createModel = (createModelData: ICreateModelData, isDuplicating = false) => async (
  dispatch: Dispatch<
    | ModelsDispatchTypes
    | PopUpErrorsDispatchTypes
    | TrainModelDispatchTypes
    | FileDispatchTypes
    | LanguagesDispatchTypes
    | ModelDocumentsDispatchTypes
    | NotificationsDispatchTypes
    | TrainingNotificationsDispatchTypes
    | PanelsDispatchTypes
    | TelemetryDispatchTypes
  >
): Promise<void | Action> => {
  const modelDispatch = isDuplicating ? DUPLICATE_MODEL : CREATE_MODEL;
  const modelDispatchSuccessful = isDuplicating ? DUPLICATE_MODEL_SUCCESSFUL : CREATE_MODEL_SUCCESSFUL;
  const modelDispatchFailed = isDuplicating ? DUPLICATE_MODEL_FAILED : CREATE_MODEL_FAILED;

  let successTelemetryMessage = '';
  let failureTelemetryMessage = '';

  if (createModelData.isAutoTrain && isDuplicating) {
    successTelemetryMessage = 'model-duplicate-train/success';
    failureTelemetryMessage = 'model-duplicate-train/failure';
  } else if (createModelData.isAutoTrain) {
    successTelemetryMessage = 'model-create-train/success';
    failureTelemetryMessage = 'model-create-train/failure';
  } else if (isDuplicating) {
    successTelemetryMessage = 'model-duplicate-draft/success';
    failureTelemetryMessage = 'model-duplicate-draft/failure';
  } else {
    successTelemetryMessage = 'model-create-draft/success';
    failureTelemetryMessage = 'model-create-draft/failure';
  }

  try {
    const temporaryId = uuidv4();
    const temporaryTrainigNotificationPayload = {
      id: temporaryId,
      data: {
        name: createModelData.name,
      },
    };

    batch(() => {
      dispatch({ type: modelDispatch });
      dispatch({ type: ADD_TRAINING_NOTIFICATION, payload: temporaryTrainigNotificationPayload });
      dispatch({ type: ADD_NOTIFICATION, payload: { id: temporaryId, type: NotificationType.Training } });
      dispatch({ type: SET_ACTIVE_PANEL, payload: AppBarPanels.Notification });
    });

    const {
      name,
      projectId,
      documentIds,
      isTuningAuto,
      isTestingAuto,
      isAutoDeploy,
      isAutoTrain,
      autoDeployThreshold,
    } = createModelData;
    const response = await axios.post(`${process.env.REACT_APP_API_URL}models`, {
      name,
      projectId,
      documentIds,
      isTuningAuto,
      isTestingAuto,
      isAutoDeploy,
      isAutoTrain,
      autoDeployThreshold,
    });

    const clonedModelResponse = processModelResponse(response);
    const { entities } = await normalizeResponse<IModelEntities>(clonedModelResponse, modelSchema);

    const modelId = Object.keys(entities.models)[0];

    const updateNotificationPayload = {
      id: modelId,
      data: {
        oldId: temporaryId,
      },
    };

    batch(() => {
      dispatch({
        type: UPDATE_MODELID_TRAINING_NOTIFICATION,
        payload: updateNotificationPayload,
      });
      dispatch({
        type: REPLACE_NOTIFICATION_ID,
        payload: updateNotificationPayload,
      });
    });

    // Add to SignalR group
    if (isAutoTrain) {
      addToSignalRGroup(`${projectId}_${modelId}`);
      batch(() => {
        dispatch({
          type: ADD_TRAINING_NOTIFICATION,
          payload: {
            id: modelId,
            data: { name },
          },
        });
        dispatch({
          type: ADD_NOTIFICATION,
          payload: {
            id: modelId,
            type: NotificationType.Training,
          },
        });
        dispatch({
          type: SET_ACTIVE_PANEL,
          payload: AppBarPanels.Notification,
        });
      });
    }

    return batch(() => {
      dispatch({
        type: SET_FILES,
        payload: entities.files,
      });
      dispatch({
        type: SET_LANGUAGES,
        payload: entities.languages,
      });
      dispatch({
        type: CLEAR_MODEL_NAME,
      });
      dispatch({
        type: modelDispatchSuccessful,
        payload: {
          ids: [modelId],
          data: entities.models,
        },
      });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: successTelemetryMessage });
    });
  } catch (err: any) {
    const responseStatusCode: number = err.response?.status || 0;
    const { display, message }: { display: boolean; message: string } = err.response.data;

    const popUpErrorPayload = processPopUpErrorPayload({
      title: `${i18next.t('components.popUpErrors.models.create')}`,
      errorMessage: display ? message : '',
      statusCode: responseStatusCode,
      entityType: PopUpEntityTypes.Models,
      requestType: RequestTypes.Create,
    });

    return batch(() => {
      dispatch({ type: modelDispatchFailed });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: failureTelemetryMessage });
      dispatch({
        type: SET_POP_UP_ERROR,
        payload: {
          ...popUpErrorPayload,
        },
      });
    });
  }
};

export const updateDraftModel = (
  modelId: string,
  modelName: string,
  addedDocuments: number[] | string[],
  deletedDocuments: number[] | string[]
) => async (
  dispatch: Dispatch<
    | ModelsDispatchTypes
    | EntitySelectionDispatchTypes
    | PopUpErrorsDispatchTypes
    | FileDispatchTypes
    | LanguagesDispatchTypes
    | ModelDocumentsDispatchTypes
    | TrainModelDispatchTypes
    | TelemetryDispatchTypes
  >
): Promise<void | Action> => {
  try {
    dispatch({ type: UPDATE_DRAFT_MODEL });

    const response = await axios.put(`${process.env.REACT_APP_API_URL}models/${modelId}`, {
      name: modelName,
      addDocuments: addedDocuments,
      removeDocuments: deletedDocuments,
    });
    return batch(() => {
      dispatch({
        type: CLEAR_DOCUMENTS,
      });
      dispatch({
        type: UPDATE_DRAFT_MODEL_SUCCESSFUL,
        payload: {
          id: modelId,
          data: response.data,
        },
      });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'model-update-draft/success' });
    });
  } catch (err: any) {
    const responseStatusCode: number = err.response.status || 0;
    const { display, message }: { display: boolean; message: string } = err.response.data;

    const popUpErrorPayload = processPopUpErrorPayload({
      title: `${i18next.t('components.popUpErrors.models.updateDraft.title')}`,
      errorMessage: display ? message : '',
      statusCode: responseStatusCode,
      entityType: PopUpEntityTypes.Models,
      requestType: RequestTypes.Put,
    });

    return batch(() => {
      dispatch({ type: UPDATE_DRAFT_MODEL_FAILED });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'model-update-draft/failure' });
      dispatch({
        type: SET_POP_UP_ERROR,
        payload: {
          ...popUpErrorPayload,
        },
      });
    });
  }
};

export const trainDraftModel = (
  modelId: string,
  projectId: string,
  modelName: string,
  hasUnsavedchanges: boolean,
  addedDocuments?: number[],
  deletedDocuments?: number[]
) => async (
  dispatch: Dispatch<
    | ModelsDispatchTypes
    | PopUpErrorsDispatchTypes
    | TrainModelDispatchTypes
    | FileDispatchTypes
    | LanguagesDispatchTypes
    | ModelDocumentsDispatchTypes
    | NotificationsDispatchTypes
    | TrainingNotificationsDispatchTypes
    | PanelsDispatchTypes
    | TelemetryDispatchTypes
  >
): Promise<void | Action> => {
  try {
    dispatch({ type: TRAIN_MODEL });
    // First update the model if there is any unsaved change
    if (hasUnsavedchanges) {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}models/${modelId}`, {
        name: modelName,
        addDocuments: addedDocuments,
        removeDocuments: deletedDocuments,
      });
      batch(() => {
        dispatch({
          type: CLEAR_DOCUMENTS,
        });
        dispatch({
          type: UPDATE_DRAFT_MODEL_SUCCESSFUL,
          payload: {
            id: modelId,
            data: response.data,
          },
        });
      });
    }

    await axios.post(`${process.env.REACT_APP_API_URL}models/${modelId}/train`, {
      id: modelId,
    });

    addToSignalRGroup(`${projectId}_${modelId}`);

    return batch(() => {
      dispatch({
        type: TRAIN_MODEL_SUCCESSFUL,
        payload: modelId,
      });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'model-train-draft/success' });
      dispatch({
        type: ADD_TRAINING_NOTIFICATION,
        payload: {
          id: modelId,
          data: { name: modelName },
        },
      });
      dispatch({
        type: ADD_NOTIFICATION,
        payload: {
          id: modelId,
          type: NotificationType.Training,
        },
      });
      dispatch({
        type: SET_ACTIVE_PANEL,
        payload: AppBarPanels.Notification,
      });
    });
  } catch (err: any) {
    const responseStatusCode: number = err.response?.status || 0;
    const { display, message }: { display: boolean; message: string } = err.response.data;

    const popUpErrorPayload = processPopUpErrorPayload({
      title: `${i18next.t('components.popUpErrors.models.create')}`,
      errorMessage: display ? message : '',
      statusCode: responseStatusCode,
      entityType: PopUpEntityTypes.Models,
      requestType: RequestTypes.Create,
    });

    return batch(() => {
      dispatch({ type: TRAIN_MODEL_FAILED });
      dispatch({ type: 'ADD_TO_TELEMETRY_QUEUE', payload: 'model-train-draft/failure' });
      dispatch({
        type: SET_POP_UP_ERROR,
        payload: {
          ...popUpErrorPayload,
        },
      });
    });
  }
};

export const copyModel = (
  modelName: string,
  modelId: string | number,
  projectId: string | number,
  targetProjectName: string,
  targetWorkspaceName: string
) => async (
  dispatch: Dispatch<
    | ModelsDispatchTypes
    | PopUpErrorsDispatchTypes
    | TrainModelDispatchTypes
    | FileDispatchTypes
    | LanguagesDispatchTypes
    | ModelDocumentsDispatchTypes
    | NotificationsDispatchTypes
    | TrainingNotificationsDispatchTypes
    | PanelsDispatchTypes
    | TelemetryDispatchTypes
    | CopyNotificationsDispatchTypes
  >
): Promise<void | Action> => {
  dispatch({ type: MODEL_COPY });

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}models/${modelId}/copy`, {
      name: modelName,
      targetProjectId: projectId,
    });

    const clonedModelResponse = processModelResponse(response);
    const { entities } = await normalizeResponse<IModelEntities>(clonedModelResponse, modelSchema);

    const newModelId = Object.keys(entities.models)[0];
    return batch(() => {
      addToSignalRGroup(`${projectId}_${newModelId}`);
      dispatch({
        type: MODEL_COPY_SUCCESSFUL,
        payload: {
          id: newModelId,
          data: response.data,
        },
      });
      dispatch({
        type: ADD_COPY_NOTIFICATION,
        payload: {
          id: newModelId,
          data: {
            modelName,
            targetProjectName,
            targetWorkspaceName,
          },
        },
      });
      dispatch({
        type: ADD_NOTIFICATION,
        payload: {
          id: newModelId,
          type: NotificationType.Copying,
        },
      });
      dispatch({ type: SET_ACTIVE_PANEL, payload: AppBarPanels.Notification });
    });
  } catch (err: any) {
    const responseStatusCode: number = err.response?.status || 0;
    const { display, message }: { display: boolean; message: string } = err.response.data;

    const popUpErrorPayload = processPopUpErrorPayload({
      title: `${i18next.t('components.popUpErrors.models.create')}`,
      errorMessage: display ? message : '',
      statusCode: responseStatusCode,
      entityType: PopUpEntityTypes.Models,
      requestType: RequestTypes.Create,
    });

    return batch(() => {
      dispatch({ type: MODEL_COPY_FAILED });
      dispatch({
        type: SET_POP_UP_ERROR,
        payload: {
          ...popUpErrorPayload,
        },
      });
    });
  }
};
