import axios from 'axios';

/**
 * Adds the user to the SignalR group for this model. Group names are given by projectId_modelId.
 * Errors during this process result in connection issue displaying in the notification panel.
 * Adding a user to a SignalR group requires a unique identifier for the user. In production, the
 * unique identifier is pulled from headers that are added by the Function app. For local development purposes,
 * we send the unique id that MSAL provides.
 */
const addToSignalRGroup = async (name: string, msalId?: string): Promise<void> => {
  try {
    await axios.post(`${process.env.REACT_APP_AZURE_FUNCTIONS}/addGroup`, { name, msalId });
  } catch (error) {
    // TODO: Hook into telemetry & alerting.
  }
};

export default addToSignalRGroup;
