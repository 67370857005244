import { useBoolean, useId } from '@fluentui/react-hooks';
import { DatePicker, TextField, DefaultButton, PrimaryButton, Callout, FontIcon, DirectionalHint, KeyCodes, Stack, Announced } from '@fluentui/react';
import { CSSProperties, KeyboardEvent, MouseEvent, useCallback, useState } from 'react';
import moment from 'moment';
import { initializeComponent, withLocalization } from '../../../services/localization';
import { INTL } from '../../../util/intlUtil';
import { DateTimePickerLocalizationFormatMessages } from '../../../clientResources';
import styled from 'styled-components';

function formatDate(date: Date | undefined | null) {
    if (!date) {
        return '';
    }
    return moment(date).utcOffset(0, true).format().split(/[TZ]/).slice(0, 2).join(' ').substring(0, 16);
};

function getDate(date: Date | undefined | null, time: string) {
    if (!date) {
        return new Date();
    }
    const timeArr = time.split(':');
    const hour = parseInt(timeArr[0], 10);
    const minute = parseInt(timeArr[1], 10);
    return new Date(date.getTime() + hour * 3600000 + minute * 60000);
};

function utcStringToLocalDate(str?: string): Date {
    if (typeof str === 'string' && str) {
        return moment.utc(str).utcOffset(moment(str).utcOffset(), true).toDate();
    }
    return moment.utc().utcOffset(moment().utcOffset(), true).toDate();
};

function localDateToUtcString(date: Date): string {
    return moment(date).utcOffset(0, true).format();
};

type DateTime = string | null;

/**
 * Params:
 * onSave: The call back function for save button
 * onDelete: The call back function for delete button
 * directionalHint: Indicating the position of the pop up callout
 * from: The start date string as initial value
 * to: The end date string as initial value
 * showField: An array indicating which fileds to show, e.g. ["start", "end"]
 * requiredField: An array indicating which fileds must be filled, e.g. ["start", "end"]
 * isDisabled: Boolean parameter indicating whether the button is disabled
 * showDelete: Boolean parameter indicating whether show the delete button
 * errorMessage: Boolean parameter indicating whether show the delete button
 */
export interface DateTimePickerProps {
    onSave?: (timeRange: TimeRange) => void;
    onDelete?: () => void;
    directionalHint?: DirectionalHint;
    from?: DateTime;
    to?: DateTime;
    showField?: string[];
    requiredField: string[];
    isDisabled?: boolean;
    showDelete?: boolean;
    errorMessage?: string;
    marginTop?: boolean;
    wrapperStyle?: CSSProperties;
    hideFromToInText?: boolean;
    size?: 'mini' | 'normal';
};
/**
 * Params:
 * from: The selected start time
 * to: The selected end time
 */
export interface TimeRange {
    from: string | undefined | null;
    to: string | undefined | null;
};

const StyledDiv = styled.div`
    box-sizing: border-box;
  
    /* Auto layout */
  
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    gap: 16px;
  
    width: 480px;
    height: 44px;
  
    /* bodyBackground #ffffff */
  
    background: #ffffff;
    /* menuDivider #c8c6c4 */
  
    border: 1px solid #c8c6c4;
    border-radius: 4px;
  
    /* Inside auto layout */
  
    flex: none;
    order: 0;
    flex-grow: 0;

    .surrounding {
        margin: 10px;
    }
    .date_picker_button.disabled {
        background: #f3f2f1;
    }
    .icon_static {
    width: 16px;
    height: 16px;
  
    /* FabricMDL / 16 */
  
    font-family: 'Fabric MDL2 Assets';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    /* identical to box height, or 16px */
  
    /* bodyText #323130 */
  
    color: #323130;
    fill: #323130;
  
    /* Inside auto layout */
  
    flex: none;
    order: 0;
    flex-grow: 0;
  }
    .date_picker_button_text {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
  
    /* bodyText #323130 */
  
    color: #323130;
  
    /* Inside auto layout */
  
    flex: none;
    order: 1;
    flex-grow: 1;
  }
    .icon_disabled {
    /* String-cell-primary */
  
    width: 16px;
    height: 16px;
  
    /* FabricMDL / 16 */
  
    font-family: 'Fabric MDL2 Assets';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    /* identical to box height, or 16px */
  
    /* disabledBodyText #a19f9d */
  
    color: #a19f9d;
    fill: #a19f9d;
  
    /* Inside auto layout */
  
    flex: none;
    order: 2;
    flex-grow: 0;
  }
    .pointer {
    cursor: pointer;
  }
  .icon {
    /* String-cell-primary */
  
    width: 16px;
    height: 16px;
  
    /* FabricMDL / 16 */
  
    font-family: 'Fabric MDL2 Assets';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    /* identical to box height, or 16px */
  
    /* accentButtonBackground #0078d4 */
  
    color: #0078d4;
    fill: #0078d4;
  
    /* Inside auto layout */
  
    flex: none;
    order: 1;
    flex-grow: 0;
  }
`;

const StyledCallout = styled(Callout)`
  .callout_container {
    padding: 16px;
  }
    .callout_title {
    font-family: Segoe UI;
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 6px;
  }
    .label {
    margin-top: 15px;
    margin-bottom: 5px;
    font-family: Segoe UI;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  }
    .date_picker_button {
    box-sizing: border-box;
  
    /* Auto layout */
  
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    gap: 16px;
  
    width: 480px;
    height: 44px;
  
    /* bodyBackground #ffffff */
  
    background: #ffffff;
    /* menuDivider #c8c6c4 */
  
    border: 1px solid #c8c6c4;
    border-radius: 4px;
  
    /* Inside auto layout */
  
    flex: none;
    order: 0;
    flex-grow: 0;
  }
    .time_inputer {
    width: 220px;
    margin-left: 8px;
    display: inline-block;
  }
  .date_picker {
    width: 220px;
    display: inline-block;
  }
    .time_inputer {
    width: 220px;
    margin-left: 8px;
    display: inline-block;
  }
    .alert-text {
    /* String-cell-primary */
  
    height: 16px;
  
    /* SegoeUI Regular / 12 - ms-fontSize-12 FontSizes.size12 */
  
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */
  
    /* Message / Web / Error-Blocked Icon #A80000 */
  
    color: #a80000;
  
    /* Inside auto layout */
  
    flex: none;
    order: 1;
    flex-grow: 0;
  }
    .action_bar {
    text-align: right;
    padding-top: 16px;
  }
    .second_item {
    margin-left: 10px;
  }
  .required_star {
    /* String-icon-required */
  
    width: 7px;
    height: 12px;
  
    /* FabricMDL / 12 */
  
    font-family: 'Fabric MDL2 Assets';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    vertical-align: top;
    /* identical to box height, or 12px */
  
    /* Message / Web / Error-Blocked Icon #A80000 */
  
    color: #a80000;
  
    /* Inside auto layout */
  
    flex: none;
    order: 1;
    flex-grow: 0;
    display: inline-block;
  }
  .alert-icon {
    font-family: 'Fabric MDL2 Assets';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    /* identical to box height, or 16px */
  
    display: inline-block;
    align-items: center;
    text-align: center;
    margin-right: 4px;
  
    /* Message / Web / Error-Blocked Icon #A80000 */
  
    color: #a80000;
  
    /* Inside auto layout */
  
    flex: none;
    order: 0;
    flex-grow: 0;
  }
`;

const StyledErrorDiv = styled.div`
.alert-text {
    /* String-cell-primary */
  
    height: 16px;
  
    /* SegoeUI Regular / 12 - ms-fontSize-12 FontSizes.size12 */
  
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */
  
    /* Message / Web / Error-Blocked Icon #A80000 */
  
    color: #a80000;
  
    /* Inside auto layout */
  
    flex: none;
    order: 1;
    flex-grow: 0;
  }
`;

function DateTimePickerInternal(props: DateTimePickerProps): JSX.Element {
    const {
        onSave,
        onDelete,
        directionalHint,
        from,
        to,
        requiredField,
        isDisabled,
        showDelete,
        errorMessage,
        marginTop,
        hideFromToInText,
        size = 'normal',
    } = props;
    let { showField } = props;
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const buttonId = useId('callout-button');
    if (!showField) {
        showField = ['start', 'end'];
    }
    let tmpFromDateTime: Date | undefined | null = null;
    let tmpToDateTime: Date | undefined | null = null;
    let tmpFromDate: Date | undefined | null = null;
    let tmpToDate: Date | undefined | null = null;
    let tmpFromTime = '';
    let tmpToTime = '';

    for (let i = 0; i < requiredField.length; i += 1) {
        if (!showField.includes(requiredField[i])) {
            requiredField.splice(i, 1);
        }
    }

    if (from && from !== 'Invalid date') {
        tmpFromDateTime = utcStringToLocalDate(from);
        const year = tmpFromDateTime.getFullYear();
        const month = tmpFromDateTime.getMonth();
        const day = tmpFromDateTime.getDate();
        const hour: number = tmpFromDateTime.getHours();
        const minute: number = tmpFromDateTime.getMinutes();
        tmpFromDate = new Date(year, month, day);
        tmpFromTime = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
    }
    if (to) {
        tmpToDateTime = utcStringToLocalDate(to);
        const year = tmpToDateTime.getFullYear();
        const month = tmpToDateTime.getMonth();
        const day = tmpToDateTime.getDate();
        const hour: number = tmpToDateTime.getHours();
        const minute: number = tmpToDateTime.getMinutes();
        tmpToDate = new Date(year, month, day);
        tmpToTime = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
    }

    const [fromDateTime, setFrom] = useState(tmpFromDateTime);
    const [toDateTime, setTo] = useState(tmpToDateTime);
    const [fromTime, setFromTime] = useState(tmpFromTime);
    const [toTime, setToTime] = useState(tmpToTime);
    const [fromDate, setFromDate] = useState(tmpFromDate);
    const [toDate, setToDate] = useState(tmpToDate);
    const [disabled, { toggle: toggleDisabled }] = useBoolean(!!isDisabled);

    if (!isCalloutVisible) {
        if (tmpFromDateTime?.getTime() !== fromDateTime?.getTime()) {
            setFrom(tmpFromDateTime);
        }
        if (tmpToDateTime?.getTime() !== toDateTime?.getTime()) {
            setTo(tmpToDateTime);
        }
        if (tmpFromTime !== fromTime) {
            setFromTime(tmpFromTime);
        }
        if (tmpToTime !== toTime) {
            setToTime(tmpToTime);
        }
        if (tmpFromDate?.getTime() !== fromDate?.getTime()) {
            setFromDate(tmpFromDate);
        }
        if (tmpToDate?.getTime() !== toDate?.getTime()) {
            setToDate(tmpToDate);
        }
    }
    const tmpSetFrom = (date: Date | undefined | null) => {
        const tmpDate: Date | null = date === undefined ? null : date;
        setFromDate(tmpDate);
        if (date && fromTime === '') {
            setFromTime('00:00');
        }
        if (!date && fromTime) {
            setFromTime('');
        }
    };

    const tmpSetTo = (date: Date | undefined | null) => {
        const tmpDate: Date | null = date === undefined ? null : date;
        setToDate(tmpDate);
        if (date && toTime === '') {
            setToTime('00:00');
        }
        if (!date && toTime) {
            setToTime('');
        }
    };

    function init() {
        if (fromDateTime) {
            tmpFromDateTime = new Date(fromDateTime);
            const year = fromDateTime.getFullYear();
            const month = fromDateTime.getMonth();
            const day = fromDateTime.getDate();
            const hour: number = fromDateTime.getHours();
            const minute: number = fromDateTime.getMinutes();
            tmpFromDate = new Date(year, month, day);
            tmpFromTime = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
            setFromDate(tmpFromDate);
            setFromTime(tmpFromTime);
        }
        if (toDateTime) {
            tmpToDateTime = new Date(toDateTime);
            const year = toDateTime.getFullYear();
            const month = toDateTime.getMonth();
            const day = toDateTime.getDate();
            const hour: number = toDateTime.getHours();
            const minute: number = toDateTime.getMinutes();
            tmpToDate = new Date(year, month, day);
            tmpToTime = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
            setToDate(tmpToDate);
            setToTime(tmpToTime);
        }
    };

    const save = useCallback(() => {
        let tmpFrom: Date | null = null;
        let tmpTo: Date | null = null;
        if (fromDate) {
            tmpFrom = getDate(fromDate, fromTime);
            setFrom(tmpFrom);
        } else {
            setFrom(null);
        }
        if (toDate) {
            tmpTo = getDate(toDate, toTime);
            setTo(tmpTo);
        } else {
            setTo(null);
        }
        onSave?.({ from: tmpFrom ? localDateToUtcString(tmpFrom) : null, to: tmpTo ? localDateToUtcString(tmpTo) : null });
    }, [fromDate, fromTime, onSave, toDate, toTime]);

    function checkTimeFormat(date: Date | undefined | null, time: string, field: string) {
        if (!requiredField.includes(field) && !date && !time) {
            return true;
        }
        if ((!date && time) || (date && !time)) {
            return false;
        }
        if (!time) {
            return false;
        }
        if (typeof time !== 'string') {
            return false;
        }
        if (time.length !== 5) {
            return false;
        }
        if (Number.isNaN(time[0]) || Number.isNaN(time[1]) || Number.isNaN(time[3]) || Number.isNaN(time[4])) {
            return false;
        }
        if (time[2] !== ':') {
            return false;
        }
        if (parseInt(time.substring(0, 2), 10) >= 24) {
            return false;
        }
        if (parseInt(time.substring(3, 5), 10) >= 60) {
            return false;
        }
        return true;
    };

    const dateFormatter = (date?: Date): string =>
        !date
            ? ''
            : `${date.getFullYear().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;

    const showCallout = (event: MouseEvent | KeyboardEvent) => {
        if (!disabled) {
            if (
                event.type === 'click' ||
                (event.type === 'keydown' && ((event as KeyboardEvent).keyCode === KeyCodes.enter || (event as KeyboardEvent).keyCode === KeyCodes.space))
            ) {
                init();
                toggleIsCalloutVisible();
                toggleDisabled();
            }
        }
    };

    const hideCallout = () => {
        toggleIsCalloutVisible();
        toggleDisabled();
    };

    const remove = (event: MouseEvent | KeyboardEvent) => {
        if (!disabled && onDelete) {
            if (
                event.type === 'click' ||
                (event.type === 'keydown' && ((event as KeyboardEvent).keyCode === KeyCodes.enter || (event as KeyboardEvent).keyCode === KeyCodes.space))
            ) {
                onDelete();
            }
        }
    };

    function hasError() {
        const formatCheck = !checkTimeFormat(fromDate, fromTime, 'start') || !checkTimeFormat(toDate, toTime, 'end');
        const requirementCheck = (requiredField.includes('start') && !fromDate) || (requiredField.includes('end') && !toDate);

        let ret = false;
        if (formatCheck) {
            ret = true;
        } else if (requirementCheck) {
            ret = true;
        } else if (showField && showField.length === 2 && getDate(fromDate, fromTime) >= getDate(toDate, toTime)) {
            ret = true;
        }

        return ret;
    };

    function getErrorMessage() {
        let ret = '';
        if (fromDate && !checkTimeFormat(fromDate, fromTime, 'start')) {
            ret = INTL.formatMessage(DateTimePickerLocalizationFormatMessages.StartTimeFormatError);
        } else if (toDate && !checkTimeFormat(toDate, toTime, 'end')) {
            ret = INTL.formatMessage(DateTimePickerLocalizationFormatMessages.EndTimeFormatError);
        } else if (showField && fromDate && toDate && showField.length === 2 && getDate(fromDate, fromTime) >= getDate(toDate, toTime)) {
            ret = INTL.formatMessage(DateTimePickerLocalizationFormatMessages.StartDateTimeError);
        }
        return ret;
    };

    function getButtonText() {
        if (!toDateTime) {
            return hideFromToInText ? formatDate(fromDateTime) : `From ${formatDate(fromDateTime)}`;
        }
        if (!fromDateTime) {
            return `To ${formatDate(toDateTime)}`;
        }
        return `From ${formatDate(fromDateTime)} to ${formatDate(toDateTime)}`;
    };

    function getHint() {
        if (showField && !showField.includes('end')) {
            return 'From YYYY-MM-DD';
        }
        if (showField && !showField.includes('start')) {
            return 'To YYYY-MM-DD';
        }
        return 'From YYYY-MM-DD to YYYY-MM-DD';
    };

    function getTitle() {
        let ret = INTL.formatMessage(DateTimePickerLocalizationFormatMessages.DefineOrEdit);
        if (!showField || showField.length === 2) {
            ret += INTL.formatMessage(DateTimePickerLocalizationFormatMessages.TimeRange);
        } else if (showField.includes('start')) {
            ret += INTL.formatMessage(DateTimePickerLocalizationFormatMessages.Starttime);
        } else {
            ret += INTL.formatMessage(DateTimePickerLocalizationFormatMessages.Endtime);
        }
        return ret;
    };

    const getWrapperStyle = () => {
        return {
            width: 480,
            marginTop: marginTop ? 8 : 0,
            marginLeft: 0,
            ...(props.wrapperStyle || {}),
        };
    };

    return (
        <>
            <StyledDiv className={`date_picker_button surrounding ${disabled ? 'disabled ' : ''} ${size}`} style={getWrapperStyle()} id={buttonId}>
                <FontIcon className="icon_static" iconName="calendar-svg" />
                {!!fromDateTime || !!toDateTime ? (
                    <div className="date_picker_button_text">{getButtonText()}</div>
                ) : (
                    <div className="date_picker_button_text">{getHint()}</div>
                )}
                <FontIcon
                    tabIndex={0}
                    className={disabled ? 'icon_disabled pointer' : 'icon pointer'}
                    iconName="edit-svg"
                    onClick={showCallout}
                    onKeyDown={showCallout}
                    aria-label={INTL.formatMessage(DateTimePickerLocalizationFormatMessages.Edit)}
                    role='button'
                />
                {showDelete && (
                    <FontIcon tabIndex={-1} className={disabled ? 'icon_disabled pointer' : 'icon pointer'} iconName="delete-svg" onClick={remove} onKeyDown={remove} />
                )}
            </StyledDiv>
            {errorMessage && <StyledErrorDiv className="alert-text">{errorMessage}</StyledErrorDiv>}
            {isCalloutVisible && (
                <StyledCallout role="dialog" setInitialFocus directionalHint={directionalHint} target={`#${buttonId}`} onDismiss={hideCallout}>
                    <div className="callout_container">
                        <div className="callout_title">{getTitle()}</div>
                        {requiredField.includes('start') && (
                            <>
                                <div className="label">{INTL.formatMessage(DateTimePickerLocalizationFormatMessages.StartTime)} {requiredField.includes('start') && <div className="required_star">*</div>}</div>
                                <Stack horizontal>
                                    <DatePicker
                                        allowTextInput
                                        title={INTL.formatMessage(DateTimePickerLocalizationFormatMessages.StartTime)}
                                        placeholder="YYYY-MM-DD"
                                        formatDate={dateFormatter}
                                        value={fromDate === null ? undefined : fromDate}
                                        onSelectDate={(date) => {
                                            tmpSetFrom(date);
                                        }}
                                        className="date_picker"
                                    />
                                    <TextField
                                        placeholder="hh:mm"
                                        onChange={(event, newValue) => {
                                            setFromTime(newValue === undefined ? '00:00' : newValue);
                                        }}
                                        value={fromTime}
                                        className="time_inputer"
                                    />
                                </Stack>
                            </>
                        )}
                        {showField.includes('end') && (
                            <>
                                <div className="label">{INTL.formatMessage(DateTimePickerLocalizationFormatMessages.EndTime)} {requiredField.includes('end') && <div className="required_star">*</div>}</div>
                                <Stack horizontal>
                                    <DatePicker
                                        allowTextInput
                                        title={INTL.formatMessage(DateTimePickerLocalizationFormatMessages.EndTime)}
                                        placeholder="YYYY-MM-DD"
                                        formatDate={dateFormatter}
                                        value={toDate === null ? undefined : toDate}
                                        onSelectDate={(date) => {
                                            tmpSetTo(date);
                                        }}
                                        className="date_picker"
                                    />
                                    <TextField
                                        placeholder="hh:mm"
                                        onChange={(event, newValue) => {
                                            setToTime(newValue === undefined ? '23:59' : newValue);
                                        }}
                                        value={toTime}
                                        className="time_inputer"
                                    />
                                </Stack>
                            </>
                        )}

                        {hasError() && getErrorMessage() && (
                            <div className="alert-text">
                                <FontIcon iconName="alert-svg" className="alert-icon" />
                                {getErrorMessage()}
                                <Announced message={getErrorMessage()} />
                            </div>
                        )}
                        <div className="action_bar">
                            <PrimaryButton
                                disabled={hasError()}
                                text={INTL.formatMessage(DateTimePickerLocalizationFormatMessages.Save)}
                                onClick={() => {
                                    hideCallout();
                                    save();
                                }}
                                allowDisabledFocus
                            />
                            <DefaultButton className="second_item" text={INTL.formatMessage(DateTimePickerLocalizationFormatMessages.Cancel)} onClick={hideCallout} allowDisabledFocus />
                        </div>
                    </div>
                </StyledCallout>
            )}
        </>
    );
}

DateTimePickerInternal.defaultProps = {
    onDelete: () => { },
    isDisabled: false,
    showDelete: false,
    showField: ['start', 'end'],
};

export const DateTimePicker = withLocalization(initializeComponent(DateTimePickerInternal));