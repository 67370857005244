export const SET_LANGUAGES = 'SET_LANGUAGES';

export interface ILanguageItem {
  id: number;
  displayName: string;
  languageCode: string;
}

export interface ILanguagePairItem {
  id: number;
  sourceLanguage: number;
  targetLanguage: number;
}

export interface ISetLanguages {
  type: typeof SET_LANGUAGES;
  payload: {
    [key: string]: ILanguageItem;
  };
}

export type LanguagesDispatchTypes = ISetLanguages;
