export const SET_FILES = 'SET_FILES';

export interface IFileItem {
  id: number;
  extractedSentenceCount: number;
  language: number;
  uploadDate: string;
}

export interface ISetFiles {
  type: typeof SET_FILES;
  payload: {
    [key: number]: IFileItem;
  };
}

export type FileDispatchTypes = ISetFiles;
