import { IDocumentItem } from '../documents/documents.types';
import { IFileItem } from '../files/files.types';
import { ILanguageItem } from '../languages/languages.types';
import { IUserItem } from '../users/users.types';

import { ModelStatus } from '../../utils/constants/modelStatus';

export const GET_MODELS = 'GET_MODELS';
export const GET_MODELS_SUCCESSFUL = 'GET_MODELS_SUCCESSFUL';
export const GET_MODELS_FAILED = 'GET_MODELS_FAILED';

export const GET_MODEL = 'GET_MODEL';
export const GET_MODEL_SUCCESSFUL = 'GET_MODEL_SUCCESSFUL';
export const GET_MODEL_FAILED = 'GET_MODEL_FAILED';

export const RENAME_MODEL = 'RENAME_MODEL';
export const RENAME_MODEL_SUCCESSFUL = 'RENAME_MODEL_SUCCESSFUL';
export const RENAME_MODEL_FAILED = 'RENAME_MODEL_FAILED';

export const DELETE_MODEL = 'DELETE_MODEL';
export const DELETE_MODEL_SUCCESSFUL = 'DELETE_MODEL_SUCCESSFUL';
export const DELETE_MODEL_FAILED = 'DELETE_MODEL_FAILED';

export const UPDATE_MODEL_TRAINING_STATUS = 'UPDATE_MODEL_TRAINING_STATUS';

export const UPDATE_MODEL_PUBLISH_STATUS = 'UPDATE_MODEL_PUBLISH_STATUS';
export const UPDATE_MODEL_PUBLISH_STATUS_SUCCESSFUL = 'UPDATE_MODEL_PUBLISH_STATUS_SUCCESSFUL';
export const UPDATE_MODEL_PUBLISH_STATUS_FAILED = 'UPDATE_MODEL_PUBLISH_STATUS_FAILED';

export const CREATE_MODEL = 'CREATE_MODEL';
export const CREATE_MODEL_SUCCESSFUL = 'CREATE_MODEL_SUCCESSFUL';
export const CREATE_MODEL_FAILED = 'CREATE_MODEL_FAILED';

export const DUPLICATE_MODEL = 'DUPLICATE_MODEL';
export const DUPLICATE_MODEL_SUCCESSFUL = 'DUPLICATE_MODEL_SUCCESSFUL';
export const DUPLICATE_MODEL_FAILED = 'DUPLICATE_MODEL_FAILED';

export const UPDATE_DRAFT_MODEL = 'UPDATE_DRAFT_MODEL';
export const UPDATE_DRAFT_MODEL_SUCCESSFUL = 'UPDATE_DRAFT_MODEL_SUCCESSFUL';
export const UPDATE_DRAFT_MODEL_FAILED = 'UPDATE_DRAFT_MODEL_FAILED';

export const TRAIN_MODEL = 'TRAIN_MODEL';
export const TRAIN_MODEL_SUCCESSFUL = 'TRAIN_MODEL_SUCCESSFUL';
export const TRAIN_MODEL_FAILED = 'TRAIN_MODEL_FAILED';

export const FILTER_MODELS = 'FILTER_MODELS';
export const SET_TESTED_OR_PUBLISHED_MODEL_IDS = 'SET_TESTED_OR_PUBLISHED_MODEL_IDS';

export const CLEAR_MODELS = 'CLEAR_MODELS';

export const MODEL_COPY = 'MODEL_COPY';
export const MODEL_COPY_SUCCESSFUL = 'MODEL_COPY_SUCCESSFUL';
export const MODEL_COPY_FAILED = 'MODEL_COPY_FAILED';

export interface IModelItem {
  id: number;
  name: string;
  modelIdentifier: null;
  projectId: string;
  documents: [] | number[];
  modelRegionStatuses: IModelRegionStatusItemBase[] | null;
  baselineBleuScorePunctuated: number | null;
  bleuScorePunctuated: number | null;
  baselineBleuScoreUnpunctuated: number | null;
  bleuScoreUnpunctuated: number | null;
  baselineBleuScoreCIPunctuated: number | null;
  bleuScoreCIPunctuated: number | null;
  baselineBleuScoreCIUnpunctuated: number | null;
  bleuScoreCIUnpunctuated: number | null;
  startDate: number | null;
  completionDate: string;
  modifiedDate: string;
  createdDate: string;
  createdBy: IUserItem;
  modifiedBy: IUserItem | null;
  trainingSentenceCount: number;
  tuningSentenceCount: number;
  testingSentenceCount: number;
  phraseDictionarySentenceCount: number;
  sentenceDictionarySentenceCount: number;
  monolingualSentenceCount: number | null;
  modelStatus: string;
  isTuningAuto: null;
  isTestingAuto: null;
  isAutoDeploy: boolean;
  autoDeployThreshold: null;
  hubBLEUScore: null;
  hubCategory: string;
  errorCode: string;
  isCopied: boolean;
  modelType: string;
}

export interface IModelRegionStatusItemBase {
  region: number;
  isDeployed: boolean;
}

export interface IModelRegionStatusItem extends IModelRegionStatusItemBase {
  regionName: string;
}

export interface ICreateModelData {
  name: string;
  projectId: string;
  documentIds: number[] | string[];
  isTuningAuto?: boolean;
  isTestingAuto?: boolean;
  isAutoDeploy?: boolean;
  isAutoTrain?: boolean;
  autoDeployThreshold?: number;
}

export interface IModelsEntities {
  models: {
    [key: string]: IModelItem;
  };
  users: {
    [key: string]: IUserItem;
  };
}

export interface IModelEntities {
  models: {
    [key: string]: IModelItem;
  };
  files: {
    [key: string]: IFileItem;
  };
  languages: {
    [key: string]: ILanguageItem;
  };
  documents: {
    [key: string]: IDocumentItem;
  };
}

export interface IModelsPayload {
  ids: any[];
  data: {
    [key: string]: IModelItem;
  };
}

export interface IModelPayload {
  id: string;
  data: {
    [key: string]: IModelItem;
  };
}

export interface IRenameModelPayload {
  id: string;
  data: {
    name: string;
  };
}

export interface IUpdateDraftModelPayload {
  id: string;
  data: {
    name: string;
    documents: number[] | [];
  };
}

export interface IUpdateModelPublishStatusPayload {
  id: string;
  data: {
    modelStatus: ModelStatus;
    modelRegionStatuses: IModelRegionStatusItemBase[] | null;
  };
}

export interface IUpdateModelStatusPayload {
  id: string;
  data: {
    modelStatus: ModelStatus;
    errorCode: string;
  };
}

export interface IGetModels {
  type: typeof GET_MODELS;
}

export interface IGetModelsSuccessful {
  type: typeof GET_MODELS_SUCCESSFUL;
  payload: IModelsPayload;
}

export interface IGetModelsFailed {
  type: typeof GET_MODELS_FAILED;
}

export interface IGetModel {
  type: typeof GET_MODEL;
}

export interface IGetModelSuccessful {
  type: typeof GET_MODEL_SUCCESSFUL;
  payload: IModelsPayload;
}

export interface IGetModelFailed {
  type: typeof GET_MODEL_FAILED;
}

export interface IRenameModel {
  type: typeof RENAME_MODEL;
}

export interface IRenameModelSuccessful {
  type: typeof RENAME_MODEL_SUCCESSFUL;
  payload: IRenameModelPayload;
}

export interface IRenameModelFailed {
  type: typeof RENAME_MODEL_FAILED;
}

export interface IUpdateDraftModel {
  type: typeof UPDATE_DRAFT_MODEL;
}

export interface IUpdateDraftModelSuccessful {
  type: typeof UPDATE_DRAFT_MODEL_SUCCESSFUL;
  payload: IUpdateDraftModelPayload;
}

export interface IUpdateDraftModelFailed {
  type: typeof UPDATE_DRAFT_MODEL_FAILED;
}

export interface ITrainModel {
  type: typeof TRAIN_MODEL;
}

export interface ITrainModelSuccessful {
  type: typeof TRAIN_MODEL_SUCCESSFUL;
  payload: string;
}

export interface ITrainModelFailed {
  type: typeof TRAIN_MODEL_FAILED;
}

export interface IDeleteModel {
  type: typeof DELETE_MODEL;
}

export interface IDeleteModelSuccessful {
  type: typeof DELETE_MODEL_SUCCESSFUL;
  payload: string;
}

export interface IDeleteModelFailed {
  type: typeof DELETE_MODEL_FAILED;
}

export interface IUpdateModelPublishStatus {
  type: typeof UPDATE_MODEL_PUBLISH_STATUS;
}

export interface IUpdateModelPublishStatusSucessful {
  type: typeof UPDATE_MODEL_PUBLISH_STATUS_SUCCESSFUL;
  payload: IUpdateModelPublishStatusPayload;
}

export interface IUpdateModelPublishStatusFailed {
  type: typeof UPDATE_MODEL_PUBLISH_STATUS_FAILED;
}

export interface IUpdateModelStatus {
  type: typeof UPDATE_MODEL_TRAINING_STATUS;
  payload: IUpdateModelStatusPayload;
}

export interface ICreateModel {
  type: typeof CREATE_MODEL;
}

export interface ICreateModelSuccessful {
  type: typeof CREATE_MODEL_SUCCESSFUL;
  payload: IModelsPayload;
}

export interface ICreateModelFailed {
  type: typeof CREATE_MODEL_FAILED;
}

export interface IFilterModels {
  type: typeof FILTER_MODELS;
  payload: string[];
}

export interface ISetTestedOrPublishedModelIds {
  type: typeof SET_TESTED_OR_PUBLISHED_MODEL_IDS;
  payload: string[];
}

export interface IDuplicateModel {
  type: typeof DUPLICATE_MODEL;
}

export interface IDuplicateModelSuccessful {
  type: typeof DUPLICATE_MODEL_SUCCESSFUL;
  payload: IModelsPayload;
}

export interface IDuplicateModelFailed {
  type: typeof DUPLICATE_MODEL_FAILED;
}

export interface IClearModels {
  type: typeof CLEAR_MODELS;
}

export interface IModelCopy {
  type: typeof MODEL_COPY;
}

export interface IModelCopySuccessful {
  type: typeof MODEL_COPY_SUCCESSFUL;
}

export interface IModelCopyFailed {
  type: typeof MODEL_COPY_FAILED;
}

export type ModelsDispatchTypes =
  | IGetModels
  | IGetModelsSuccessful
  | IGetModelsFailed
  | IGetModel
  | IGetModelSuccessful
  | IGetModelFailed
  | IRenameModel
  | IRenameModelSuccessful
  | IRenameModelFailed
  | IUpdateDraftModel
  | IUpdateDraftModelSuccessful
  | IUpdateDraftModelFailed
  | ITrainModel
  | ITrainModelSuccessful
  | ITrainModelFailed
  | IDeleteModel
  | IDeleteModelSuccessful
  | IDeleteModelFailed
  | ICreateModel
  | ICreateModelSuccessful
  | ICreateModelFailed
  | IDuplicateModel
  | IDuplicateModelSuccessful
  | IDuplicateModelFailed
  | IUpdateModelPublishStatus
  | IUpdateModelPublishStatusSucessful
  | IUpdateModelPublishStatusFailed
  | IUpdateModelStatus
  | IFilterModels
  | ISetTestedOrPublishedModelIds
  | IClearModels
  | IModelCopy
  | IModelCopySuccessful
  | IModelCopyFailed;
