import { Modal, FontIcon, Link, PrimaryButton, IModalProps } from "@fluentui/react";
import { Carousel } from "../Carousel/Carousel";
import { useEffect, useLayoutEffect, useState } from "react";
import { INTL } from "../../../util/intlUtil";
import { FormatMessagePlaceholder, WizardDialogLocalizationFormatMessages } from "../../../clientResources";
import styled from "styled-components";
import { initializeComponent, withLocalization } from "../../../services/localization";

export enum WizardDialogCloseState {
    Dismissed = "Dismissed",
    WithResourceSelection = "WithResourceSelection",
    WithoutResourceSelection = "WithoutResourceSelection",
}

export interface Step {
    image: string;
    title: string;
    description?: string;
    button?: {};
    link?: {};
}

export interface IWizardDialogProps {
    onDismiss: () => void;
    steps: Step[];

    hasDefaultResource?: boolean;
    resourceName?: string;
    subscriptionName?: string;

    isLoggedIn: boolean;
    onSignIn: () => void;
    onDialogOpenedOrClosed: () => void;
    onLogEvent?: (state: WizardDialogCloseState) => void;

    onLoad?: () => void;
    contentHeight?: number;

    signInBtnContext?: string;
    chooseResourceBtnContext?: string;
    closeNContinueBtnContext?: string;
    laterBtnContext?: string;
}

export interface IStepProps extends IWizardDialogProps {
    currentStep: number;
    isLastStep?: boolean;
    defaultHeight: number;
    setCurrentCarouselHeight: (height: number) => void;
    closeButtonOnDismiss?: (closeState?: WizardDialogCloseState) => void;
}

const WizardDialogStep = (props: IStepProps) => {
    useLayoutEffect(() => {
        let otherContentHeight = 0;
        if (props.isLastStep) {
            const otherContent = document.querySelector(".carousel-last-step");
            otherContentHeight += otherContent?.clientHeight || 0;
            // otherContentHeight += parseInt(otherContent?.computedStyleMap()?.get("bottom")?.toString()) || 0; // not support firefox
            otherContentHeight += 16;
        }
        const exceptCarouselHeight = document.querySelector(".carousel.active").clientHeight + otherContentHeight;
        props.setCurrentCarouselHeight(exceptCarouselHeight > props.defaultHeight ? exceptCarouselHeight : props.defaultHeight);
    }, [props, props.currentStep])
    return <>
        {
            props.steps.map((item, index) => <div key={`wizard-dialog-carousel-${index}`} className={`carousel ${index === props.currentStep ? "active" : ""}`} >
                <h2 className="carousel-title">{item.title}</h2>
                {item.description && <p className="carousel-description">{item.description}</p>}
            </div>)
        }
        {props.isLastStep && <div className="carousel-last-step">
            {props.hasDefaultResource && <div className="default-resource-info">
                <p>{INTL.formatMessage(WizardDialogLocalizationFormatMessages.Subscription, {
                    [FormatMessagePlaceholder]: props.subscriptionName,
                })}</p>
                <p>{INTL.formatMessage(WizardDialogLocalizationFormatMessages.Resource, {
                    [FormatMessagePlaceholder]: props.resourceName,
                })}<Link onClick={props.onDialogOpenedOrClosed}>&nbsp;{INTL.formatMessage(WizardDialogLocalizationFormatMessages.Edit)}</Link></p>
            </div>}
            {props.isLoggedIn ? (
                <PrimaryButton
                    text={props.hasDefaultResource ? (props.closeNContinueBtnContext || INTL.formatMessage(WizardDialogLocalizationFormatMessages.CloseAndContinueButton)) : (props.chooseResourceBtnContext || INTL.formatMessage(WizardDialogLocalizationFormatMessages.ChooseResourceButton))}
                    className="carousel-last-step-button"
                    onClick={props.hasDefaultResource ? () => props.closeButtonOnDismiss(WizardDialogCloseState.WithResourceSelection) : props.onDialogOpenedOrClosed}
                />
            ) : (
                <PrimaryButton text={props.signInBtnContext || INTL.formatMessage(WizardDialogLocalizationFormatMessages.LogInToStudio)} className="carousel-last-step-button" onClick={props.onSignIn} />
            )}
        </div>}
        {props.isLastStep && !props.hasDefaultResource && <Link className="do-this-later" onClick={() => props.closeButtonOnDismiss(WizardDialogCloseState.WithoutResourceSelection)}>
            {props.laterBtnContext || INTL.formatMessage(WizardDialogLocalizationFormatMessages.DoThisLater)}
        </Link>}
    </>
};

interface IStyledModalProps extends IModalProps {
    carouselHeight: number;
}

const StyledModal = styled((props: IStyledModalProps) => <Modal {...props} />)`
    overflow: hidden;
    .carousel-container {
        min-height: ${props => props.carouselHeight}px;
        margin: 0;
        position: relative;
        .carousel {
            display: flex;
            align-items: center;
            flex-direction: column;
            text-align: center;
            padding: 24px 0 0 0;
            width: 87%;
            position: absolute;
            left: 6.45%;
            opacity: 0;
            transition: opacity ease-in .1s;

            .carousel-title {
                font-size: 32px;
                margin: 0 0 8px;
                font-weight: 400;
                color: #136bfb;
            }
            .carousel-description {
                margin: 0 0 16px;
            }
        }

        .active {
            opacity: 1 !important;
        }

        .carousel-button {
            user-select: none;
            line-height: 1.5;
            font-size: 21px;
            color: #136bfb;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        .prev {
            top: 50%;
            left: 15px;
            transform: translateY(-60%);
        }

        .next {
            top: 50%;
            right: 15px;
            transform: translateY(-60%);
        }

        .carousel-last-step {
            position: absolute;
            bottom: 16px;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;

            .default-resource-info {
                margin-bottom: 20px;
                p {
                    font-size: 12px;
                    margin: 0;
                }
            }

            .carousel-last-step-button {
                .ms-Button-label {
                    font-weight: 400;
                }
            }
        }

        .do-this-later {
            position: absolute;
            bottom: 22px;
            right: 4%;
            text-decoration: none;
        }

        .dots-container {
            position: absolute;
            bottom: -40px;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            .dot-icon {
                cursor: pointer;
                font-size: 12px;
                padding: 8px;
                color: #ffffff;
            }
        }
    }

    .carousel-container-large {
        height: 234px;
    }

    @media screen and (max-width: 480px) {
        .carousel-container {
            height: ${props => props.carouselHeight}px;
            .carousel {
                .carousel-title {
                    font-size: 20px;
                }
                .carousel-description {
                    margin: 0 10px 16px 10px;
                }
            }
        }
        .carousel-container-large {
            min-height: 280px;
        }
    }

    @media screen and (max-width: 640px) {
        .carousel-container {
            .carousel {
                .carousel-title {
                    font-size: 20px;
                }
            }
        }
    }

    .modal-container {
        width: 620px;
        min-width: 320px;
        overflow: unset;
        padding: 0 !important;

        .modal-scrollable-content {
            min-height: 398px;
            width: 100%;
            overflow: unset;

            .close-button {
                padding: 5px;
                font-size: 18px;
                color: #ffffff;
                position: absolute;
                top: 4px;
                right: 4px;
                cursor: pointer;
            }
            .image-wrapper {
                width: 100%;
                height: 220px;
                background-color: #000000;
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 106px;
                    height: 106px;
                    object-fit: cover;
                }
            }
        }
    }

    @media screen and (max-width: 320px) {
        .carousel-container {
            height: ${props => props.carouselHeight}px;
            .carousel {
                .carousel-title {
                    font-size: 20px;
                }
                .carousel-description {
                    width: 240px;
                }
            }
            .choose-resource {
                bottom: 32px;
            }
            .carousel-last-step {
                bottom: 20px;
            }
            .do-this-later {
                bottom: 2px;
                left: 50%;
                transform: translateX(-25%);
            }
            .dots-container {
                bottom: unset;
                top: -30px;
            }
        }
        .carousel-container-large {
            height: 320px;
        }
        .modal-container {
            max-height: 100% !important;
            height: 100%;
            overflow: auto;
            .modal-scrollable-content {
                .close-button {
                    top: 10px;
                    right: 10px;
                }
                .image-wrapper {
                    height: 161px;
                    img {
                        height: 70px;
                        width: 70px;
                    }
                }
            }
        }
    }

    .modal-container {
        min-height: 398px;
    }

    @media screen and (max-height: ${props => props.carouselHeight + 161}px) {
        .dots-container {
            bottom: unset !important;
            top: -30px;
        }
    }
`;

const InternalWizardDialog = (props: IWizardDialogProps) => {
    const contentHeight = props.contentHeight || 178;
    const totalSteps = props.steps.length;
    const closeButtonOnDismiss = (closeState?: WizardDialogCloseState) => {
        props.onDismiss();
        // log telemetry
        if (props.onLogEvent) {
            if (closeState) {
                props.onLogEvent && props.onLogEvent(closeState);
                return
            }
            if (props.hasDefaultResource) {
                props.onLogEvent && props.onLogEvent(WizardDialogCloseState.WithResourceSelection);
                return
            }
            if (carouselIndex === props.steps.length - 1) {
                props.onLogEvent(WizardDialogCloseState.WithoutResourceSelection);
                return
            }
            props.onLogEvent(WizardDialogCloseState.Dismissed);
        }
    };

    const [carouselIndex, setCarouselIndex] = useState(0);
    const [currentStepProps, setCurrentStepProps] = useState({
        currentStep: 0,
        isLastStep: false,
        closeButtonOnDismiss: closeButtonOnDismiss,
        ...props
    } as IStepProps);
    const [isLastStep, setIsLastStep] = useState(false);
    const [currentCarouselHeight, setCurrentCarouselHeight] = useState(contentHeight);

    useEffect(() => {
        const lastStep = carouselIndex === totalSteps - 1;
        setIsLastStep(lastStep);
        setCurrentStepProps({
            ...props,
            isLastStep: lastStep,
            currentStep: carouselIndex,
            defaultHeight: contentHeight,
            closeButtonOnDismiss: closeButtonOnDismiss,
            setCurrentCarouselHeight: (height: number) => currentCarouselHeight !== height && setCurrentCarouselHeight(height),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carouselIndex]);

    return (
        <StyledModal
            className="wizard-dialog"
            carouselHeight={currentCarouselHeight}
            isOpen={true}
            isBlocking={false}
            onDismiss={props.onDismiss}
            containerClassName="modal-container"
            scrollableContentClassName="modal-scrollable-content"
        >
            <FontIcon iconName="Cancel" className="close-button" onClick={() => closeButtonOnDismiss()} />
            <div className="image-wrapper">
                <img src={props.steps[carouselIndex].image} alt={props.steps[carouselIndex].title} />
            </div>
            <Carousel
                className={`carousel-container ${isLastStep && props.hasDefaultResource ? "carousel-container-large" : ""}`}
                carouselIndex={carouselIndex}
                onChangeStep={setCarouselIndex}
                currentStep={<WizardDialogStep {...currentStepProps} />}
                totalSteps={totalSteps}
            />
        </StyledModal>
    );
};

export const WelcomeWizardDialog = withLocalization(initializeComponent(InternalWizardDialog));