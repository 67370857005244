import {
  Breadcrumb, IBreadcrumbItem, ITooltipHostStyles, TooltipOverflowMode, useTheme
} from "@fluentui/react";
import _ from "lodash";
import { BreadcrumbLocalizationFormatMessages } from "../../../clientResources";
import { initializeComponent, useLocalization, withLocalization } from "../../../services/localization";
import { INTL } from "../../../util/intlUtil";

export interface DefaultBreadcrumbProps {
  items: IBreadcrumbItem[];
};

const StyledBreadcrumbInternal = (props: DefaultBreadcrumbProps) => {
  const defaultTheme = useTheme();

  const breadcrumbs = _.map(props.items, (readcrumb) => {
    if (readcrumb?.isCurrentItem) {
      readcrumb.style = {
        fontSize: "0.875rem",
        fontWeight: 400,
        color: "black",
        pointerEvents: "none",
        cursor: "pointer",
      };
    }
    return readcrumb;
  });

  return !props.items ? null : (
    <Breadcrumb
      items={breadcrumbs}
      ariaLabel={INTL.formatMessage(BreadcrumbLocalizationFormatMessages.Breadcrumb)}
      styles={{
        root: { margin: "0px" },
        itemLink: {
          fontSize: "0.875rem",
          fontWeight: 400,
          color: defaultTheme.palette.themePrimary,
        },
        listItem: {
          selectors: {
            ":last-child .ms-Breadcrumb-itemLink": {
              color: "black",
              fontWeight: 400,
              pointerEvents: 'none',
              cursor: 'pointer'
            },
            ":first-child .ms-Breadcrumb-itemLink": {
              paddingLeft: 0
            }
          },
        },
      }}
      {...props} />

  );
};

export const StyledBreadcrumb = withLocalization(initializeComponent(StyledBreadcrumbInternal));