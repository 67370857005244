import { PublicClientApplication } from '@azure/msal-browser';

const msalConfig = {
  auth: {
    clientId: `${process.env.REACT_APP_APPLICATION_ID}`,
    redirectUri: process.env.REACT_APP_REDIRECT_URL,
    authority: process.env.REACT_APP_AUTHORITY,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
  // TODO: Establish if there is a need for system logging
  // System logging options: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
};

export const requestScopes = {
  scopes: [`${process.env.REACT_APP_API_SCOPE_ROOT}/access_as_user`],
};

export const msalInstance = new PublicClientApplication(msalConfig);
