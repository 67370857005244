import styled from "styled-components";

export interface IA11yAnchorButtonProps extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
    content: string | JSX.Element;
    /**
     * contentAnchorId is the id of content or body element,
     * and the element with this id will be the navigate target of "skip to content" button.
     * Example:
     * <TargetElement id="exampleId" />
     * the props of contentAnchorId passed in as "exampleId"
     * "skip to content" button will navigate to "TargetElement"
     */
    contentAnchorId: string;
    color: string;
    background: string;
    height?: string;
}
export const A11yAnchorButton = styled((props: IA11yAnchorButtonProps) =>
    <a  
        className={props.className}
        href={`#${props.contentAnchorId}`}
    >
        {props.content}
    </a>
)`
        position: absolute;
        z-index: 999;
        height: ${props => props.height || "40px"};
        line-height: calc(${(props => props.height || "40px")} - 1rem);
        background: ${props => props.background};
        left: calc(50% - 70px);
        padding: .5rem;
        text-align: center;
        box-sizing: border-box;
        transform: translateY(-150%);
        color: ${props => props.color};

        &:focus, &:hover {
            transform: translateY(0);
        }
`;