import React, { useEffect, useState } from "react";
import { Panel, PanelType, Link, Separator, Toggle, Text } from "@fluentui/react";
import { INTL } from "../../../util/intlUtil";
import { NewFeaturesPanelLocalizationFormatMessages } from "../../../clientResources";
import { initializeComponent, useLocalization, withLocalization } from "../../../services/localization";
import styled from "styled-components";
import { defaultTheme } from "../../../themes";
import { useId } from "@fluentui/react-hooks";

export interface IFeatureListItem {
    title: string | JSX.Element;
    description: string | JSX.Element;
    isEnabled: boolean;
    requestUrl?: string;
    onToggle?: Function;
    isToggleAllowed: boolean;
    pillText?: string;
}

export interface NewFeaturesPanelProps {
    mainTitle?: string | JSX.Element;
    featureList: IFeatureListItem[];
    onLoad?: () => void;
    onClose?: () => void;
    onLightDismissClick?: () => void;
    autoFlyout?: boolean | false;
    headerText: string;
    isOpen?: boolean | true;
};

const TitleText = styled(Text)`
    display: inline-block;
    font-weight: 600;
    flex:1
`;
const DescriptionText = styled(Text)`
    display: inline-block;
`;
const ItemWrapper = styled.div`
    margin-top:20px
`;
const StyledLink = styled(Link)`
    padding-right:8px;
`;
const StyledPill = styled.span`
    display: inline-block;
    box-sizing: border-box;
    margin-left: 5px;
    padding: 1px 8px;
    background: ${defaultTheme.header.palette.themeDarkAlt};
    border: 1px solid ${defaultTheme.header.palette.themeLighter};
    border-radius: 16px;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    color: ${defaultTheme.body.palette.neutralDark};
`;
const MainTitle = styled(Text)`
    padding-bottom: 20px;
    display: inline-block;
`;

const StyledDiv = styled.div`
    display:flex;
    flex-direction:row;
    & .ms-Toggle{
        width:100%;
    }
`

const StyleToggle = styled(Toggle)`
    width:100%;
    label.ms-Toggle-label {
        flex:1;
    }

`
const NewFeaturesPanelInternal = (props: NewFeaturesPanelProps) => {
    const { onClose, onLoad, autoFlyout, headerText, isOpen } = props;
    const [isPanelOpen, setIsPanelOpen] = useState(isOpen);
    const id = useId("NewFeaturesPanel")
    useEffect(() => {
        setIsPanelOpen(isOpen)
    }, [isOpen])

    function onDismiss() {
        setIsPanelOpen(false);
        props.onClose?.();
    }

    function onLightDismissClick() {
        setIsPanelOpen(false);
        props.onLightDismissClick?.();
    }

    return (
        <Panel
            role="dialog"
            aria-labelledby={INTL.formatMessage(NewFeaturesPanelLocalizationFormatMessages.NewFeatures)}
            closeButtonAriaLabel={INTL.formatMessage(NewFeaturesPanelLocalizationFormatMessages.Close)}
            isOpen={isPanelOpen}
            onDismiss={onDismiss}
            type={PanelType.custom}
            customWidth={"800px"}
            style={{ top: "40px", padding: "32px" }}
            isLightDismiss
            onLightDismissClick={onLightDismissClick}
            headerText={headerText || ""}
            headerTextProps={{ 'aria-level': 2 }}
            layerProps={{ eventBubblingEnabled: true }}
            styles={{
                root: {
                    ".ms-Panel-commands": {
                        position: "sticky",
                        height: "40px",
                        backgroundColor: "#fff",
                    },
                },
                content: { height: "100%" },
                scrollableContent: { height: "100%" },
            }}
        >
            <Separator />
            <MainTitle>{props.mainTitle}</MainTitle>
            {
                props.featureList.map((item: IFeatureListItem) => (
                    <ItemWrapper>
                        <StyledDiv>
                            {item.requestUrl &&
                                <>
                                    <TitleText>
                                        {item.title}
                                        {item.pillText &&
                                            <StyledPill>{item.pillText}</StyledPill>}
                                    </TitleText>
                                    <StyledLink href={item.requestUrl}>
                                        {INTL.formatMessage(NewFeaturesPanelLocalizationFormatMessages.RequestAccess)}
                                    </StyledLink>
                                </>
                            }
                            {!item.requestUrl && <StyleToggle
                                onText={INTL.formatMessage(NewFeaturesPanelLocalizationFormatMessages.Enabled)}
                                offText={INTL.formatMessage(NewFeaturesPanelLocalizationFormatMessages.Disabled)}
                                defaultChecked={item.isEnabled}
                                disabled={!item.isToggleAllowed}
                                label={<>
                                    <TitleText>
                                        {item.title}
                                        {item.pillText &&
                                            <StyledPill>{item.pillText}</StyledPill>}
                                    </TitleText>
                                </>}
                                inlineLabel
                            />}
                        </StyledDiv>
                        <DescriptionText>
                            {item.description}
                        </DescriptionText>
                    </ItemWrapper>
                ))
            }
        </Panel>
    );

};

export const NewFeaturesPanel = withLocalization(initializeComponent(NewFeaturesPanelInternal));