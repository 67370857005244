import React from 'react';
import { initializeComponent, withLocalization } from "../../../services/localization";
import { Stack, Text, CommandBar, ICommandBarItemProps, IStyle } from "@fluentui/react";
import { defaultTheme } from "../../../themes";
import Platforms from './Platforms';

export interface ISupportedPlatforms {
    supportsIoT?: boolean;
    supportsDocker?: boolean;
    supportsCloud?: boolean;
}

export interface CaptionPageProps {
    title?: string;
    preview?: string;
    closeButton?: JSX.Element;
    description?: string;
    rest?: JSX.Element;
    commandBarItems?: ICommandBarItemProps[];
    ariaLabel?: string;
    commandStyle?: IStyle;
    platform?: ISupportedPlatforms | JSX.Element;
}


export const CaptionPage = (props: CaptionPageProps) => {
    const {
      title,
      closeButton,
      description,
      rest,
      commandBarItems,
      ariaLabel,
      commandStyle,
      platform,
      preview,
    } = props;

    const isElement = React.isValidElement(platform);
    
    const styles = {
        headerWrap: {
            paddingTop: "8px",
            justifyContent: "space-between",
        },
        header: {
          fontWeight: 600,
          fontSize: "24px",
          lineHeight: "32px",
          marginBottom: "0.5rem",
          marginTop: 0,
        },
        subtitle: {
          fontWeight: 200,
          fontStyle: "italic",
          marginLeft: "5px",
        },
        hidden: {
          display: "none !important"
        }
      };
     
    return (
      <>
        <Stack
          className="caption-header"
          horizontal
          style={styles.headerWrap}
          hidden={false}
        >
          {title && (
            <Text style={styles.header} as="h1">
              {title}
              {preview && <Text style={styles.subtitle}>{preview}</Text>}
            </Text>
          )}
          {closeButton}
        </Stack>
        <Stack horizontal style={{paddingTop: "16px"}}>
          <Stack grow>
            {description && <Text style={{ maxWidth: "754px" }}>{description}</Text>}
            {rest}
          </Stack>
          <Stack
            grow
            style={{
              paddingLeft: 20,
              marginLeft: 20,
              borderLeft: `1px solid ${defaultTheme.body.palette.neutralTertiary}`,
            }}
          >
            {!isElement ? (
                <Platforms
                  supportsIoT={(platform as ISupportedPlatforms)?.supportsIoT}
                  supportsDocker={(platform as ISupportedPlatforms)?.supportsDocker}
                  supportsCloud={(platform as ISupportedPlatforms)?.supportsCloud}
                />
            ) : (
              platform
            )}
          </Stack>
        </Stack>
        <Stack grow style={{ marginTop: "10px" }}>
          <CommandBar
            styles={{ root: commandStyle }}
            items={commandBarItems}
            ariaLabel={ariaLabel}
          />
        </Stack>
      </>
    );
}

export const CaptionPageInner = withLocalization(initializeComponent(CaptionPage));