/* eslint-disable @typescript-eslint/no-use-before-define */
import i18next from 'i18next';

import { IPopUpErrorItem } from './popUpErrors.types';

import { PopUpEntityTypes, PopUpErrorActions, RequestTypes } from '../../utils/constants/popUpErrors';

interface IPopUpArguments {
  title: string;
  errorMessage?: string;
  statusCode: number;
  entityType: PopUpEntityTypes;
  requestType?: RequestTypes;
}

/*
 * First check if there is a API response display error. If so render that before
 * going through the popup payload process.
 * If no response error to display check the entity type such as projects, workspaces, documents, etc.
 * Then handle the payload based on the HTTP header status code of the entity type.
 * If project type or status code does not match fail out.
 * NOTE: this file uses hoisting to move entity handler functions to the bottom of the page for better clarity
 */
const processPopUpErrorPayload = ({
  title,
  errorMessage,
  statusCode,
  entityType,
  requestType,
}: IPopUpArguments): IPopUpErrorItem => {
  if (errorMessage) {
    return genericPayload(title, errorMessage);
  }

  switch (entityType) {
    case PopUpEntityTypes.Projects:
      return handleProjectsPayload(title, statusCode, requestType);
    case PopUpEntityTypes.Workspaces:
      return handleWorkspacesPayload(title, statusCode, requestType);
    case PopUpEntityTypes.Models:
      return handleModelsPayload(title, statusCode, requestType);
    case PopUpEntityTypes.Documents:
      return handleDocumentsPayload(title, statusCode, requestType);
    default:
      return genericPayload(title, errorMessage);
  }
};

export default processPopUpErrorPayload;

function genericPayload(title: string, errorMessage?: string): IPopUpErrorItem {
  return {
    title,
    message: errorMessage || `${i18next.t('components.popUpErrors.generic.message')}`,
    confirmationButtonText: `${i18next.t('components.popUpErrors.generic.confirmationButton')}`,
    confirmationButtonAction: PopUpErrorActions.Dismiss,
    showCancelButton: false,
  };
}

function handleProjectsPayload(
  title: string,
  statusCode: number,
  requestType: RequestTypes | undefined
): IPopUpErrorItem {
  switch (statusCode) {
    case 404:
    case 400:
    case 401:
      if (requestType === RequestTypes.Put) {
        return {
          title,
          message: `${i18next.t('components.popUpErrors.projects.update.message')}`,
          confirmationButtonText: `${i18next.t('components.popUpErrors.projects.reload')}`,
          confirmationButtonAction: PopUpErrorActions.RefreshProjects,
          showCancelButton: true,
        };
      }
      if (requestType === RequestTypes.Delete) {
        return {
          title,
          message: `${i18next.t('components.popUpErrors.projects.delete.message')}`,
          confirmationButtonText: `${i18next.t('components.popUpErrors.projects.reload')}`,
          confirmationButtonAction: PopUpErrorActions.RefreshProjects,
          showCancelButton: true,
        };
      }
      return genericPayload(title);
    default:
      return genericPayload(title);
  }
}

function handleWorkspacesPayload(
  title: string,
  statusCode: number,
  requestType: RequestTypes | undefined
): IPopUpErrorItem {
  switch (statusCode) {
    case 404:
    case 400:
    case 401:
      if (requestType === RequestTypes.Delete) {
        return {
          title,
          showCancelButton: true,
          message: `${i18next.t('components.popUpErrors.workspaces.delete.message')}`,
          confirmationButtonText: `${i18next.t('components.popUpErrors.workspaces.reload')}`,
          confirmationButtonAction: PopUpErrorActions.RefreshProjects,
        };
      }
      return genericPayload(title);
    default:
      return genericPayload(title);
  }
}

function handleModelsPayload(
  title: string,
  statusCode: number,
  requestType: RequestTypes | undefined
): IPopUpErrorItem {
  switch (statusCode) {
    case 404:
    case 400:
    case 401:
      if (requestType === RequestTypes.Delete) {
        return {
          title,
          message: `${i18next.t('components.popUpErrors.models.delete.message')}`,
          confirmationButtonText: `${i18next.t('components.popUpErrors.models.reload')}`,
          confirmationButtonAction: PopUpErrorActions.RefreshModels,
          showCancelButton: true,
        };
      }
      if (requestType === RequestTypes.Put) {
        return {
          title,
          message: `${i18next.t('components.popUpErrors.models.updateDraft.message')}`,
          confirmationButtonText: `${i18next.t('components.popUpErrors.refreshPage')}`,
          confirmationButtonAction: PopUpErrorActions.RefreshPage,
          showCancelButton: true,
        };
      }
      return genericPayload(title);
    default:
      return genericPayload(title);
  }
}

function handleDocumentsPayload(
  title: string,
  statusCode: number,
  requestType: RequestTypes | undefined
): IPopUpErrorItem {
  switch (statusCode) {
    case 401:
    case 404:
    case 400:
      if (requestType === RequestTypes.Delete) {
        return {
          title,
          message: `${i18next.t('components.popUpErrors.documents.delete.message')}`,
          confirmationButtonText: `${i18next.t('components.popUpErrors.refreshPage')}`,
          confirmationButtonAction: PopUpErrorActions.RefreshPage,
          showCancelButton: true,
        };
      }
      if (requestType === RequestTypes.Download) {
        return {
          title,
          message: `${i18next.t('components.popUpErrors.documents.download.message')}`,
          confirmationButtonText: `${i18next.t('components.popUpErrors.refreshPage')}`,
          confirmationButtonAction: PopUpErrorActions.RefreshPage,
          showCancelButton: true,
        };
      }
      return genericPayload(title);
    default:
      return genericPayload(title);
  }
}
