import { defineMessages } from "react-intl";
export const FormatMessagePlaceholder = "NamePlaceholderDoNotTranslate";

export const HeaderLocalizationFormatMessages = defineMessages({
    AzureAI: {
        id: "Header.AzureAI",
        defaultMessage: "Azure AI"
    },
    CognitiveServices: {
        id: "Header.CognitiveServices",
        defaultMessage: "Cognitive Services"
    },
    SignIn: {
        id: "Header.SignIn",
        defaultMessage: "Sign In"
    },
    SkipToContent: {
        id: "Header.SkipToContent",
        defaultMessage: "Skip to content"
    },
    InboxActions: {
        id: "Header.InboxActions",
        defaultMessage: "Inbox actions"
    },
    NewVersionContent:{
        id:"Header.NewVersionContent",
        defaultMessage: "Use the new version"
    }
});

export const SpinnersLocalizationFormatMessages = defineMessages({
    Loading: {
        id: "Spinners.Loading",
        defaultMessage: "Loading...",
    }
});

export const ThemedIconsLocalizationFormatMessages = defineMessages({
    Success: {
        id: "ThemedIcons.Success",
        defaultMessage: "Success",
    },
    Failed: {
        id: "ThemedIcons.Failed",
        defaultMessage: "Failed",
    },
    Expired: {
        id: "ThemedIcons.Expired",
        defaultMessage: "Expired",
    },
    Processing: {
        id: "ThemedIcons.Processing",
        defaultMessage: "Processing",
    },
    Cancelled: {
        id: "ThemedIcons.Cancelled",
        defaultMessage: "Cancelled",
    },
    Paused: {
        id: "ThemedIcons.Paused",
        defaultMessage: "Paused",
    },
    Suspended: {
        id: "ThemedIcons.Suspended",
        defaultMessage: "Suspended",
    },
    Recent: {
        id: "ThemedIcons.Recent",
        defaultMessage: "Recent",
    }
});

export const BreadcrumbLocalizationFormatMessages = defineMessages({
    Breadcrumb: {
        id: "Breadcrumb.Breadcrumb",
        defaultMessage: "Breadcrumb",
    }
});

export const FilterableComboBoxFormatMessages = defineMessages({
    All: {
        id: "All",
        defaultMessage: "All",
    },
    IconButtonArialabel: {
        id: "FilterableComboBoxFormatMessages.IconButtonArialabel",
        defaultMessage: "Dropdown button",
    },
});

export const DetailsListLocalizedFormatMessages = defineMessages({
    CommonCustomShimmeredDetailsListSortedByInDesc: {
        id: "DetailsListLocalizedFormatMessages.CommonCustomShimmeredDetailsListSortedByInDesc",
        defaultMessage: "Sorted by '{NamePlaceholderDoNotTranslate}' column in descending order."
    },
    CommonCustomShimmeredDetailsListSortedByInAsc: {
        id: "DetailsListLocalizedFormatMessages.CommonCustomShimmeredDetailsListSortedByInAsc",
        defaultMessage: "Sorted by '{NamePlaceholderDoNotTranslate}' column in ascending order.",
    },
    CountSelected: {
        id: "DetailsListLocalizedFormatMessages.CountSelected",
        defaultMessage: "{NamePlaceholderDoNotTranslate} selected",
    },
    ToggleSelectionForAllItems: {
        id: "DetailsListLocalizedFormatMessages.ToggleSelectionForAllItems",
        defaultMessage: "Toggle selection for all items",
    },
    ToggleSelection: {
        id: "DetailsListLocalizedFormatMessages.ToggleSelection",
        defaultMessage: "Toggle selection",
    },
    SelectRow: {
        id: "DetailsListLocalizedFormatMessages.SelectRow",
        defaultMessage: "select row",
    },
    ResizeColumn: {
        id: "DetailsListLocalizedFormatMessages.ResizeColumn",
        defaultMessage: "Resize column"
    },
    Resize: {
        id: "DetailsListLocalizedFormatMessages.Resize",
        defaultMessage: "Resize"
    },
    TableNotSorted: {
        id: "DetailsListLocalizedFormatMessages.TableNotSorted",
        defaultMessage: "Table is not sorted."
    },
    Sort: {
        id: "DetailsListLocalizedFormatMessages.Sort",
        defaultMessage: "Sort"
    },
    Cancel: {
        id: "DetailsListLocalizedFormatMessages.Cancel",
        defaultMessage: "Cancel"
    },
    EnterDesiredWidth: {
        id: "DetailsListLocalizedFormatMessages.EnterDesiredWidth",
        defaultMessage: "Enter desired column width (px):"
    }
});

export const ButtonLocalizationFormatMessages = defineMessages({
    More: {
        id: "ButtonLocalizationFormatMessages.More",
        defaultMessage: "Open in a new window",
    }
});

export const HatsLocalizationFormatMessages = defineMessages({
    OpenInANewWindow: {
        id: "Hats.OpenInANewWindow",
        defaultMessage: "Open in a new window",
    },
    Close: {
        id: "Hats.Close",
        defaultMessage: "Close",
    },
});

export const BannerLocalizationFormatMessages = defineMessages({
    NotAtAll: {
        id: "Banner.NotAtAll",
        defaultMessage: "Not at all",
    },
    ExtremelyLikely: {
        id: "Banner.ExtremelyLikely",
        defaultMessage: "Extremely likely",
    },
    Submit: {
        id: "Banner.Submit",
        defaultMessage: "Submit",
    },
    Close: {
        id: "Banner.Close",
        defaultMessage: "Close",
    }
});

export const HelpLocalizationFormatMessages = defineMessages({
    Close: {
        id: "Help.Close",
        defaultMessage: "Close",
    },
});

export const LeftNavLocalizationFormatMessages = defineMessages({
    CollapseNavigation: {
        id: "LeftNav.CollapseNavigation",
        defaultMessage: "Collapse navigation",
    },
    ExpandNavigation: {
        id: "LeftNav.ExpandNavigation",
        defaultMessage: "Expand navigation",
    },
});

export const NotificationLocalizationFormatMessages = defineMessages({
    PanelClose: {
        id: "Notification.PanelClose",
        defaultMessage: "Panel Close",
    },
    Close: {
        id: "Notification.Close",
        defaultMessage: "Close",
    },
    DismissAll: {
        id: "Notification.DismissAll",
        defaultMessage: "Dismiss all",
    },
    Messages: {
        id: "Notification.Messages",
        defaultMessage: "Notifications, {0} unread item(s)",
    },
});

export const ProfileCardLocalizationFormatMessages = defineMessages({
    Labelledby: {
        id: "ProfileCard.labelledby",
        defaultMessage: "profile card",
    },
    SignOut: {
        id: "ProfileCard.SignOut",
        defaultMessage: "Sign out",
    },
    ViewAccount: {
        id: "ProfileCard.ViewAccount",
        defaultMessage: "View account",
    },
    CurrentDirectory: {
        id: "ProfileCard.CurrentDirectory",
        defaultMessage: "Current directory",
    },
    SwitchDirectories: {
        id: "ProfileCard.SwitchDirectories",
        defaultMessage: "header profile switch-directories",
    },
    SwitchDirectory: {
        id: "ProfileCard.SwitchDirectory",
        defaultMessage: "Switch directory",
    },
    CurrentSubscription: {
        id: "ProfileCard.CurrentSubscription",
        defaultMessage: "Current subscription",
    },
    CurrentResource: {
        id: "ProfileCard.CurrentResource",
        defaultMessage: "Current resource",
    },
    CurrentRole: {
        id: "ProfileCard.CurrentRole",
        defaultMessage: "Current role",
    },
    CurrentRoleLearnMore: {
        id: "ProfileCard.CurrentRoleLearnMore",
        defaultMessage: "Your current role(s) for the selected resource.",
    },
    LearnMore: {
        id: "ProfileCard.LearnMore",
        defaultMessage: "Learn more",
    },

    SwitchResource: {
        id: "ProfileCard.SwitchResource",
        defaultMessage: "Switch resource",
    },
    SelectResource: {
        id: "ProfileCard.SelectResource",
        defaultMessage: "select resource",
    },
    PlaceHolder: {
        id: "ProfileCard.PlaceHolder",
        defaultMessage: "--",
    },
    Switch: {
        id: "ProfileCard.Switch",
        defaultMessage: "Switch",
    },
    Select: {
        id: "ProfileCard.Select",
        defaultMessage: "Select",
    },
    SignInWithADifferentAccount: {
        id: "ProfileCard.SignInWithADifferentAccount",
        defaultMessage: "Sign in with a different account",
    },
    SwitchResources: {
        id: "ProfileCard.SwitchResources",
        defaultMessage: "header file switch-resources",
    },
    SwitchSubscription: {
        id: "ProfileCard.SwitchSubscription",
        defaultMessage: "header file switch-subscription",
    },
});

export const AzureLocationMessages: any = defineMessages({
    australiaeast: {
        id: "SpeechStudio.AzureLocation.AustraliaEast",
        defaultMessage: "Australia East",
    },
    brazilsouth: {
        id: "SpeechStudio.AzureLocation.BrazilSouth",
        defaultMessage: "Brazil South",
    },
    westus: {
        id: "SpeechStudio.AzureLocation.WestUS",
        defaultMessage: "West US",
    },
    westus2: {
        id: "SpeechStudio.AzureLocation.WestUS2",
        defaultMessage: "West US 2",
    },
    westeurope: {
        id: "SpeechStudio.AzureLocation.WestEurope",
        defaultMessage: "West Europe",
    },
    northeurope: {
        id: "SpeechStudio.AzureLocation.NorthEurope",
        defaultMessage: "North Europe",
    },
    southeastasia: {
        id: "SpeechStudio.AzureLocation.SoutheastAsia",
        defaultMessage: "Southeast Asia",
    },
    eastasia: {
        id: "SpeechStudio.AzureLocation.EastAsia",
        defaultMessage: "East Asia",
    },
    westcentralus: {
        id: "SpeechStudio.AzureLocation.WestCentralUS",
        defaultMessage: "West Central US",
    },
    southcentralus: {
        id: "SpeechStudio.AzureLocation.SouthCentralUS",
        defaultMessage: "South Central US",
    },
    eastus: {
        id: "SpeechStudio.AzureLocation.EastUS",
        defaultMessage: "East US",
    },
    eastus2: {
        id: "SpeechStudio.AzureLocation.EastUS2",
        defaultMessage: "East US 2",
    },
    canadacentral: {
        id: "SpeechStudio.AzureLocation.CanadaCentral",
        defaultMessage: "Canada Central",
    },
    japaneast: {
        id: "SpeechStudio.AzureLocation.JapanEast",
        defaultMessage: "Japan East",
    },
    centralindia: {
        id: "SpeechStudio.AzureLocation.CentralIndia",
        defaultMessage: "Central India",
    },
    jioindiawest: {
        id: "SpeechStudio.AzureLocation.JioIndiaWest",
        defaultMessage: "Jio India West",
    },
    uksouth: {
        id: "SpeechStudio.AzureLocation.UKSouth",
        defaultMessage: "UK South",
    },
    japanwest: {
        id: "SpeechStudio.AzureLocation.JapanWest",
        defaultMessage: "Japan West",
    },
    koreacentral: {
        id: "SpeechStudio.AzureLocation.KoreaCentral",
        defaultMessage: "Korea Central",
    },
    francecentral: {
        id: "SpeechStudio.AzureLocation.FranceCentral",
        defaultMessage: "France Central",
    },
    northcentralus: {
        id: "SpeechStudio.AzureLocation.NorthCentralUS",
        defaultMessage: "North Central US",
    },
    centralus: {
        id: "SpeechStudio.AzureLocation.CentralUS",
        defaultMessage: "Central US",
    },
    southafricanorth: {
        id: "SpeechStudio.AzureLocation.SouthAfricaNorth",
        defaultMessage: "South Africa North",
    },
    uaenorth: {
        id: "SpeechStudio.AzureLocation.UAENorth",
        defaultMessage: "UAE North",
    },
    centraluseuap: {
        id: "SpeechStudio.AzureLocation.CentralUSEUAP",
        defaultMessage: "Central US EUAP",
    },
    chinanorth: {
        id: "SpeechStudio.AzureLocation.ChinaNorth",
        defaultMessage: "China North",
    },
    chinanorth2: {
        id: "SpeechStudio.AzureLocation.ChinaNorth2",
        defaultMessage: "China North 2",
    },
    chinaeast: {
        id: "SpeechStudio.AzureLocation.ChinaEast",
        defaultMessage: "China East",
    },
    chinaeast2: {
        id: "SpeechStudio.AzureLocation.ChinaEast2",
        defaultMessage: "China East 2",
    },
    usgovarizona: {
        id: "SpeechStudio.AzureLocation.USGovArizona",
        defaultMessage: "USGov Arizona",
    },
    usgovvirginia: {
        id: "SpeechStudio.AzureLocation.USGovVirginia",
        defaultMessage: "USGov Virginia",
    },
    switzerlandnorth: {
        id: "SpeechStudio.AzureLocation.SwitzerlandNorth",
        defaultMessage: "Switzerland North",
    },
    switzerlandwest: {
        id: "SpeechStudio.AzureLocation.SwitzerlandWest",
        defaultMessage: "Switzerland West",
    },
    germanywestcentral: {
        id: "SpeechStudio.AzureLocation.GermanyWestCentral",
        defaultMessage: "Germany West Central",
    },
    norwayeast: {
        id: "SpeechStudio.AzureLocation.NorwayEast",
        defaultMessage: "Norway East",
    },
    westus3: {
        id: "SpeechStudio.AzureLocation.WestUS3",
        defaultMessage: "West US 3",
    },
    eastus2euap: {
        id: "SpeechStudio.AzureLocation.EastUS2EUAP",
        defaultMessage: "East US 2 EUAP",
    },
    swedencentral: {
        id: "SpeechStudio.AzureLocation.SwedenCentral",
        defaultMessage: "Sweden Central",
    },
    chinanorth3: {
        id: "SpeechStudio.AzureLocation.ChinaNorth3",
        defaultMessage: "China North 3",
    },
    qatarcentral: {
        id: "SpeechStudio.AzureLocation.QatarCentral",
        defaultMessage: "Qatar Central",
    },
});

export const FaviconLocalizationFormatMessages = defineMessages({
    Message: {
        id: "Favicon.Message",
        defaultMessage: 'We need to make sure that the favicon works fine for both dark and light browser themes. As such, refer to the below PR for what changes are needed to support this: ',
    },
    PullRequest: {
        id: "Pull.Request",
        defaultMessage: "Pull Request"
    }
});

export const PrivacyLinkLocalizationFormatMessages = defineMessages({
    PrivacyCookies: {
        id: "PrivacyLink.Cookies",
        defaultMessage: "Privacy & cookies"
    },
    Imprint: {
        id: "PrivacyLink.Imprint",
        defaultMessage: "Imprint"
    }
})

export const ResourceLocalizationFormatMessages = defineMessages({
    LearnMoreAboutCreatingResourcesDesc: {
        id: "Resource.LearnMoreAboutCreatingResourcesDesc",
        defaultMessage: "Resources for Azure Cognitive Services belong to an Azure subscription where billing happens. They contain projects for individual AI services, and they’re organized into resource groups. Use resource keys and endpoints to authenticate your applications."
    },
    LearnMoreAboutCreatingResourcesInAzure: {
        id: "Resource.LearnMoreAboutCreatingResourcesInAzure",
        defaultMessage: "Learn more about creating resources in Azure"
    },
    CurrentResource: {
        id: "Resource.CurrentResource",
        defaultMessage: "Current resource:"
    },
    CurrentSubscription: {
        id: "Resource.CurrentSubscription",
        defaultMessage: "Current subscription:"
    },
    CurrentRoleAssignments: {
        id: "Resource.CurrentRoleAssignments",
        defaultMessage: "Current role assignments:"
    },
    ViewLevelOfAccessForThisResource: {
        id: "Resource.ViewLevelOfAccessForThisResource",
        defaultMessage: "View level of access for this resource"
    },
    AllResources: {
        id: "Resource.AllResources",
        defaultMessage: "All resources"
    },
    SelectNow: {
        id: "Resource.SelectNow",
        defaultMessage: "Select row"
    },
    ToggleSelectionForAllItems: {
        id: "Resource.ToggleSelectionForAllItems",
        defaultMessage: "Toggle selection for all items"
    },
    Search: {
        id: "Resource.Search",
        defaultMessage: "Search"
    },
    CreateAzureResource: {
        id: "Resource.CreateAzureResource",
        defaultMessage: "Create a new resource"
    },
    ColumnOptions: {
        id: "Resource.ColumnOptions",
        defaultMessage: "Column options"
    },
    ResourceName: {
        id: "Resource.ResourceName",
        defaultMessage: "Resource name"
    },
    AzureSubscription: {
        id: "Resource.AzureSubscription",
        defaultMessage: "Azure subscription"
    },
    Region: {
        id: "Resource.Region",
        defaultMessage: "Region"
    },
    PricingTier: {
        id: "Resource.PricingTier",
        defaultMessage: "Pricing tier"
    },
    Plan: {
        id: "Resource.Plan",
        defaultMessage: "Plan"
    },
    Standard: {
        id: "Resource.Standard",
        defaultMessage: "Standard"
    },
    Free: {
        id: "Resource.Free",
        defaultMessage: "Free"
    },
    Endpoint: {
        id: "Resource.Endpoint",
        defaultMessage: "Endpoint"
    },
    Key: {
        id: "Resource.Key",
        defaultMessage: "Key"
    },
    ShowKey: {
        id: "Resource.ShowKey",
        defaultMessage: "Show key"
    },
    HideKey: {
        id: "Resource.HideKey",
        defaultMessage: "Hide key"
    },
    Copy: {
        id: "Resource.Copy",
        defaultMessage: "Copy"
    },
    UseResource: {
        id: "Resource.UseResource",
        defaultMessage: "Use resource"
    },
    ViewAllPropertiesInAzurePortal: {
        id: "Resource.ViewAllPropertiesInAzurePortal",
        defaultMessage: "View all properties in Azure Portal"
    },
    CustomRole: {
        id: "Resource.CustomRole",
        defaultMessage: "Custom role"
    },
    Copied: {
        id: "Resource.Copied",
        defaultMessage: "Copied"
    },
    Refresh: {
        id: "Resource.Refresh",
        defaultMessage: "Refresh"
    },
    TooltipMessage: {
        id: "Resource.TooltipMessage",
        defaultMessage: "This resource is disabled for navigation because it does not support creating custom Computer Vision solutions (deployments, models, datasets, etc). Only resources in West US 2, East US, and West Europe are available at this time."
    },
    HintButton: {
        id: "Resource.HintButton",
        defaultMessage: "hint button"
    },
    ToggleFavoriteResource: {
        id: "Resource.ToggleFavoriteResource",
        defaultMessage: "toggle favorite resource"
    },
    FavoriteSelected: {
        id: "Resource.FavoriteSelected",
        defaultMessage: "Selected"
    },
    FavoriteUnselected: {
        id: "Resource.favoriteUnselected",
        defaultMessage: "Unselected"
    },
    AddToFavorites: {
        id: "Resource.AddToFavorites",
        defaultMessage: "Added {0} to favorites"
    },
    RemoveFromFavorites: {
        id: "Resource.RemoveFromFavorites",
        defaultMessage: "Removed {0} from favorites"
    },
    Favorites: {
        id: "Resource.Favorites",
        defaultMessage: "Favorites"
    },
    JSONView: {
        id: "Resource.JSONView",
        defaultMessage: "JSON view"
    },
    NoResourcesFound: {
        id: "Resource.NoResourcesFound",
        defaultMessage: "No resources found."
    },
    NoResourceInDirectory: {
        id: "Resource.NoResourceInDirectory",
        defaultMessage: "We could not find any resources in your directory."
    },
    NoResourceInSelectedDirectory: {
        id: "Resource.NoResourceInSelectedDirectory",
        defaultMessage: "We could not find any resources under the selected directory. Consider switching to a different directory."
    },
    SwitchDirectory: {
        id: "Resource.SwitchDirectory",
        defaultMessage: "Switch Directory"
    }
});

export const TabLocalizationFormatMessages = defineMessages({
    Directory: {
        id: "Tab.Directory",
        defaultMessage: "Directory"
    },
    General: {
        id: "Tab.General",
        defaultMessage: "General"
    },
    Resource: {
        id: "Tab.Resource",
        defaultMessage: "Resource"
    },
});

export const ColumnSelectorLocalizationFormatMessages = defineMessages({
    Content: {
        id: "ColumnSelector.Content",
        defaultMessage: "Check the column names you would like to be visible. Drag items, or use arrow keys, to rearrange them."
    },
    EditColumns: {
        id: "ColumnSelector.EditColumns",
        defaultMessage: "Edit columns"
    },
    Save: {
        id: "ColumnSelector.Save",
        defaultMessage: "Save"
    },
    Cancel: {
        id: "ColumnSelector.Cancel",
        defaultMessage: "Cancel"
    },
    Close: {
        id: "ColumnSelector.Close",
        defaultMessage: "Close"
    },
    MoveUp: {
        id: "ColumnSelector.MoveUp",
        defaultMessage: "Move Up"
    },
    MoveDown: {
        id: "ColumnSelector.MoveDown",
        defaultMessage: "Move Down"
    },
    SearchColumnsUpdate: {
        id: "ColumnSelector.SearchColumnsUpdate",
        defaultMessage: "The columns are updated"
    },
    MoveUpColumnsUpdate: {
        id: "ColumnSelector.MoveUpColumnsUpdate",
        defaultMessage: "The column is moved up"
    },
    MoveDownColumnsUpdate: {
        id: "ColumnSelector.MoveDownColumnsUpdate",
        defaultMessage: "The column is moved down"
    }
});

export const CardLocalizationFormatMessages = defineMessages({
    ExpandCardLink: {
        id: "Card.expandLink",
        defaultMessage: "Expand card link dropdown"
    },
    CollapseCardLink: {
        id: "Card.Collapse",
        defaultMessage: "Collapse card link dropdown"
    }
});

export const DirectoryLocalizationFormatMessages = defineMessages({
    SwitchDirectoryPrompt: {
        id: "Directory.SwitchDirectoryPrompt",
        defaultMessage: "The directory you choose will impact subscription, resource group, and region filters that are available when you view resources."
    },
    LearnMoreAboutAzureDirectories: {
        id: "Directory.LearnMoreAboutAzureDirectories",
        defaultMessage: "Learn more about directories"
    },
    CurrentDirectory: {
        id: "Directory.CurrentDirectory",
        defaultMessage: "Current directory:"
    },
    AllDirectories: {
        id: "Directory.AllDirectories",
        defaultMessage: "All directories"
    },
    SelectRow: {
        id: "Directory.SelectRow",
        defaultMessage: "Select row"
    },
    ToggleSelectionForAllItems: {
        id: "Directory.ToggleSelectionForAllItems",
        defaultMessage: "Toggle selection for all items"
    },
    Search: {
        id: "Directory.Search",
        defaultMessage: "Search"
    },
    DirectoryName: {
        id: "Directory.DirectoryName",
        defaultMessage: "Directory name"
    },
    Domain: {
        id: "Directory.Domain",
        defaultMessage: "Domain"
    },
    DirectoryID: {
        id: "Directory.DirectoryID",
        defaultMessage: "Directory ID"
    },
    SwitchDirectory: {
        id: "Directory.SwitchDirectory",
        defaultMessage: "Switch directory"
    },
    ViewAllPropertiesInAzurePortal: {
        id: "Directory.ViewAllPropertiesInAzurePortal",
        defaultMessage: "View all properties in Azure Portal"
    },
    NoDataFound: {
        id: "Directory.NoDataFound",
        defaultMessage: "No data found."
    },
    NoDirectoryDescription: {
        id: "Directory.NoDirectoryDescription",
        defaultMessage: "We could not find any directories for the logged in account."
    },
    OneItemDisplayed: {
        id: "OneItemDisplayed",
        defaultMessage: "One Item Displayed"
    },
    NoItemDisplayed: {
        id: "NoItemDisplayed",
        defaultMessage: "No Item Displayed"
    },
    ItemsDisplayed: {
        id: "ItemsDisplayed",
        defaultMessage: "Items Displayed"
    }
});

export const UploadFileDialogFormatMessages = defineMessages({
    FileLocation: {
        id: "FileLocation",
        defaultMessage: "File location"
    },
    UploadData: {
        id: "UploadData",
        defaultMessage: "Upload data"
    },
    UploadFile: {
        id: "UploadFile",
        defaultMessage: "Upload file"
    },
    Cancel: {
        id: "Cancel",
        defaultMessage: "Cancel"
    },
    LocalFile: {
        id: "LocalFile",
        defaultMessage: "Local file"
    },
    DragAndDrop: {
        id: "DragAndDrop",
        defaultMessage: "Drag and drop."
    },
    BrowseForFiles: {
        id: "BrowseForFiles.1",
        defaultMessage: "Browse for a file"
    },
    YourDataFormatIsInvalidPleaseArchiveCorrectFile: {
        id: "YourDataFormatIsInvalidPleaseArchiveCorrectFile",
        defaultMessage: "Your data format is invalid. Please upload a JSON lines file with {NamePlaceholderDoNotTranslate} extension."
    },
    SelectedFileForUpload: {
        id: "SelectedFile",
        defaultMessage: "Selected file for upload: {NamePlaceholderDoNotTranslate}"
    },
    YourDataIsOversizedPleaseDivideAndUpload: {
        id: "YourDataIsOversizedPleaseDivideAndUpload",
        defaultMessage: "Your data is oversized. Please divide the data into several batches and upload separately."
    },
    EmptyFilePleaseCheckYourData: {
        id: "UploadEmptyFileNotAllowed",
        defaultMessage: "Empty file. Please check your data and try again."
    }
})

export const PanelSelectorLocalizationFormatMessages = defineMessages({
    MoveUp: {
        id: "PanelSelector.MoveUp",
        defaultMessage: "Move Up"
    },
    MoveDown: {
        id: "PanelSelector.MoveDown",
        defaultMessage: "Move Down"
    },
    MoveUpColumnsUpdate: {
        id: "PanelSelector.MoveUpColumnsUpdate",
        defaultMessage: "The column is moved up"
    },
    MoveDownColumnsUpdate: {
        id: "PanelSelector.MoveDownColumnsUpdate",
        defaultMessage: "The column is moved down"
    }
});

export const CreateResourceWizardMessages = defineMessages({
    SelectDirectoryStepDescription1: {
        id: "SelectDirectoryStep.Description1",
        defaultMessage: "An Azure subscription has a trust relationship with Azure Active Directory (Azure AD). A subscription trusts Azure AD to authenticate users, services, and devices."
    },
    SelectDirectoryStepDescription2: {
        id: "SelectDirectoryStep.Description2",
        defaultMessage: "All of your users have a single home directory for authentication. Your users can also be guests in other directories. You can see both the home and guest directories for each user in Azure AD."
    },
    LearnMore: {
        id: "SelectDirectoryStep.LearnMoreLinkText",
        defaultMessage: "Learn more"
    },
    SelectDirectoryStepSelectorTitle: {
        id: "SelectDirectoryStep.SelectorTitle",
        defaultMessage: "Good news! You have the following directory associated with your account:"
    },
    TrustRelationship: {
        id: "SelectDirectoryStep.Relationship",
        defaultMessage: "Trust Relationship"
    },
    SelectSubscription: {
        id: "SelectorPlaceholder.Subscription",
        defaultMessage: "Select subscription"
    },
    SelectGroup: {
        id: "SelectorPlaceholder.ResourceGroup",
        defaultMessage: "Select group"
    },
    SelectLocation: {
        id: "SelectorPlaceholder.Location",
        defaultMessage: "Select location"
    },
    SelectTier: {
        id: "SelectorPlaceholder.PricingTier",
        defaultMessage: "Select tier"
    },
    AzureSubscription: {
        id: "SelectorTitle.Subscription",
        defaultMessage: "Azure Subscription"
    },
    AzureResourceGroup: {
        id: "SelectorTitle.ResourceGroup",
        defaultMessage: "Azure resource group"
    },
    Location: {
        id: "SelectorTitle.Location",
        defaultMessage: "Location"
    },
    PricingTier: {
        id: "SelectorTitle.PricingTier",
        defaultMessage: "Pricing tier"
    },
    NameYourResource: {
        id: "InputPlaceholder.ResourceName",
        defaultMessage: "Name your resource"
    },
    ResourceNameInputTitle: {
        id: "InputTitle.ResourceName",
        defaultMessage: "Name your resource"
    },
    ResourceNameInputTip: {
        id: "InputTip.ResourceName",
        defaultMessage: "Tip: The resource name is best for recall when it’s in the following format."
    },
    LocationSelectorTip: {
        id: "InputTip.Location",
        defaultMessage: "Tip: Choose the location that is closest to your geographic region unless you work for a big company other considerations"
    },
    PricingTierSelectorTip: {
        id: "InputTip.PricingTier",
        defaultMessage: "Tip: Pricing tiers for Language are broken down into two categories - Free (F0) and Standard (S). Choose one for this situation and the other for this."
    },
    AzureDirectory: {
        id: "SummaryStepTitle.AzureDirectory",
        defaultMessage: "Azure directory"
    },
    Subscription: {
        id: "SummaryStepTitle.Subscription",
        defaultMessage: "Subscription"
    },
    Resource: {
        id: "SummaryStepTitle.Resource",
        defaultMessage: "Resource"
    },
    AdvancedOptions: {
        id: "SummaryStepTitle.Options",
        defaultMessage: "Advanced options"
    },
    SummaryStepOptionsDescriptionPortal: {
        id: "SummaryStepDescription.Portal",
        defaultMessage: "If you need any of the following resource features, you can add them to this resource within the "
    },
    AzurePortal: {
        id: "SummaryStepDescription.PortalText",
        defaultMessage: "Azure portal"
    },
    SummaryStepOptionsDescriptionOption1: {
        id: "SummaryStepDescription.Option1",
        defaultMessage: "Virtual private network (VPN) setup"
    },
    SystemAssignedManagedIdentity: {
        id: "SummaryStepDescription.Option2",
        defaultMessage: "System assigned managed identity"
    },
    StoreEncryption: {
        id: "SummaryStepDescription.Option3",
        defaultMessage: "Store encryption"
    },
    SummaryStepOptionsDescriptionOption4: {
        id: "SummaryStepDescription.Option4",
        defaultMessage: "Tags (name/value pairs) for categorizing resources"
    },
    LearnMoreAboutAdvancedOptions: {
        id: "SummaryStepDescription.LearnMoreText",
        defaultMessage: "Learn more about advanced options"
    },
});

export const LeftNavCustomLocalizationFormatMessages = defineMessages({
    ExpandCollapsed: {
        id: "ExpandCollapsed",
        defaultMessage: "Expand/Collapsed"
    },
    OpenAI: {
        id: "OpenAI",
        defaultMessage: "Azure OpenAI"
    },
    GPT3: {
        id: "GPT3",
        defaultMessage: "GPT-3"
    },
    Dalle: {
        id: "Dalle",
        defaultMessage: "DALL·E 2"
    },
    Playground: {
        id: "Playground",
        defaultMessage: "Playground"
    },
    Management: {
        id: "Management",
        defaultMessage: "Management"
    },
    Deployments: {
        id: "Deployments",
        defaultMessage: "Deployments"
    },
    Models: {
        id: "Models",
        defaultMessage: "Models"
    },
    FileManagement: {
        id: "FileManagement",
        defaultMessage: "File Management"
    },
    ChatGPT: {
        id: "ChatGPT",
        defaultMessage: "ChatGPT"
    },
    ExpandCollapse: {
        id: "ExpandCollapse",
        defaultMessage: "Expand/Collapse"
    },
});

export const CodeViewGuidanceLocalizationFormatMessages = defineMessages({
    GuidMessage: {
        id: "GuidMessage",
        defaultMessage: "If you want to have code view in your studio, we recommend using 'react-syntax-highlighter' library. Here's the sample code:"
    },
});

export const NonSignedInCardLocalizationFormatMessages = defineMessages({
    DontHaveAzureAccount: {
        id: "TeachingBubble.DontHaveAzureAccount",
        defaultMessage: "Don’t have Azure account?"
    },
    CreateOneLink: {
        id: "TeachingBubble.CreateOneLink",
        defaultMessage: "Create one here",
    },
    or: {
        id: "TeachingBubble.or",
        defaultMessage: "or",
    },
    howToCreateAccountLink: {
        id: "TeachingBubble.howToCreateAccountLink",
        defaultMessage: "learn how to create Azure account.",
    },
    SignUpAndGetFreeAzureCredit: {
        id: "TeachingBubble.SignUpAndGetFreeAzureCredit",
        defaultMessage: 'Sign up and get free $200 Azure Credit',
    },
});

export const ErrorPageLocalizationFormatMessages = defineMessages({
    DescriptionVisit: {
        id: "Description.VisitThe",
        defaultMessage: "visit the "
    },
    DescriptionOrStep: {
        id: "Description.OrStep",
        defaultMessage: " or use the main navigation to find what you are looking for.",
    },
    DescriptionHomePage: {
        id: "Description.HomePage",
        defaultMessage: "home page",
    },
    Title404: {
        id: "Title404",
        defaultMessage: "The page you are looking for was not found",
    },
    Title403: {
        id: "Title403",
        defaultMessage: "You are not authorized to view this page",
    }
});

export const TagPickerLocalizationFormatMessages = defineMessages({
    Apply: {
        id: "TagPicker.Apply",
        defaultMessage: "Apply"
    },
    Cancel: {
        id: "TagPicker.Cancel",
        defaultMessage: "Cancel"
    },
    AddFilter: {
        id: "TagPicker.AddFilter",
        defaultMessage: "Add filter"
    },
    SelectFilter: {
        id: "TagPicker.SelectFilter",
        defaultMessage: "Select filter"
    },
    Filter: {
        id: "TagPicker.Filter",
        defaultMessage: "Filter"
    },
    SelectValue: {
        id: "TagPicker.SelectValue",
        defaultMessage: "Select value"
    },
    Value: {
        id: "TagPicker.Value",
        defaultMessage: "Value"
    },
    Search: {
        id: "TagPicker.Search",
        defaultMessage: "Search"
    },
    Delete: {
        id: "TagPicker.Delete",
        defaultMessage: "Delete"
    },
});

export const DateTimePickerLocalizationFormatMessages = defineMessages({
    StartTimeFormatError: {
        id: "DateTimePicker.StartTimeFormatError",
        defaultMessage: "Start time format must match hh:mm."
    },
    EndTimeFormatError: {
        id: "DateTimePicker.EndTimeFormatError",
        defaultMessage: "End time format must match hh:mm."
    },
    StartDateTimeError: {
        id: "DateTimePicker.StartDateTimeError",
        defaultMessage: "Start date-time can't be greater than end date-time."
    },
    DefineOrEdit: {
        id: "DateTimePicker.DefineOrEdit",
        defaultMessage: "Define or edit "
    },
    TimeRange: {
        id: "DateTimePicker.TimeRange",
        defaultMessage: "time range"
    },
    Starttime: {
        id: "DateTimePicker.StartTimeSmall",
        defaultMessage: "start time"
    },
    Endtime: {
        id: "DateTimePicker.EndTimeSmall",
        defaultMessage: "end time"
    },
    Save: {
        id: "DateTimePicker.Save",
        defaultMessage: "Save"
    },
    Cancel: {
        id: "DateTimePicker.Cancel",
        defaultMessage: "Cancel"
    },
    StartTime: {
        id: "DateTimePicker.StartTime",
        defaultMessage: "Start time"
    },
    EndTime: {
        id: "DateTimePicker.EndTime",
        defaultMessage: "End time"
    },
    Edit: {
        id: "DateTimePicker.Edit",
        defaultMessage: "Edit date time picker"
    }
});

export const HeaderLinksMenuListItemsName = defineMessages({
    SpeechStudio: {
        id: "HeaderLinksMenuListItemsName.SpeechStudio",
        defaultMessage: "Speech Studio"
    },
    LanguageStudio: {
        id: "HeaderLinksMenuListItemsName.LanguageStudio",
        defaultMessage: "Language Studio"
    },
    CustomTranslator: {
        id: "HeaderLinksMenuListItemsName.CustomTranslator",
        defaultMessage: "Custom Translator"
    },
    VisionStudio: {
        id: "HeaderLinksMenuListItemsName.VisionStudio",
        defaultMessage: "Vision Studio"
    },
    DocumentIntelligence: {
        id: "HeaderLinksMenuListItemsName.DocumentIntelligence",
        defaultMessage: "Document Intelligence"
    },
    ContentSafety: {
        id: "HeaderLinksMenuListItemsName.ContentSafety",
        defaultMessage: "Content Safety"
    },
    AzureAIStudio: {
        id: "HeaderLinksMenuListItemsName.AzureAIStudio",
        defaultMessage: "Azure AI Studio"
    },
    AzureMachineLearning: {
        id: "HeaderLinksMenuListItemsName.AzureMachineLearning",
        defaultMessage: "Azure Machine Learning"
    },
    LinkToOtherStudios: {
        id: "HeaderLinksMenuListItemsName.LinkToOtherStudios",
        defaultMessage: "Link to other studios"
    },
    ComingSoon: {
        id: "HeaderLinksMenuListItemsName.ComingSoon",
        defaultMessage: " (Coming soon)"
    },
});

export const FilterBarDropdownLocalizationFormatMessages = defineMessages({
    Search: {
        id: "FilterBarDropdown.Search",
        defaultMessage: "Search"
    }
});

export const JsonViewLocalizationFormatMessages = defineMessages({
    Close: {
        id: "JsonView.Close",
        defaultMessage: "Close",
    },
    ResourceJSON: {
        id: "JsonView.ResourceJSON",
        defaultMessage: "Resource JSON",
    },
});
export const WizardDialogLocalizationFormatMessages = defineMessages({
    DoThisLater: {
        id: "WizardDialog.DoThisLater",
        defaultMessage: "Do this later",
    },
    Edit: {
        id: "WizardDialog.Edit",
        defaultMessage: " - Edit",
    },
    LogInToStudio: {
        id: "WizardDialog.LogInToStudio",
        defaultMessage: "Sign in to Studio",
    },
    CloseAndContinueButton: {
        id: "WizardDialog.CloseAndContinueButton",
        defaultMessage: "Close and continue to Studio",
    },
    ChooseResourceButton: {
        id: "WizardDialog.ChooseResourceButton",
        defaultMessage: "Choose resource",
    },
    Subscription: {
        id: "WizardDialog.Subscription",
        defaultMessage: "Subscription: {NamePlaceholderDoNotTranslate}"
    },
    Resource: {
        id: "WizardDialog.Resource",
        defaultMessage: "Resource: {NamePlaceholderDoNotTranslate}"
    },
});

export const PreviewTagMessages: any = defineMessages({
    Preview: {
        id: "LeftNav.Preview",
        defaultMessage: "Preview",
    },
});

export const PlatformsMessages: any = defineMessages({
    Cloud: {
        id: "Platforms.Cloud",
        defaultMessage: "Cloud",
    },
    IOT: {
        id: "Platforms.IOT",
        defaultMessage: "Azure IoT",
    },
    Docker: {
        id: "Platforms.Docker",
        defaultMessage: "Docker container",
    },
    Platforms: {
        id: "Platforms.Platforms",
        defaultMessage: "Platforms",
    }
});

export const NewFeaturesPanelLocalizationFormatMessages = defineMessages({
    Close: {
        id: "NewFeaturesPanel.Close",
        defaultMessage: "Close",
    },
    Enabled: {
        id: "NewFeaturesPanel.Enabled",
        defaultMessage: "Enabled",
    },
    Disabled: {
        id: "NewFeaturesPanel.Disabled",
        defaultMessage: "Disabled",
    },
    RequestAccess: {
        id: "NewFeaturesPanel.RequestAccess",
        defaultMessage: "Request access",
    },
    NewFeatures: {
        id: "NewFeaturesPanel.NewFeatures",
        defaultMessage: "New features",
    }
});

export const CarouselLocalizationFormatMessages: any = defineMessages({
    Next: {
        id: "Carousel.Next",
        defaultMessage: "Next",
    },
    Previous: {
        id: "Carousel.Previous",
        defaultMessage: "Previous",
    }
});