import { IBaseModelNotificationItem } from '../notifications/notifications.types';

export const ADD_TRAINING_NOTIFICATION = 'ADD_TRAINING_NOTIFICATION';
export const UPDATE_TRAINING_NOTIFICATION = 'UPDATE_TRAINING_NOTIFICATION';
export const CLEAR_TRAINING_NOTIFICATION = 'CLEAR_TRAINING_NOTIFICATION';
export const CLEAR_ALL_TRAINING_NOTIFICATIONS = 'CLEAR_ALL_TRAINING_NOTIFICATIONS';
export const UPDATE_MODELID_TRAINING_NOTIFICATION = 'UPDATE_MODELID_TRAINING_NOTIFICATION';

export interface ITrainingNotificationItem extends IBaseModelNotificationItem {
  modelStatus: string;
  errorCode: string; // Errors that occurred during training process. Maps to TrainingErrorCodes enum.
}

export interface IAddTrainingNotificationPayload {
  id: string;
  data: {
    name: string;
  };
}

export interface IUpdateTrainingNotificationPayload {
  id: string;
  data: {
    modelStatus: string;
    errorCode: string;
  };
}

export interface IUpdateModelIdTrainingNotificationPayload {
  id: string;
  data: {
    oldId: string;
  };
}

export interface IAddTrainingNotification {
  type: typeof ADD_TRAINING_NOTIFICATION;
  payload: IAddTrainingNotificationPayload;
}

export interface IUpdateTrainingNotification {
  type: typeof UPDATE_TRAINING_NOTIFICATION;
  payload: IUpdateTrainingNotificationPayload;
}

export interface IClearTrainingNotification {
  type: typeof CLEAR_TRAINING_NOTIFICATION;
  payload: string;
}

export interface IClearAllTrainingNotifications {
  type: typeof CLEAR_ALL_TRAINING_NOTIFICATIONS;
}

export interface IUpdateModelIdTrainingNotification {
  type: typeof UPDATE_MODELID_TRAINING_NOTIFICATION;
  payload: IUpdateModelIdTrainingNotificationPayload;
}

export type TrainingNotificationsDispatchTypes =
  | IAddTrainingNotification
  | IUpdateTrainingNotification
  | IClearTrainingNotification
  | IClearAllTrainingNotifications
  | IUpdateModelIdTrainingNotification;
