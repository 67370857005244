import { ILinkProps, Panel, PanelType } from "@fluentui/react";
import { useState, useEffect } from "react";
import { INTL } from "../../../util/intlUtil";
import { JsonViewLocalizationFormatMessages } from "../../../clientResources";
import { initializeComponent, withLocalization } from "../../../services/localization";
import ReactMarkdown from "react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

export type LinkPropsExt = ILinkProps & { displayText: string }

export interface IJsonViewProps {
    headerText?: string;
    isOpen?: boolean | true;
    onClose: () => void;

    jsonObject: string;
    resourceName: string;

    width?: string;
};

const JsonViewInternal = (props: IJsonViewProps) => {
    const { onClose, isOpen, headerText } = props;
    const [isPanelOpen, setIsPanelOpen] = useState(isOpen);
    useEffect(() => {
        setIsPanelOpen(isOpen)
    }, [isOpen])
    function onDismiss() {
        setIsPanelOpen(false);
        onClose?.();
    }

    const markdownStr = `\`\`\`\n${JSON.stringify(JSON.parse(props.jsonObject), undefined, 4)}\n\`\`\``;

    return (
        <Panel
            isOpen={isPanelOpen}
            role="dialog"
            aria-labelledby={headerText || INTL.formatMessage(JsonViewLocalizationFormatMessages.ResourceJSON)}
            onDismiss={onDismiss}
            closeButtonAriaLabel={INTL.formatMessage(JsonViewLocalizationFormatMessages.Close)}
            type={PanelType.custom}
            customWidth={props.width || "1125px"}
            style={{ top: "40px" }}
            isLightDismiss
            headerText={headerText || INTL.formatMessage(JsonViewLocalizationFormatMessages.ResourceJSON)}
            headerTextProps={{ 'aria-level': 2 }}
            styles={{
                root: {
                    ".ms-Panel-commands": {
                        position: "sticky",
                        top: "-15px",
                        backgroundColor: "#fff",
                    },
                    ".ms-Panel-content": {
                        height: "calc(100% - 85px)",
                        "> p": {
                            fontSize: "12px",
                            margin: 0,
                        },
                    },
                    ".json-view-json-container": {
                        height: "calc(100% - 14px)",
                        width: "100%",
                        "pre": {
                            height: "100%",
                            width: "100%",
                            "div": {
                                height: "100%",
                                width: "100%",
                                overflow: "auto",
                            },
                        },
                    },
                },
            }}
        >
            <p>{props.resourceName}</p>
            <ReactMarkdown
                className="json-view-json-container"
                children={markdownStr}
                components={{
                    code({ node, inline, className, children, ...props }) {
                        return (
                            <SyntaxHighlighter
                                {...props}
                                children={children}
                                style={a11yDark}
                                language="json"
                                PreTag="div"
                                tabIndex={0}
                            />
                        )
                    }
                }}
            />
        </Panel>
    );
};

export const JsonViewArea = withLocalization(initializeComponent(JsonViewInternal));