export enum NotificationType {
  Upload = 0,
  Training = 1,
  Deployment = 2,
  Copying = 3,
}

export interface IUploadNotificationReceived {
  jobId: string;
  status: UploadNotificationStatus;
  errorMessage: string;
}

export interface IExtractedFileUploadNotificationReceived {
  jobId: string;
  status: UploadNotificationStatus;
  errorMessage: string;
  id: string;
  name: string;
}

export interface ITrainingNotificationReceived {
  modelId: string;
  projectId: string;
  errorCode: string;
  status: number;
}

export interface IDeploymentNotificationReceived {
  regionalStatuses: {
    [key: string]: IRegionalStatusReceived;
  };
  modelId: string;
  projectId: string;
  status: number;
}

export interface IRegionalStatusReceived {
  region: number;
  status: DeploymentNotificationStatus;
  isDeployed: boolean;
}

// Status returned by the deployment service through Azure Functions
// See Enums.cs in HubStudio/Common/Messages
export enum DeploymentNotificationStatus {
  New = 0,
  InProgress = 1,
  Succeeded = 2,
  Cancelled = 3,
  Failed = 4,
}

// Status returned by the upload web job through Azure Functions.
// See Enums.cs in HubStudio/Common/Messages
export enum UploadNotificationStatus {
  InProgress = 0,
  Succeeded = 1,
  Failed = 2,
}
