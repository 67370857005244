import { DatePicker, Stack, StackItem, TextField } from '@fluentui/react';
import moment from 'moment';
import { useState } from 'react';
import { mergeStyleSets, Text } from '@fluentui/react';
import { initializeComponent, withLocalization } from '../../../services/localization';

const errorTextStyles = mergeStyleSets({
    root: {
        color: '#A80000',
        fontSize: 12,
        lineHeight: 16,
    },
});

export interface FormInlineErrorProps {
    children: JSX.Element | string | undefined | null;
}

export const FormInlineError = (props: FormInlineErrorProps): JSX.Element => {
    const { children } = props;
    return <Text className={errorTextStyles.root}>{children}</Text>;
};

function getDate(date: Date | undefined | null, time: string) {
    if (!date) {
        return new Date();
    }
    const timeArr = time.split(':');
    const hour = parseInt(timeArr[0], 10);
    const minute = parseInt(timeArr[1], 10);
    return new Date(date.getTime() + hour * 3600000 + minute * 60000);
}

function utcStringToLocalDate(str?: string): Date {
    if (typeof str === 'string' && str) {
        return moment.utc(str).utcOffset(moment(str).utcOffset(), true).toDate();
    }
    return moment.utc().utcOffset(moment().utcOffset(), true).toDate();
}

function localDateToUtcString(date: Date) {
    return moment(date).utcOffset(0, true).format();
}

type DateTime = string | null;

export interface DateTimePickerInlineProps {
    value: DateTime;
    isDisabled?: boolean;
    errorMessage?: string;
    marginTop?: boolean;
    onChange?: (dateTime: string) => void;
    minDate?: Date;
    required?: boolean;
    dateLabel?: string;
    timeLabel?: string;
};

function DateTimeValid(time: string): boolean {
    const regex = /^(?:(?:[0-2][0-3])|(?:[0-1][0-9])):[0-5][0-9]$/;
    if (regex.test(time)) return true;
    return false;
}
function DateTimePickerInlineInternal(props: DateTimePickerInlineProps): JSX.Element {
    const { value, isDisabled, errorMessage, marginTop, onChange, minDate, required = false } = props;
    let tmpFromDateTime: Date | undefined | null = null;

    let tmpFromDate: Date | undefined | null = null;

    let tmpFromTime = '';

    if (value) {
        tmpFromDateTime = utcStringToLocalDate(value);
        const year = tmpFromDateTime.getFullYear();
        const month = tmpFromDateTime.getMonth();
        const day = tmpFromDateTime.getDate();
        const hour: number = tmpFromDateTime.getHours();
        const minute: number = tmpFromDateTime.getMinutes();
        tmpFromDate = new Date(year, month, day);
        tmpFromTime = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
    }

    const [fromTime, setFromTime] = useState(tmpFromTime);
    const [fromDate, setFromDate] = useState(tmpFromDate);

    const tmpSetFrom = (date: Date | undefined | null) => {
        const tmpDate: Date | null = date === undefined ? null : date;
        setFromDate(tmpDate);
        if (date && fromTime === '') {
            setFromTime('00:00');
        }
        if (!date && fromTime) {
            setFromTime('');
        }
    };

    const dateFormatter = (date?: Date): string =>
        !date
            ? ''
            : `${date.getFullYear().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;

    return (
        <Stack>
            <Stack grow horizontal tokens={{ childrenGap: 8 }}>
                <StackItem grow>
                    <DatePicker
                        label={props.dateLabel}
                        allowTextInput
                        placeholder="YYYY-MM-DD"
                        formatDate={dateFormatter}
                        value={fromDate === null ? undefined : fromDate}
                        minDate={minDate}
                        onSelectDate={(date) => {
                            tmpSetFrom(date);
                            if (date) onChange?.(localDateToUtcString(date));
                        }}
                        textField={{
                            'aria-required': true,
                        }}
                    />
                </StackItem>
                <StackItem grow>
                    <TextField
                        label={props.timeLabel}
                        placeholder="hh:mm"
                        onRenderInput={(prop, defaultRenderer) => {
                            return defaultRenderer?.({ 'aria-required': true, ...prop }) || <></>;
                        }}
                        value={fromTime}
                        onChange={(_event, newValue) => setFromTime(newValue || '00:00')}
                        onBlur={(event) => {
                            let newTime = event.target.value.trim();
                            if (newTime && DateTimeValid(newTime)) {
                                const newDateTime = getDate(tmpFromDate, newTime);
                                if (newDateTime instanceof Date && !Number.isNaN(newDateTime.getTime())) onChange?.(localDateToUtcString(newDateTime));
                            } else {
                                newTime = '00:00';
                            }
                            setFromTime(newTime);
                        }}
                    />
                </StackItem>
            </Stack>
            <FormInlineError>{errorMessage}</FormInlineError>
        </Stack>
    );
}

DateTimePickerInlineInternal.defaultProps = {
    isDisabled: false,
};

export const DateTimePickerInline = withLocalization(initializeComponent(DateTimePickerInlineInternal));