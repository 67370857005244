import { IBaseModelNotificationItem } from '../notifications/notifications.types';

export const ADD_COPY_NOTIFICATION = 'ADD_COPY_NOTIFICATION';
export const UPDATE_COPY_NOTIFICATION = 'UPDATE_COPY_NOTIFICATION';

export interface ICopyNotificationItem extends IBaseModelNotificationItem {
  id: string;
  data: {
    modelName: string;
    targetProjectName: string;
    targetWorkspaceName: string;
    modelStatus?: string;
  };
}

export interface IAddCopyNotificationPayload {
  id: string;
  data: {
    modelName: string;
    targetProjectName: string;
    targetWorkspaceName: string;
  };
}

export interface IAddCopyNotification {
  type: typeof ADD_COPY_NOTIFICATION;
  payload: IAddCopyNotificationPayload;
}

export interface IUpdateCopyNotification {
  type: typeof UPDATE_COPY_NOTIFICATION;
  payload: any;
}

export type CopyNotificationsDispatchTypes = IAddCopyNotification | IUpdateCopyNotification;
